<form class="container" [class.formula-only]="brand === 'fr'" [formGroup]="loginControl" (ngSubmit)="doLogin()">
  <div class="login-form">
    <div class="logo">
      <img class="image-logo" src="./assets/images/bt/img-logo.svg">
    </div>
    <div class="login-title">{{ portalName }}</div>
    <div class="form-group">
      <input class="input-field" id="login-user" type="text"
        [(formControl)]="loginControl.controls.username"
        [class.hasValue] = "loginControl.controls.username.value">
      <label class="input-field-placeholder" for="login-user">Usuário</label>
    </div>
    <app-error-message class="error" [control]="loginControl.controls.username"></app-error-message>
    <div class="form-group">
      <input class="input-field" id="login-password" type="password"
        [(formControl)]="loginControl.controls.password"
        [class.hasValue] = "loginControl.controls.password.value">
      <label class="input-field-placeholder" for="login-password">Senha</label>
    </div>
    <app-error-message class="error" [control]="loginControl.controls.password"></app-error-message>
    <button type="submit" id="login-button" class="bo-btn bo-tertiary-btn H5-LatoBold-B">Entrar</button>
  </div>
</form>