import gql from 'graphql-tag';
import { GymUnit } from './gym-unit';

export interface Plan {
  id: number;
  shortName: string;
}

export interface GymUnitGroupPlan {
  plan: Plan;
  gymUnities: GymUnit;
}

export class PlanDetailsGraphql {
  public static readonly queryListGymUnitGroupPlans = gql`
    query {
       listGymUnitGroupPlans {
        gymUnitGroupPlan {
            plan {
              id
              shortName
            }
        }
        gymUnities {
            id
            name
            address {
                id
                city {
                    id
                    state {
                        id
                        name
                    }
                }
            }
        }
      }
    }
  `;

  public static readonly mutationUpdateBatchGymUnitGroupPlanDescriptionsNameAndAvailableActivities = gql`
    mutation UpdateBatchGymUnitGroupPlanDescriptionsNameAndAvailableActivities(
      $planId: Long!
      $gymUnitIds: [Long!]
      $shortDescription: String,
      $longDescription: String,
      $availableActivities: String,
      $portalName: String,
    ) {
      updateBatchGymUnitGroupPlanDescriptionsNameAndAvailableActivities(
        planId: $planId,
        gymUnitIds: $gymUnitIds,
        shortDescription: $shortDescription,
        longDescription: $longDescription,
        availableActivities: $availableActivities,
        portalName: $portalName
      ) {
        plan {
          id
        }
        errors {
          field
          message
        }
      }
    }
  `;
}
