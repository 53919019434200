import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ClientContactData, ClientEmergencyContact, ClientPhone, ClientPhoneContact, PhoneType } from '@models';

@Component({
  selector: 'app-show-contacts-modal',
  templateUrl: './show-contacts-modal.component.html',
  styleUrls: ['./show-contacts-modal.component.scss'],
})
export class ShowContactsModalComponent<T> {

  homePhone: ClientPhoneContact;
  workPhone: ClientPhoneContact;
  cellPhone: ClientPhoneContact;
  emergencyPhones: ClientEmergencyContact[];

  constructor(
    public dialogRef: MatDialogRef<ShowContactsModalComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public data: ClientContactData,
  ) {
    const homePhone = data.listCustomerPhones.find(phone => phone.phoneType === PhoneType.HOME);
    this.homePhone = { ...homePhone, frontendFormattedNumber: this.formatPhoneToString(homePhone) };

    const workPhone = data.listCustomerPhones.find(phone => phone.phoneType === PhoneType.WORK);
    this.workPhone = { ...workPhone, frontendFormattedNumber: this.formatPhoneToString(workPhone) };

    const cellPhone = data.listCustomerPhones.find(phone => phone.phoneType === PhoneType.CELL);
    this.cellPhone = { ...cellPhone, frontendFormattedNumber: this.formatPhoneToString(cellPhone) };

    this.emergencyPhones = data.listCustomerEmergencialPhones.sort((a, b) => b.mainPhone - a.mainPhone);
    this.emergencyPhones = this.emergencyPhones.map(phone => ({ ...phone, frontendFormattedNumber: this.formatPhoneToString(phone) }));
  }

  formatPhoneToString(phone: ClientPhone) {
    if (!phone) { return ''; }
    const phoneString = phone.phoneNumber.trim(); // 912345678

    const dashIndex = phoneString.length - 4;
    const spaceIndex = Math.max(phoneString.length - 8, 0);

    let formattedNumber = '';
    if (spaceIndex > 0) {
      formattedNumber = phoneString.slice(0, spaceIndex) + ' '; // "9 "
    }
    const firstPart = phoneString.slice(spaceIndex, dashIndex); // 1234
    const secondPart = phoneString.slice(dashIndex); // 5678

    formattedNumber += firstPart + '-' + secondPart; // "9 1234-5678"
    return `(${phone.ddd}) ${formattedNumber}`;
  }


  closeModal(icon?: T): void {
    this.dialogRef.close(icon || false);
  }
}
