import { Item, Page } from '@models';

export const items: Item[] = [
  {
    id: 1,
    title: 'Fotos da Unidade',
    icon: '18-p-fotos-unidade-b.svg',
    secundaryIcon: 'bt/18-p-fotos-unidade-v-2.svg',
    path: '/fotos',
    brands: ['bt', 'fr'],
    page: Page.Photos,
  },
  {
    id: 2,
    title: 'Dados da Unidade',
    icon: 'bt/18-p-detalhes-unidade-b.svg',
    secundaryIcon: 'bt/18-p-detalhes-unidade-v-2.svg',
    hiddenChildren: true,
    children: [
      {
        id: 201,
        title: 'Horários',
        path: '/horarios',
        brands: ['bt', 'fr'],
        page: Page.WorkingPeriod,
      },
      {
        id: 202,
        title: 'Tags de Busca',
        path: '/tags',
        brands: ['bt', 'fr'],
        page: Page.Tags,
      },
      {
        id: 203,
        title: 'Conveniências',
        path: '/conveniencias',
        brands: ['bt'],
        page: Page.Conveniences,
      },
      {
        id: 204,
        title: 'Redes Sociais',
        path: '/redes-sociais',
        brands: ['bt', 'fr'],
        page: Page.SocialMedia,
      },
      {
        id: 205,
        title: 'Nome de Exibição',
        path: '/nome-de-exibicao',
        brands: ['bt', 'fr'],
        page: Page.GymName,
      },
      {
        id: 206,
        title: 'Termo de Adesão',
        path: '/termo-de-adesao',
        brands: ['bt', 'fr'],
        page: Page.AdhesionTerm,
      },
      {
        id: 207,
        title: 'Localização Geográfica',
        path: '/localizacao',
        brands: ['bt', 'fr'],
        page: Page.Location,
      },
      {
        id: 208,
        title: 'Professores',
        path: '/professores',
        brands: ['bt', 'fr'],
        page: Page.Teachers,
      },
    ],
  },
  {
    id: 3,
    title: 'Tabela de Preços',
    icon: '18-p-tabela-preco-b.svg',
    secundaryIcon: 'bt/18-p-tabela-preco-v-2.svg',
    path: '/tabela-precos',
    brands: ['bt', 'fr'],
    page: Page.PlanPriceList,
  },
  {
    id: 4,
    title: 'Avisos e Eventos',
    icon: '18-p-avisos-eventos-b.svg',
    secundaryIcon: 'bt/18-p-avisos-eventos-v-2.svg',
    path: '/avisos-eventos',
    brands: ['bt'],
    page: Page.InformativeGymUnit,
  },
  {
    id: 5,
    isLine: true,
  },
  {
    id: 12,
    title: 'Banner da Home',
    icon: '18-p-home-b.svg',
    secundaryIcon: 'bt/18-p-home-v-2.svg',
    path: '/geral/banner-da-home',
    brands: ['bt', 'fr'],
    page: Page.Banners,
  },
  {
    id: 13,
    title: 'Banner do Aplicativo',
    icon: '18-p-banner-app-b.svg',
    secundaryIcon: 'bt/18-p-banner-app-v-2.svg',
    path: '/geral/banner-do-aplicativo',
    brands: ['bt'],
    page: Page.AppBanners,
  },
  {
    id: 14,
    title: 'Headers das Páginas',
    icon: '18-p-headers-b.svg',
    secundaryIcon: 'bt/18-p-headers-v-2.svg',
    hiddenChildren: true,
    page: Page.PageHeader,
    children: [
      {
        id: 1401,
        title: 'Academias',
        path: '/geral/headers-das-paginas/gym-search',
        brands: ['bt', 'fr'],
      },
      {
        id: 1402,
        title: 'Mundo BT',
        path: '/geral/headers-das-paginas/concept',
        brands: ['bt'],
      },
      {
        id: 1403,
        title: 'Horários das Aulas',
        path: '/geral/headers-das-paginas/agenda',
        brands: ['bt', 'fr'],
      },
      {
        id: 1404,
        title: 'BT Corporate',
        path: '/geral/headers-das-paginas/corporate',
        brands: ['bt'],
      },
      {
        id: 1405,
        title: 'Contato',
        path: '/geral/headers-das-paginas/ticket',
        brands: ['bt', 'fr'],
      },
      {
        id: 1406,
        title: 'Bodytech Company',
        path: '/geral/headers-das-paginas/company',
        brands: ['bt'],
      },
      {
        id: 1407,
        title: 'Central de Privacidade',
        path: '/geral/headers-das-paginas/privacy-center',
        brands: ['bt', 'fr'],
      },
      {
        id: 1408,
        title: 'Política de Privacidade',
        path: '/geral/headers-das-paginas/privacy-policy',
        brands: ['bt', 'fr'],
      },
      // Essa página pode voltar futuramente, por isso manteve comentado
      // {
      //     id: 1407,
      //     title: 'Imprensa',
      //     path: '/geral/headers-das-paginas/press-release',
      //     brands: ['bt', 'fr']
      // },
      {
        id: 1409,
        title: 'Trabalhe Conosco',
        path: '/geral/headers-das-paginas/work-with-us',
        brands: ['bt', 'fr'],
      },
      {
        id: 1410,
        title: 'Seja um Franqueado',
        path: '/geral/headers-das-paginas/franchise',
        brands: ['bt', 'fr'],
      },
      {
        id: 1411,
        title: 'Dúvidas Frequentes',
        path: '/geral/headers-das-paginas/faq',
        brands: ['bt', 'fr'],
      },
      {
        id: 1412,
        title: 'Conceito',
        path: '/geral/headers-das-paginas/concept',
        brands: ['fr'],
      },
      {
        id: 1413,
        title: 'Sobre a Fórmula',
        path: '/geral/headers-das-paginas/company',
        brands: ['fr'],
      },
      {
        id: 1414,
        title: 'Private Partner',
        path: '/geral/headers-das-paginas/private-partner',
        brands: ['bt'],
      },
      {
        id: 1415,
        title: 'Dúvidas - Covid-19',
        path: '/geral/headers-das-paginas/faq-covid',
        brands: ['bt', 'fr'],
      },
      {
        id: 1416,
        title: 'Bodytech Home',
        path: '/geral/headers-das-paginas/bodytech-home',
        brands: ['bt'],
      },
      {
        id: 1417,
        title: 'Agendamentos',
        path: '/geral/headers-das-paginas/schedules',
        brands: ['bt', 'fr'],
      },
      {
        id: 1418,
        title: 'Kids',
        path: '/geral/headers-das-paginas/kids',
        brands: ['bt'],
      },
      {
        id: 1419,
        title: 'BT Pass',
        path: '/geral/headers-das-paginas/bt-pass',
        brands: ['bt'],
      },
    ],
  },
  {
    id: 15,
    title: 'Serviços',
    icon: '18-p-servicos-b.svg',
    secundaryIcon: 'bt/18-p-servicos-v-2.svg',
    path: '/geral/servicos',
    brands: ['bt'],
    page: Page.InfoServices,
  },
  {
    id: 16,
    title: 'Promoções',
    icon: '18-p-promocoes-b.svg',
    secundaryIcon: 'bt/18-p-promocoes-v-2.svg',
    path: '/geral/promocoes',
    brands: ['bt', 'fr'],
    page: Page.Promotions,
  },
  {
    id: 17,
    title: 'Detalhes dos Planos',
    icon: '18-p-planos-b.svg',
    secundaryIcon: 'bt/18-p-planos-v-2.svg',
    path: '/geral/detalhes-dos-planos',
    brands: ['bt', 'fr'],
    page: Page.PlanDetails,
  },
  {
    id: 18,
    title: 'Atividades',
    icon: '18-p-atividades-b.svg',
    secundaryIcon: 'bt/18-p-atividades-v-2.svg',
    path: '/geral/atividades',
    brands: ['bt', 'fr'],
    page: Page.Activities,
  },
  {
    id: 19,
    title: 'Avisos e Eventos',
    icon: '18-p-avisos-eventos-b.svg',
    secundaryIcon: 'bt/18-p-avisos-eventos-v-2.svg',
    path: '/geral/avisos-eventos',
    brands: ['bt'],
    page: Page.InformativeHolding,
  },
  {
    id: 20,
    title: 'Usuários e Permissões',
    icon: '18-p-permissoes-b.svg',
    secundaryIcon: 'bt/18-p-permissoes-v-2.svg',
    path: '/geral/usuarios-permissoes',
    brands: ['bt', 'fr'],
    page: Page.UserPermissions,
  },
  {
    id: 21,
    title: 'Periodização',
    icon: '18-p-periodizacao-b.svg',
    secundaryIcon: 'bt/18-p-periodizacao-v-2.svg',
    path: '/geral/periodizacao',
    brands: ['bt', 'fr'],
    page: Page.Periodization,
  },
];
