import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { EvoBaseParamsModel, EvoGymResponse, GymUnitAddress, GymUnitInfo, GymUnitPhone } from '@models';
import { evoBack, EvoBackEndpoints } from '@utils/app-endpoints';


@Injectable()
export class EvoGymService {


  constructor(private readonly http: HttpClient) { }

  public mapEvoContactInfoToLegacy(
    legacyContactInfo: GymUnitInfo,
    evoContactInfo: EvoGymResponse,
  ): GymUnitInfo {
    const address: GymUnitAddress = {
      ...legacyContactInfo.address,
      city: {
        ...legacyContactInfo.address.city,
        name: evoContactInfo.city,
        state: {
          ...legacyContactInfo.address.city.state,
          initials: evoContactInfo.stateShort,
          name: evoContactInfo.state,
        }
      },
      publicPlace: {
        ...legacyContactInfo.address.publicPlace,
        name: '',
      },
      neighborhood: evoContactInfo.neighborhood,
      number: evoContactInfo.number,
      street: evoContactInfo.address,
      zipCode: evoContactInfo.zipCode,
    };

    const phones: GymUnitPhone[] = [{
      ...legacyContactInfo.phones[0],
      ddd: +evoContactInfo.telephone.slice(0, 2),
      number: evoContactInfo.telephone.slice(2),
    }];

    return {
      ...legacyContactInfo,
      name: evoContactInfo.name,
      portalName: evoContactInfo.name,
      businessName: evoContactInfo.internalName,
      cnpj: evoContactInfo.cnpj,
      address,
      phones,
    };
  }

  public getGymsContactInfo(params?: EvoBaseParamsModel): Observable<EvoGymResponse[]> {
    return this.http.get<EvoGymResponse[]>(evoBack(EvoBackEndpoints.Configuration), { params: { ...params } });
  }
}
