import { EventEmitter, ElementRef } from '@angular/core';
import { AlertMessage, MessageMap } from '@models';
import { UploadService, BlockUiService, AlertMessageService, DialogService } from '@services';
import { environment } from '@env/environment';
import { AppConstants } from '@utils/app-constants';
import { GenericModalComponent } from '@sharedcomponents/generic-modal/generic-modal.component';
var DropAreaComponent = /** @class */ (function () {
    function DropAreaComponent(uploadService, dialogService, blockUiService, alertMessageService) {
        this.uploadService = uploadService;
        this.dialogService = dialogService;
        this.blockUiService = blockUiService;
        this.alertMessageService = alertMessageService;
        this.isLoading = false;
        this.dropAreaAuxText = 'Clique para selecionar ou arraste a imagem';
        this.multiple = false;
        this.photosEmitter = new EventEmitter();
        this.dropzoneActive = false;
        this.color = 'primary';
        this.mode = 'indeterminate';
        this.value = 50;
    }
    Object.defineProperty(DropAreaComponent.prototype, "getImages", {
        set: function (images) {
            this.images = images;
        },
        enumerable: true,
        configurable: true
    });
    DropAreaComponent.prototype.dropzoneState = function ($event) {
        this.dropzoneActive = $event;
    };
    DropAreaComponent.prototype.handleDrop = function (fileList) {
        var _this = this;
        if (!this.multiple && fileList.length > 1) {
            var data = {
                modalTitle: 'MÚLTIPLOS ARQUIVOS SELECIONADOS',
                modalContent: "Selecione apenas um arquivo para enviar.",
                pButtonText: 'CONTINUAR',
            };
            this.isLoading = false;
            this.openAlertModal(data);
            return;
        }
        Array.from(fileList).forEach(function (file) {
            if (file.type !== AppConstants.IMAGE_PNG && file.type !== AppConstants.IMAGE_JPG) {
                var data = {
                    modalTitle: 'TIPO DE ARQUIVO INVÁLIDO',
                    modalContent: "O arquivo \"" + file.name + "\" enviado \u00E9 inv\u00E1lido. Envie um arquivo de imagem em formato JPEG, JPG ou PNG.",
                    pButtonText: 'CONTINUAR'
                };
                _this.openAlertModal(data);
                _this.isLoading = false;
                return;
            }
            var img = new Image();
            img.src = window.URL.createObjectURL(file);
            img.onerror = function () {
                var data = {
                    modalTitle: 'ERRO NO ARQUIVO',
                    modalContent: "N\u00E3o foi poss\u00EDvel abrir o arquivo \"" + file.name + "\". Envie um arquivo de imagem em formato JPEG, JPG ou PNG.",
                    pButtonText: 'CONTINUAR'
                };
                _this.openAlertModal(data);
                _this.isLoading = false;
                return;
            };
            img.onload = function () {
                var width = img.naturalWidth;
                var height = img.naturalHeight;
                window.URL.revokeObjectURL(img.src);
                if ((width !== +_this.width || height !== +_this.height)) {
                    var data = {
                        modalTitle: 'DIMENSÕES DA IMAGEM INVÁLIDAS',
                        modalContent: "A imagem \"" + file.name + "\" tem " + width + "x" + height + "px e o tamanho permitido \u00E9\n            " + _this.width + "x" + _this.height + "px.",
                        pButtonText: 'CONTINUAR'
                    };
                    _this.openAlertModal(data);
                    _this.isLoading = false;
                    return;
                }
                else if (file.type !== AppConstants.IMAGE_PNG && file.type !== AppConstants.IMAGE_JPG) {
                    var data = {
                        modalTitle: 'TIPO DA IMAGEM NÃO É VÁLIDA',
                        modalContent: "O arquivo \"" + file.name + "\" enviado \u00E9 inv\u00E1lido. Envie um arquivo de imagem em formato JPEG, JPG ou PNG.",
                        pButtonText: 'CONTINUAR'
                    };
                    _this.openAlertModal(data);
                    _this.isLoading = false;
                    return;
                }
                else {
                    _this.uploadService.uploadFile([file], "" + (_this.selectedGym ? _this.selectedGym.tag : 'banners'), false, 'images', _this.selectedGym ? _this.selectedGym.slug : '').subscribe(function (res) {
                        if (res && _this.selectedGym) {
                            res.forEach(function (resFile) {
                                var newFile = {
                                    url: environment.s3buckets.images + "/" + resFile,
                                    description: '',
                                    fileName: resFile,
                                    formerUrl: resFile.split('.')[0],
                                    portalDescription: '',
                                    displayOrder: 0,
                                    objectType: 'CARROSSEL'
                                };
                                _this.images.unshift(newFile);
                                _this.photosEmitter.emit(_this.images);
                            });
                        }
                        else {
                            _this.photosEmitter.emit('/' + res[0]);
                        }
                    }, function () {
                        _this.blockUiService.decrement();
                        _this.alertMessageService.showToastr(AlertMessage.error(MessageMap.SEM_CONEXAO_INTERNET));
                    });
                }
            };
        });
    };
    DropAreaComponent.prototype.openAlertModal = function (data) {
        this.dialogService.openDialog(GenericModalComponent, {
            width: '796px',
            data: data
        });
    };
    DropAreaComponent.prototype.selectFileWihtClick = function () {
        this.someInput.nativeElement.click();
    };
    DropAreaComponent.prototype.selectFile = function (event) {
        this.handleDrop(event.target.files);
        this.someInput.nativeElement.value = null;
    };
    return DropAreaComponent;
}());
export { DropAreaComponent };
