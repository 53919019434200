import * as tslib_1 from "tslib";
import gql from 'graphql-tag';
var MembershipsToHideListGraphql = /** @class */ (function () {
    function MembershipsToHideListGraphql() {
    }
    MembershipsToHideListGraphql.querylistEvoMembershipsToHideOnPriceTable = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n    query {\n      listEvoMembershipsToHideOnPriceTable {\n        membershipName\n      }\n    }\n  "], ["\n    query {\n      listEvoMembershipsToHideOnPriceTable {\n        membershipName\n      }\n    }\n  "])));
    return MembershipsToHideListGraphql;
}());
export { MembershipsToHideListGraphql };
var templateObject_1;
