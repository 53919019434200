import { Component, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { GymUnit, PhotoUpdate, AlertMessage, MessageMap } from '@models';
import { UploadService, BlockUiService, AlertMessageService, DialogService } from '@services';
import { environment } from '@env/environment';
import { AppConstants } from '@utils/app-constants';
import { GenericModalComponent } from '@sharedcomponents/generic-modal/generic-modal.component';

@Component({
  selector: 'app-drop-area',
  templateUrl: './drop-area.component.html',
  styleUrls: ['./drop-area.component.scss']
})
export class DropAreaComponent {

  @Input() isLoading = false;
  @Input() selectedGym: GymUnit;
  @Input() set getImages(images: PhotoUpdate[]) {
    this.images = images;
  }

  @Input() dropAreaAuxText = 'Clique para selecionar ou arraste a imagem';
  @Input() dragText: string;
  @Input() dragImage: string;
  @Input() width: number;
  @Input() height: number;
  @Input() multiple = false;

  public images: PhotoUpdate[];

  @Output() photosEmitter = new EventEmitter<PhotoUpdate[] | string>();

  @ViewChild('someInput', { static: false }) someInput: ElementRef;

  dropzoneActive = false;

  color = 'primary';
  mode = 'indeterminate';
  value = 50;


  constructor(
    private uploadService: UploadService,
    public dialogService: DialogService,
    private blockUiService: BlockUiService,
    private alertMessageService: AlertMessageService
  ) { }


  dropzoneState($event: boolean) {
    this.dropzoneActive = $event;
  }

  handleDrop(fileList: FileList) {
    if (!this.multiple && fileList.length > 1) {
      const data = {
        modalTitle: 'MÚLTIPLOS ARQUIVOS SELECIONADOS',
        modalContent: `Selecione apenas um arquivo para enviar.`,
        pButtonText: 'CONTINUAR',
      };
      this.isLoading = false;
      this.openAlertModal(data);
      return;
    }
    Array.from(fileList).forEach(file => {
      if (file.type !== AppConstants.IMAGE_PNG && file.type !== AppConstants.IMAGE_JPG) {
        const data = {
          modalTitle: 'TIPO DE ARQUIVO INVÁLIDO',
          modalContent: `O arquivo "${file.name}" enviado é inválido. Envie um arquivo de imagem em formato JPEG, JPG ou PNG.`,
          pButtonText: 'CONTINUAR'
        };
        this.openAlertModal(data);
        this.isLoading = false;
        return;
      }
      const img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onerror = () => {
        const data = {
          modalTitle: 'ERRO NO ARQUIVO',
          modalContent: `Não foi possível abrir o arquivo "${file.name}". Envie um arquivo de imagem em formato JPEG, JPG ou PNG.`,
          pButtonText: 'CONTINUAR'
        };
        this.openAlertModal(data);
        this.isLoading = false;
        return;
      };
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        window.URL.revokeObjectURL(img.src);
        if ((width !== +this.width || height !== +this.height)) {
          const data = {
            modalTitle: 'DIMENSÕES DA IMAGEM INVÁLIDAS',
            modalContent: `A imagem "${file.name}" tem ${width}x${height}px e o tamanho permitido é
            ${this.width}x${this.height}px.`,
            pButtonText: 'CONTINUAR'
          };
          this.openAlertModal(data);
          this.isLoading = false;
          return;
        } else if (file.type !== AppConstants.IMAGE_PNG && file.type !== AppConstants.IMAGE_JPG) {
          const data = {
            modalTitle: 'TIPO DA IMAGEM NÃO É VÁLIDA',
            modalContent: `O arquivo "${file.name}" enviado é inválido. Envie um arquivo de imagem em formato JPEG, JPG ou PNG.`,
            pButtonText: 'CONTINUAR'
          };
          this.openAlertModal(data);
          this.isLoading = false;
          return;
        } else {
          this.uploadService.uploadFile([file], `${this.selectedGym ? this.selectedGym.tag : 'banners'}`,
          false, 'images', this.selectedGym ? this.selectedGym.slug : '').subscribe(
            res => {
              if (res && this.selectedGym) {
                res.forEach(resFile => {
                  const newFile: PhotoUpdate = {
                    url: `${environment.s3buckets.images}/${resFile}`,
                    description: '',
                    fileName: resFile,
                    formerUrl: resFile.split('.')[0],
                    portalDescription: '',
                    displayOrder: 0,
                    objectType: 'CARROSSEL'
                  };
                  this.images.unshift(newFile);
                  this.photosEmitter.emit(this.images);
                });
              } else {
                this.photosEmitter.emit('/' + res[0]);
              }
            },
            () => {
              this.blockUiService.decrement();
              this.alertMessageService.showToastr(
                AlertMessage.error(MessageMap.SEM_CONEXAO_INTERNET)
              );
            }
          );
        }
      };
    });
  }

  private openAlertModal(data) {
    this.dialogService.openDialog(GenericModalComponent, {
      width: '796px',
      data
    });
  }

  public selectFileWihtClick() {
    this.someInput.nativeElement.click();
  }

  public selectFile(event) {
    this.handleDrop(event.target.files);
    this.someInput.nativeElement.value = null;
  }
}
