<div class="modal-content">
  <div class="modal-header">
    PREVIEW {{ previewType === 'mobile' ? 'MOBILE' : 'WEB'}}
    <img src="./assets/icon_custom/18-p-fechar-b.svg" class="close-btn" (click)="closeModal()">
  </div>
  <div class="modal-body H4-LatoRegular-C7">
    <div class="all-content">
      <div class="overflow-x">
          <div class="main-container" [ngClass]="{'main-container-web': previewType === 'web'}">
              <img class="preview-image" [ngStyle]="{ 'background-image': 'url(' + banner.image + ')'}"
                [src]="previewType === 'web' ? './assets/images/bt/mascara-web.svg' : './assets/images/bt/mascara-mobile.svg'">
              <div class="preview-content" [ngClass]="{ 'preview-content-web': previewType === 'web' }">
                <div class="preview-content-body">
                  <div class="bottom-content">
                    <div class="content-footer" [ngClass]="{
                      'content-footer-web': previewType === 'web'
                    }">
                      <small class="preview-legal-text"
                      [ngClass]="{
                        'preview-legal-text-web': previewType === 'web'
                      }">
                      {{ banner.legalText }}
                    </small>
                    <div *ngIf="banner.buttonText" class="preview-button">{{ banner.buttonText }}</div>
                    </div>
                    <div class="preview-subtitle"
                      [ngClass]="{
                        'preview-subtitle-web': previewType === 'web'
                      }">{{ banner.subtitle }}
                    </div>
                  </div>
                  <div class="H1-OswaldMedium-B preview-title"
                    [ngClass]="{
                      'preview-title-web': previewType === 'web'
                    }">
                    {{ banner.title }}</div>
                </div>
                </div>
            </div>
      </div>
      <div class="B1-LatoRegular-C7 tip text-center">
        <strong>Atenção:</strong> A máscara representa a área segura onde o assunto da foto não será cortado quando a página
        for visualizada em diversas resoluções. Recomenda-se o uso de fotos em que o assunto principal não ultrapasse a área
        delimitada.
      </div>
    </div>
  </div>
</div>
