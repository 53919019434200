<div class="main-container" fxLayout="column" [formGroup]="promotionsForm">
  <div class="H5-LatoBold-C6 mb-10">
    TEXTO DA THUMBNAIL (APARECERÁ NA THUMBNAIL DA UNIDADE NA LISTA DE ACADEMIAS)
  </div>
  <div class="form-group">
    <input name="banner-title" type="text" class="input-text fill-available-width" formControlName="thumbnailText">
  </div>
  <app-error-message class="error" [control]="promotionsForm.controls.thumbnailText"></app-error-message>
  <div fxLayout="column" fxLayout.gt-md="row">
    <div ngClass.lt-lg="mb-16" class="justify-between" fxLayout="column" fxFlex="60">
      <div class="H5-LatoBold-C6 mb-10">TÍTULO DA PROMOÇÃO (APARECERÁ NO FLUXO DE MATRÍCULA/COMPRA)</div>
      <div class="form-group">
        <input name="banner-title" type="text" class="input-text fill-available-width" formControlName="purchaseTitle">
      </div>
      <app-error-message class="error" [control]="promotionsForm.controls.purchaseTitle"></app-error-message>
    </div>
    <div fxFlex="40" fxLayout="column" fxLayout.gt-xs="row">
      <div ngClass.gt-md="ml-16" ngClass.xs="mb-16" class="justify-between" fxLayout="column" fxFlex="50">
        <div class="H5-LatoBold-C6 mb-10">INÍCIO DA VEICULAÇÃO</div>
        <div fxLayout="row" class="form-group" (click)="initialDatePicker.open()">
          <input matInput (focus)="initialDatePicker.open()" (keydown)="false"
            [matDatepicker]="initialDatePicker" [max]="promotionsForm.value.finalDate"
            class="input-text w-100 no-pointer-events" formControlName="initialDate" [matDatepickerFilter]="dateFilter">
          <mat-datepicker-toggle matSuffix [for]="initialDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #initialDatePicker></mat-datepicker>
        </div>
        <app-error-message class="error" [control]="promotionsForm.controls.initialDate"></app-error-message>
      </div>
      <div ngClass.gt-xs="ml-16" class="justify-between" fxLayout="column" fxFlex="50">
        <div class="H5-LatoBold-C6 mb-10">FIM DA VEICULAÇÃO</div>
        <div fxLayout="row" class="form-group" (click)="finalDatePicker.open()">
          <input matInput (focus)="finalDatePicker.open()" (key)="false"
            [matDatepicker]="finalDatePicker"  [min]="promotionsForm.value.initialDate"
            class="input-text w-100 no-pointer-events" formControlName="finalDate" [matDatepickerFilter]="dateFilter">
          <mat-datepicker-toggle matSuffix [for]="finalDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #finalDatePicker></mat-datepicker>
        </div>
        <app-error-message class="error" [control]="promotionsForm.controls.finalDate"></app-error-message>
      </div>
    </div>
  </div>
  <div class="H5-LatoBold-C6 mb-10">TEXTO DA PROMOÇÃO (APARECERÁ NO FLUXO DE MATRÍCULA/COMPRA)</div>
  <div class="form-group h-85">
    <textarea class="input-text no-resize fill-available-width" formControlName="purchaseText"></textarea>
  </div>
  <app-error-message class="error" [control]="promotionsForm.controls.purchaseText"></app-error-message>
  <div class="H5-LatoBold-C6 mb-10">UNIDADES VINCULADAS</div>
  <div (click)="gymsSelectionModal()">
    <tag-input #tagInputGymUnits formControlName="gymUnities" (onRemove)="updateInvalidTagInput()"
    theme="tag-theme" [placeholder]="tagInputNotEmptyMessage" [secondaryPlaceholder]="tagInputEmptyMessage" class="promotion-gymUnities"
    [displayBy]="'name'" [class.invalid]="isInvalidTagInput"></tag-input>
  </div>
  <app-error-message class="error" [control]="promotionsForm.controls.gymUnities"></app-error-message>
  <button (click)="save()"
    class="bo-btn bo-primary-btn fit-content-width mt-24 px-35 H5-LatoBold-B">
    {{ promotionToEdit ? 'SALVAR' : 'ADICIONAR PROMOÇÃO'}}
  </button>
</div>
