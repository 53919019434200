import { PipeTransform } from '@angular/core';
import * as removeAccents from 'remove-accents-diacritics';
var FilterPipe = /** @class */ (function () {
    function FilterPipe() {
    }
    FilterPipe.prototype.transform = function (items, searchText, fieldToFilter) {
        var _this = this;
        if (!searchText) {
            return items;
        }
        searchText = searchText.trim();
        searchText = removeAccents.remove(searchText);
        searchText = searchText.replace(/[\\^$.|?*+(){}[\]]/g, function (c) { return '\\' + c; });
        var keysArray = fieldToFilter.split(',');
        var attributes = keysArray.map(function (key) { return key.split('.'); });
        return (items || []).filter(function (item) {
            return attributes.some(function (element) {
                return _this.getNested.apply(_this, [item].concat(element)) &&
                    new RegExp(searchText, 'gi').test(removeAccents.remove(_this.getNested.apply(_this, [item].concat(element))));
            });
        });
    };
    FilterPipe.prototype.getNested = function (object) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return args.reduce(function (obj, level) { return obj && obj[level]; }, object);
    };
    return FilterPipe;
}());
export { FilterPipe };
