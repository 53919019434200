import gql from 'graphql-tag';

export interface PageHeader {
    id?: number;
    tag?: string;
    title?: string;
    image?: string;
    errors?: any[];
}

export class PageHeaderGraphql {
    public static readonly queryGetPortalPageHeaderByTag = gql`
        query($tag: String!) {
            getPortalPageHeaderByTag(tag: $tag) {
            id
            tag
            title
            image
            }
        }
    `;

    public static readonly mutationUpdatePortalPageHeaderImage = gql`
        mutation($tag: String!, $image: String!) {
            updatePortalPageHeaderImage(tag: $tag, image: $image) {
                id
                tag
                title
                image
                errors {
                    field
                    message
                }
            }
        }
    `;

}
