import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ScrollService } from '@services/scroll.service';

@Component({
  selector: 'app-multiple-select-modal',
  templateUrl: './multiple-select-modal.component.html',
  styleUrls: ['./multiple-select-modal.component.scss', '../sided-menu-generic-modal.component.scss']
})
export class MultipleSelectModalComponent implements OnInit {

  modalTitle: string;
  searchText = '';

  selectedItemId: number;

  selectedSubItems: any[]  = [];
  oldSelectedSubItems: any[];
  checkAllSelected = false;

  messageSelectAll: string;

  items = [];
  subItems = [];

  constructor(
    public dialogRef: MatDialogRef<MultipleSelectModalComponent>,
    private scrollService: ScrollService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.modalTitle = this.data.modalTitle;
    this.items = this.data.items;
    this.subItems = this.data.subItems;
    this.selectedItemId = this.data.items[0].id;

    if (this.data.selectedSubItems && this.data.selectedSubItems.length) {
      this.selectedSubItems = this.data.selectedSubItems.slice();
      this.oldSelectedSubItems = this.data.selectedSubItems.slice();
      this.checkAllSelected = this.data.subItems.length === this.data.selectedSubItems.length;
      this.selectedItemId = this.data.selectedItemId ? this.data.selectedItemId : this.selectedSubItems[0].activityGroup.id;
    }
    this.selectItem(this.selectedItemId);

    if (this.data.messageSelectAll) {
      this.messageSelectAll = this.data.messageSelectAll;
    }

  }

  selectItem(itemId: number): void {
    this.selectedItemId = itemId;
    this.subItems = this.data.subItems.filter(subItem => subItem.owner === itemId);
    this.searchText = '';
    this.scrollService.scroll();
  }

  closeModal(): void {
    this.dialogRef.close(this.oldSelectedSubItems);
  }

  confirmModal(): void {
    this.dialogRef.close(this.selectedSubItems);
  }

  getItem(subitem: any): boolean {
    return this.selectedSubItems.some(internalSubItem => internalSubItem.id === subitem.id);
  }

  selectAllToggler(item: boolean): void {
    if (item) {
      this.selectedSubItems = this.data.subItems;
    } else {
      this.selectedSubItems = [];
    }
  }

  onCheckChangeHandler(subItem: any): void {
    const subItemFound = this.selectedSubItems.find(internalSubItem => internalSubItem.id === subItem.id);
    if (subItemFound) {
      this.selectedSubItems = this.selectedSubItems.filter(internalGym => internalGym.id !== subItem.id);
    } else {
      this.selectedSubItems.push(subItem);
    }
    if (this.data.subItems.length !== this.selectedSubItems.length) {
      this.checkAllSelected = false;
    }
  }
}
