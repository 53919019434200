import * as tslib_1 from "tslib";
import gql from 'graphql-tag';
var BannerGraphql = /** @class */ (function () {
    function BannerGraphql() {
    }
    BannerGraphql.mutationCreateOrUpdatePortalHomeBannerHeaders = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n    mutation createOrUpdatePortalHomeBannerHeaders($portalHomeBannerHeaders: [PortalHomeBannerHeaderUpdate!]) {\n      createOrUpdatePortalHomeBannerHeaders(portalHomeBannerHeaders: $portalHomeBannerHeaders) {\n        title\n        subtitle\n        image\n        buttonText\n        linkUrl\n        legalText\n        displayOrder\n        initialDate\n        finalDate\n        errors {\n          field\n          message\n        }\n      }\n    }\n  "], ["\n    mutation createOrUpdatePortalHomeBannerHeaders($portalHomeBannerHeaders: [PortalHomeBannerHeaderUpdate!]) {\n      createOrUpdatePortalHomeBannerHeaders(portalHomeBannerHeaders: $portalHomeBannerHeaders) {\n        title\n        subtitle\n        image\n        buttonText\n        linkUrl\n        legalText\n        displayOrder\n        initialDate\n        finalDate\n        errors {\n          field\n          message\n        }\n      }\n    }\n  "])));
    BannerGraphql.queryListPortalHomeBannerHeaders = gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n        query {\n          listAllPortalHomeBannerHeaders {\n            title\n            subtitle\n            image\n            buttonText\n            linkUrl\n            legalText\n            displayOrder\n            initialDate\n            finalDate\n          }\n        }\n    "], ["\n        query {\n          listAllPortalHomeBannerHeaders {\n            title\n            subtitle\n            image\n            buttonText\n            linkUrl\n            legalText\n            displayOrder\n            initialDate\n            finalDate\n          }\n        }\n    "])));
    return BannerGraphql;
}());
export { BannerGraphql };
var templateObject_1, templateObject_2;
