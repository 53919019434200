import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { environment } from '@env/environment';
import { SelectGymModalComponent } from '@sharedcomponents';
import { GymUnit } from '@models';
import { StorageService } from '@services';
import { AppConstants } from '@utils/app-constants';
import { FormUtil } from '@utils/form-util';

@Component({
  selector: 'app-entry-schedule-report-modal',
  templateUrl: './entry-schedule-report-modal.component.html',
  styleUrls: ['./entry-schedule-report-modal.component.scss']
})
export class EntryScheduleReportModalComponent implements OnInit {

  form: FormGroup;

  gymUnits: GymUnit[];
  selectedGym: GymUnit;

  yesterday: Date;

  constructor(
    private storageService: StorageService,
    private readonly matDialog: MatDialog,
    private readonly formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EntryScheduleReportModalComponent>,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      gym: new FormControl(null, Validators.required),
      initialDate: new FormControl(null, Validators.required),
      finalDate: new FormControl(null, Validators.required),
    });

    this.yesterday = new Date();
    this.yesterday.setDate(this.yesterday.getDate() - 1);

    const gyms = JSON.parse(this.storageService.get(AppConstants.STOR_RECEP_GYMS));
    this.gymUnits = gyms.receptionistLogin;

    if (this.gymUnits && this.gymUnits.length === 1) {
      this.selectedGym = this.gymUnits[0];
      this.form.get('gym').setValue(this.selectedGym.name);
      this.form.get('gym').disable();
    }
  }

  closeModal(): void {
    this.dialogRef.close(false);
  }

  selectGym() {
    this.matDialog.open(SelectGymModalComponent, {
      width: '796px',
      maxHeight: '90vh',
      data: { gyms: this.gymUnits, selectedGym: this.selectedGym, noRedirect: true, },
    }).afterClosed().subscribe(result => {
      if (result) {
        this.selectedGym = result;
        this.form.get('gym').setValue(this.selectedGym.name);
      }
    });
  }

  generateReport() {
    FormUtil.touchForm(this.form);
    if (this.form.valid) {
      const initialDate = (this.form.value.initialDate as Date).getTime();
      const finalDate = (this.form.value.finalDate as Date).getTime();
      const gymId = this.selectedGym.id;

      const csvUrl = `${environment.portal}/csv/dailyPresenceCsv/${initialDate}&${finalDate}&${gymId}`;
      window.open(csvUrl);
    }
  }
}
