import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { InfoService } from '@models';

@Component({
  selector: 'app-info-services-edit-modal',
  templateUrl: './info-services-edit-modal.component.html',
  styleUrls: ['./info-services-edit-modal.component.scss'],
})
export class InfoServicesEditModalComponent {

  constructor(
    public dialogRef: MatDialogRef<InfoServicesEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  public closeModal(): void {
    this.dialogRef.close(false);
  }

  public editInfoService(infoService: InfoService) {
    this.dialogRef.close(infoService);
  }
}
