export class StringUtil {

  static strcmp(a: string, b: string) {
    if (typeof a !== 'string') {
      return typeof b !== 'string' ? 0 : 1;
    }
    return a.localeCompare(b, 'pt', {
      sensitivity: 'base',
      ignorePunctuation: true,
      numeric: true,
    });
  }

  // returns the hash code of a string
  static hashCode(value: string): number {
    let hash = 0;
    let chr: number;
    if (value.length === 0) {
      return hash;
    }
    for (let i = 0; i < value.length; i++) {
      chr = value.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }
}
