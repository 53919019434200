import gql from 'graphql-tag';

import { Address } from './address';
import { RoleBackoffice } from './role-backoffice';


export interface GymUnit {
    id: number;
    name?: string;
    portalName?: string;
    slug?: string;
    address?: Address;
    tag?: string;
    searchTags?: string;
    roles?: RoleBackoffice[];
}

export interface GymUnitInfo {
  id: number;
  showChannels: boolean;
  name: string;
  portalName: string;
  businessName: string;
  cnpj: string;
  slug: string;
  preSale: boolean;
  isGymUnit: boolean;
  reportText: string;
  email: string;
  activePromotion: any;
  gymUnitSocialMedias: GymUnitSocialMedias[];
  address: GymUnitAddress;
  phones: GymUnitPhone[];
  workingPeriods: GymUnitWorkingPeriods[];
  evoMigratedUnit: { id: number; };
}

export interface GymUnitWorkingPeriods {
  id: number;
  dayOfTheWeek: string;
  timeOfDay: string;
}

export interface GymUnitPhone {
  id: number;
  ddd: number;
  number: string;
  main: boolean;
}
export interface GymUnitAddress {
    id: number;
    street: string;
    number: string;
    adjunct: string;
    neighborhood: string;
    zipCode: string;
    latitude: string;
    longitude: string;
    publicPlace: {
      acronym: string;
      id: number;
      idiom: string;
      name: string;
      visible: boolean;
    };
    city: {
      id: number;
      name: string;
      state: {
          id: number;
          name: string;
          initials: string;
          country: {
            id: number;
            name: string;
          },
      },
    };
}

export interface GymUnitSocialMedias {
  url: string;
  display: string;
  socialMedia: {
      id: number;
      name: string;
      urlImage: string;
  };
}

export class GymUnitGraphql {
    public static readonly queryListGymUnitsNotHoldingWithState = gql`
        query {
            listGymUnitsThatAreNotHoldingForBackOffice {
                id
                name
                slug
                tag
                searchTags
                portalName
                gymUnitSocialMedias {
                    url
                    display
                    socialMedia {
                        id
                        name
                    }
                }
                address {
                    id
                    latitude
                    longitude
                    city {
                        id
                        state {
                            id
                            name
                            initials
                        }
                    }
                }
            }
        }
  `;

  public static readonly mutationUpdateSearchTags = gql`
    mutation UpdateSearchTags($id: Long!, $searchTags: String!) {
        updateSearchTags(id: $id, searchTags: $searchTags) {
            id
            errors {
                field
                message
            }
        }
    }
    `;

  public static readonly mutationUpdatePortalName = gql`
    mutation updatePortalName($id: Long!, $portalName: String!) {
        updatePortalName(id: $id, portalName: $portalName) {
            id
            portalName
            errors {
                field
                message
            }
        }
    }
    `;

  public static readonly queryGetGymUnitBySlug = gql`
    query($slug: String!) {
      getGymUnitBySlug(slug: $slug) {
        id
        evoMigratedUnit { id }
        showChannels
        name
        portalName
        businessName
        cnpj
        slug
        preSale
        isGymUnit
        reportText
        email
        activePromotion {
          id
          thumbnailText
          purchaseTitle
          purchaseText
          initialDate
          finalDate
        }
        gymUnitSocialMedias {
          url
          display
          socialMedia {
            id
            name
            urlImage
          }
        }
        address {
          id
          street
          number
          adjunct
          neighborhood
          zipCode
          latitude
          longitude
          publicPlace {
            acronym
            id
            idiom
            name
            visible
          }
          city {
            id
            name
            state {
              id
              name
              initials
              country {
                id
                name
              }
            }
          }
        }
        phones {
          id
          ddd
          number
          main
        }
        workingPeriods {
          id
          dayOfTheWeek
          timeOfDay
        }
      }
    }
  `;
}
