import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ConvenienceIcon, Banner } from '@models';

@Component({
  selector: 'app-banners-preview-modal',
  templateUrl: './banners-preview-modal.component.html',
  styleUrls: ['./banners-preview-modal.component.scss']
})
export class BannersPreviewModalComponent implements OnInit {

  public banner: Banner;
  public previewType: string;

  constructor(
    public dialogRef: MatDialogRef<BannersPreviewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.banner = this.data.preview.banner;
    this.previewType = this.data.preview.previewType;
  }

  closeModal(icon?: ConvenienceIcon): void {
    this.dialogRef.close(icon || false);
  }
}
