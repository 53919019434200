<div #containerFileDrop fxLayout.lt-sm="column" fxLayout.gt-xs="row" class="container-file-drop">
  <ng-container *ngIf="!isToViewOnly" >
    <div [ngStyle]="calculateSizeBox()" fxLayout="row" fxLayoutAlign="center center" *ngIf="isLoading; else showComponent">
      <mat-progress-spinner [color]="color" [mode]="mode"
        [value]="value">
      </mat-progress-spinner>
    </div>

    <ng-template #showComponent>
      <div
        #uploadBox
        fxLayout="column" fxLayoutAlign="space-evenly center"
        class="upload-box"
        [class.invalid]="invalid"
        appFileDrop
        (filesDropped)="handleDrop($event)"
        (filesHovered)="dropzoneState($event)"
        [ngClass]="{
          'active': !disabledComponent && dropzoneActive,
          'file-drop-disabled': disabledComponent,
          'terms':  purpose == 'terms'
        }"
        (click)="selectFileWihtClick()"
        fxFlexOrder="1"
        [ngStyle]="calculateSizeBox()"
      >
        <ng-container *ngIf="hasPdfFile; else hasNoPDF">
          <div class="B1-LatoRegular-C7 upload-box-text">
              Arquivo Enviado!
          </div>
          <img class="image mt-10" src="./assets/icon_custom/bt/36-p-check-c-7.svg">
        </ng-container>
        <ng-template #hasNoPDF>
          <div class="B1-LatoRegular-C4 upload-box-text">
            {{ dragText || 'Clique para selecionar ou arraste a imagem' }}
          </div>
          <img class="image" [src]="dragImage || './assets/icon_custom/36-p-camera-c-4.svg'">
        </ng-template>
      </div>
      <input type="file" (change)="selectFile($event)" class="file-input" #someInput>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="purpose !== 'terms'">
    <div *ngIf="invalid && !isToViewOnly" [fxFlexOrder]="orderError"
    class="mr-16 input-file-error text-start">A imagem é obrigatória</div>
    <div #property *ngIf="!isToViewOnly" class="B1-LatoRegular-C4 w-100 mt-6 property" [fxFlexOrder]="orderProperty">Propriedades da imagem: {{ standardWidth }}x{{ standardHeigth }}, em JPEG, JPG ou PNG.</div>
    <img #image *ngIf="file && file.url" [src]="file.url" [fxFlexOrder]="orderImage" [ngStyle]="calculateSizeBox()"
      [ngClass]="{'thumbnail-size': purpose === 'thumbnail', 'image-horizontal-order': isHorizontal && !isToViewOnly, 'image-vertical-order': !isHorizontal}">
  </ng-container>
</div>
