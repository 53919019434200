import { FormGroup, FormArray, AbstractControl } from '@angular/forms';

export class FormUtil {

  static touchForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      const controle = formGroup.get(campo);
      controle.markAsTouched();
      controle.markAsDirty();
      controle.updateValueAndValidity();
      if (controle instanceof FormGroup) {
        this.touchForm(controle);
      }
    });
  }

  static touchFormWihoutPropagation(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      const controle = formGroup.get(campo);
      controle.markAsTouched({ onlySelf: true });
      controle.markAsDirty({ onlySelf: true });
      controle.updateValueAndValidity({
        emitEvent: false,
        onlySelf: true
      });
      if (controle instanceof FormGroup) {
        this.touchForm(controle);
      }
    });
  }

  static touchFormArray(formArray: FormArray) {
    formArray.controls.forEach((formGroup: FormGroup) => {
      Object.keys(formGroup.controls).forEach(campo => {
        const controle = formGroup.get(campo);
        controle.markAsTouched();
        controle.markAsDirty();
        controle.updateValueAndValidity();
        if (controle instanceof FormGroup) {
          this.touchForm(controle);
        }
      });
    });
  }

  static resetForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      const controle = formGroup.get(campo);
      controle.markAsUntouched();
      controle.markAsPristine();
      controle.updateValueAndValidity();
      if (controle instanceof FormGroup) {
        this.resetForm(controle);
      }
    });
  }

  static checkURL(formControl: AbstractControl, prefix: string[]) {
    formControl.markAsTouched();
    const value = formControl.value;
    prefix = prefix.map(v => (v += '://')).map(v => v.substr(0, value.length));

    if (prefix.some(v => value.length >= v.length && value.startsWith(v)) && formControl.hasError('validUrl')) {
        delete formControl.errors.validUrl;

        if (Object.keys(formControl.errors).length === 0) {
          formControl.markAsUntouched();
        }
    }
  }

  static trimFieldsForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      if (formGroup.controls[key] instanceof FormGroup) {
        this.trimFieldsForm(formGroup.controls[key] as FormGroup);
      } else if (formGroup.controls[key] instanceof FormArray) {
        (formGroup.controls[key] as FormArray).controls.forEach(
          (fg: FormGroup) => {
            this.trimFieldsForm(fg);
          },
        );
      } else {
        if (typeof formGroup.get(key).value === 'string') {
          formGroup.get(key).setValue(formGroup.get(key).value.trim());
        }
      }
    });
  }
}
