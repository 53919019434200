import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Promotion } from '@models/promotion';

@Component({
  selector: 'app-promotions-edit-modal',
  templateUrl: './promotions-edit-modal.component.html',
  styleUrls: ['./promotions-edit-modal.component.scss']
})
export class PromotionsEditModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PromotionsEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  editPromotion(promotion: Promotion) {
    this.dialogRef.close(promotion);
  }

}
