<app-sided-menu-generic-modal [hasAsideMenu]="!!false" modalTitle="SELECIONE O PROFESSOR">
  
  <div header class="search-box-with-btn" fxHide.lt-sm>
    <input class="search-input" type="text" [(ngModel)]="searchText">
    <img class="search-icon" src="./assets/images/24-p-lupa-c-7.svg">
  </div>


  <div main *ngIf="teachers">
    <div class="search-list" *ngFor="let teacher of teachers | filter : searchText : 'name'">
      <div class="gym-name H4-LatoRegular-C9" (click)="selectTeacher(teacher.id)"
      [ngClass]="{'selected': selectedTeacher === teacher.id}"
      >
        {{ teacher.name }}
      </div>
    </div>
  </div>
</app-sided-menu-generic-modal>
