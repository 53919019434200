import * as tslib_1 from "tslib";
import gql from 'graphql-tag';
import { GraphqlParser } from '@utils/graphql-parser';
export var InformativeStatus;
(function (InformativeStatus) {
    InformativeStatus["DRAFT"] = "Rascunho";
    InformativeStatus["PUBLISHED"] = "Publicado";
    InformativeStatus["EXPIRED"] = "Expirado";
    InformativeStatus["SCHEDULED"] = "Agendado";
})(InformativeStatus || (InformativeStatus = {}));
var InformativeGraphql = /** @class */ (function () {
    function InformativeGraphql() {
    }
    InformativeGraphql.parseToCreateMutation = function (informatives) {
        var body = '';
        informatives.forEach(function (informative, index) {
            var newInformative = JSON.parse(JSON.stringify(informative));
            var gyms = newInformative.gymUnities.map(function (g) { return g.id; });
            delete newInformative.gymUnities;
            delete newInformative.id;
            if (newInformative.place) {
                delete newInformative.place.__typename;
            }
            body += "createInformative" + index + ": createInformative(\n        gymUnitIds: [" + gyms.join(',') + "],\n        informative: {\n          " + GraphqlParser.parseJSONGraphQL(newInformative, true) + "\n        }\n      ) {\n        id\n        errors {\n          field\n          message\n        }\n      }\n";
        });
        return gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["mutation { ", " }"], ["mutation { ", " }"])), body);
    };
    InformativeGraphql.parseToUpdateMutation = function (informatives) {
        var body = '';
        informatives.forEach(function (informative, index) {
            var gyms = informative.gymUnities.map(function (g) { return g.id; });
            var informativeToUpdate = JSON.parse(JSON.stringify(informative));
            delete informativeToUpdate.gymUnities;
            if (informativeToUpdate.place) {
                delete informativeToUpdate.place.__typename;
            }
            body += "updateInformative" + index + ": updateInformative(\n        id: " + informative.id + ",\n        gymUnitIds: [" + gyms.join(',') + "],\n        informative: {\n          " + GraphqlParser.parseJSONGraphQL(informativeToUpdate, true) + "\n        }\n      ) {\n        id\n        errors {\n          field\n          message\n        }\n      }\n";
        });
        return gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["mutation { ", " }"], ["mutation { ", " }"])), body);
    };
    InformativeGraphql.queryListInformativesByGymUnit = gql(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n    query($gymUnitId: Long!) {\n      listInformativesByGymUnit(gymUnitId: $gymUnitId) {\n        id\n        title\n        description\n        imageUrl\n        published\n        isEvent\n        status\n        gymUnities {\n          id\n          name\n          address {\n            id\n            city {\n              id\n              state {\n                id\n                name\n                initials\n              }\n            }\n          }\n        }\n        teacherName\n        place {\n          id\n          name\n        }\n        otherPlace\n        initialPublishingDate\n        finalPublishingDate\n        eventDate\n        initialTimeHour\n        finalTimeHour\n        errors {\n          field\n          message\n        }\n      }\n    }\n  "], ["\n    query($gymUnitId: Long!) {\n      listInformativesByGymUnit(gymUnitId: $gymUnitId) {\n        id\n        title\n        description\n        imageUrl\n        published\n        isEvent\n        status\n        gymUnities {\n          id\n          name\n          address {\n            id\n            city {\n              id\n              state {\n                id\n                name\n                initials\n              }\n            }\n          }\n        }\n        teacherName\n        place {\n          id\n          name\n        }\n        otherPlace\n        initialPublishingDate\n        finalPublishingDate\n        eventDate\n        initialTimeHour\n        finalTimeHour\n        errors {\n          field\n          message\n        }\n      }\n    }\n  "])));
    InformativeGraphql.queryListAllInformatives = gql(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n    query {\n      listAllInformatives {\n        id\n        title\n        description\n        imageUrl\n        published\n        isEvent\n        status\n        gymUnities {\n          id\n          name\n          address {\n            id\n            city {\n              id\n              state {\n                id\n                name\n                initials\n              }\n            }\n          }\n        }\n        teacherName\n        place {\n          id\n          name\n        }\n        otherPlace\n        initialPublishingDate\n        finalPublishingDate\n        eventDate\n        initialTimeHour\n        finalTimeHour\n        errors {\n          field\n          message\n        }\n      }\n    }\n  "], ["\n    query {\n      listAllInformatives {\n        id\n        title\n        description\n        imageUrl\n        published\n        isEvent\n        status\n        gymUnities {\n          id\n          name\n          address {\n            id\n            city {\n              id\n              state {\n                id\n                name\n                initials\n              }\n            }\n          }\n        }\n        teacherName\n        place {\n          id\n          name\n        }\n        otherPlace\n        initialPublishingDate\n        finalPublishingDate\n        eventDate\n        initialTimeHour\n        finalTimeHour\n        errors {\n          field\n          message\n        }\n      }\n    }\n  "])));
    return InformativeGraphql;
}());
export { InformativeGraphql };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
