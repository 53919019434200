import { AbstractControl, ValidatorFn } from '@angular/forms';

export function ValidateHour(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const v: string = control.value;

    const stringRegex = '^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$';

    const regexUrl = new RegExp(stringRegex , 'i');

    if (!!v && !regexUrl.test(v)) {
      return { validHour: true };
    }
    return null;
  };
}
