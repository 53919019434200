<div class="modal-content">
  <div class="modal-header">
    {{ title }}
    <img src="./assets/icon_custom/18-p-fechar-b.svg" class="close-btn" (click)="closeModal()">
  </div>
  <div class="modal-body H4-LatoRegular-C7">
    <div fxLayoutAlign="center center" fxLayout="row" class="icons">
      <div class="service-icon" *ngFor="let icon of icons" [class.active]="selectedIcon === icon.icon" fxFlex="nogrow" fxLayoutAlign="center center">
        <img *ngIf="icon.display" [src]="icon.icon" (click)="closeModal(icon)">
      </div>
    </div>
  </div>
</div>
