import { environment } from '@env/environment';

const genericEndpointInterpolation = (baseurl: string, path: string, params: Array<string | number> = []): string => {
  const interpoled = params.reduce<string>((old, curr, i) => old.split(`{{${i}}}`).join(curr.toString()), path);
  return baseurl + interpoled;
};

export const enum SurEndpoints { // MUST start with a slash (/)
  Root = '/',
  Url = '/graphql',
  Login = '/login',
  BucketPolicy = '/s3Policy/retrieveBucketPolicy?bucketName={{0}}&quantity={{1}}',
}

export const enum PortalEndpoints {
  Root = '/',

  AppToken = '/auth',
  ZoomRoomInfo = '/zoom/zoomRoomInformation',
}

export const enum ServiceBackEndpoints {
  Root = '/',

  // EVO
  EvoCheckMigratingGym = '/evo/unit/isMigratedUnit',
  EvoSendRequest = '/legacy/evo/sendRequest',
}

export const enum EvoBackEndpoints {
  Root = '/',
  Configuration = '/api/v1/configuration',
  Membership = '/api/v1/membership',
}

export const sur = (path: SurEndpoints, ...params: (string | number)[]): string => {
  return genericEndpointInterpolation(environment.baseUrl, path, params);
};

export const portalBack = (path: PortalEndpoints, ...params: (string | number)[]): string => {
  return genericEndpointInterpolation(environment.portal, path, params);
};

export const serviceBack = (path: ServiceBackEndpoints, ...params: (string | number)[]): string => {
  return genericEndpointInterpolation(environment.services.baseUrl, path, params);
};

export const evoBack = (path: EvoBackEndpoints, ...params: (string | number)[]): string => {
  return genericEndpointInterpolation(environment.services.evoBaseUrl, path, params);
};

// tslint:disable-next-line:max-classes-per-file
export class AppEndpoints {
  public static get(value = '', ...params: Array<string|number>) {
    // Replaces {{i}} with params[i]
    return params.reduce<string>((old, curr, i) => old.split(`{{${i}}}`).join(curr.toString()), value);
  }
}

export const isToOldCanais = (url: string) => url.startsWith(sur(SurEndpoints.Root));

export const isEvoService = (url: string) => url.startsWith(evoBack(EvoBackEndpoints.Root));

export const isEvoRedirectRequest = (url: string) => url.startsWith(serviceBack(ServiceBackEndpoints.EvoSendRequest));
