import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { SelectIconModalComponent } from '@sharedcomponents/select-icon-modal/select-icon-modal.component';
import { Convenience, ConvenienceIcon, AlertMessage, MessageMap } from '@models';
import { ConvenienceService, AlertMessageService, DialogService } from '@services';
import { FormUtil } from '@utils/form-util';
import { ValidateUrl } from '@validators/url.validator';

@Component({
  selector: 'app-convenience-form',
  templateUrl: './convenience-form.component.html',
  styleUrls: ['./convenience-form.component.scss'],
})
export class ConvenienceFormComponent implements OnInit {
  public convenienceForm: FormGroup;

  @Input() convenienceToEdit: Convenience;
  @Output() convenienceEmitter: EventEmitter<Convenience> = new EventEmitter();

  icons: ConvenienceIcon[];
  selectedIconId: number;

  constructor(
    private formBuilder: FormBuilder,
    private convenienceService: ConvenienceService,
    private alertMessageService: AlertMessageService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.convenienceForm = this.formBuilder.group({
      name: new FormControl(
        this.convenienceToEdit ? this.convenienceToEdit.name : '',
        [Validators.required, Validators.maxLength(48)],
      ),
      url: new FormControl(
        this.convenienceToEdit ? this.convenienceToEdit.url : '',
        [ValidateUrl(true), Validators.maxLength(255)],
      ),
      description: new FormControl(
        this.convenienceToEdit ? this.convenienceToEdit.description : '',
        [Validators.required],
      ),
      icon: new FormControl(
        this.convenienceToEdit ? this.convenienceToEdit.icon : '',
        [Validators.required],
      ),
      displayOrder: new FormControl(
        this.convenienceToEdit ? this.convenienceToEdit.displayOrder : 1,
        [Validators.required],
      ),
      isSelected: false,
    });
    this.convenienceService.getConvenienceIcons().subscribe(res => {
      this.icons = res;
    });
  }

  public openIconModal() {
    this.dialogService.openDialog<SelectIconModalComponent<ConvenienceIcon>>(SelectIconModalComponent, {
      width: '645px',
      maxWidth: window.innerWidth < 600 ? '100vw' : '645px',
      maxHeight: '90vh',
      data: {
        title: 'SELECIONE O ÍCONE DA CONVENIÊNCIA',
        icons: this.icons,
        selectedIcon: this.convenienceForm.controls.icon.value
          ? this.convenienceForm.controls.icon.value
          : null,
      },
    });
    this.dialogService.afterClosed().subscribe(icon => {
      if (icon) {
        this.convenienceForm.controls.icon.setValue(icon.icon);
        this.selectedIconId = icon.id;
      }
    });
  }

  public save() {
    FormUtil.touchForm(this.convenienceForm);
    if (this.convenienceForm.valid) {
      this.trimFields(this.convenienceForm);
      this.selectedIconId = null;
      this.convenienceEmitter.emit(this.convenienceForm.value);
      this.convenienceForm.reset();
      this.convenienceForm.controls.displayOrder.setValue(1);
      this.convenienceForm.controls.isSelected.setValue(false);
    } else {
      this.alertMessageService.showToastr(AlertMessage.error(MessageMap.CAMPOS_EM_VERMELHO));
    }
  }

  trimFields(form: FormGroup) {
    form.value.name = form.value.name ? form.value.name.trim() : '';
    form.value.url = form.value.url ? form.value.url.trim() : '';
    form.value.description = form.value.description
      ? form.value.description.trim()
      : '';
  }

  clearErroUrl() {
    FormUtil.checkURL(this.convenienceForm.controls.url, [
      'http',
      'https',
      'ftp',
    ]);
  }
}
