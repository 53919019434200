import { StringUtil } from '../../../utils/string.util';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GymUnit } from '@models';
import { ScrollService } from '@services/scroll.service';

interface MappedState {
  id: number;
  name: string;
}
interface Data {
  gyms: GymUnit[];
  selectedGyms: GymUnit[];
  messageSelectAll?: string;
}
@Component({
  selector: 'app-choose-gyms-modal',
  templateUrl: './choose-gyms-modal.component.html',
  styleUrls: ['./choose-gyms-modal.component.scss', '../sided-menu-generic-modal.component.scss']
})
export class ChooseGymsModalComponent implements OnInit {

  states: MappedState[];
  gyms: GymUnit[] = [];
  oldSelectedGyms: GymUnit[] = [];
  selectedGyms: GymUnit[] = [];
  selectedStateId: number;
  searchText = '';
  checkAllSelected = false;
  messageSelectAll = 'Selecionar toda a Rede';

  constructor(
    public dialogRef: MatDialogRef<ChooseGymsModalComponent>,
    private scrollService: ScrollService,
    @Inject(MAT_DIALOG_DATA) public data: Data,
  ) { }

  ngOnInit() {
    if (this.data.selectedGyms) {
      this.selectedGyms = this.data.selectedGyms.slice();
      this.oldSelectedGyms = this.data.selectedGyms.slice();
      this.checkAllSelected = this.data.gyms.length === this.data.selectedGyms.length;
    }
    if (this.data.messageSelectAll) {
      this.messageSelectAll = this.data.messageSelectAll;
    }
    this.getStatesWithoutDuplicate();
  }

  public selectState(stateId: number): void {
    this.selectedStateId = stateId;
    this.gyms = this.data.gyms.filter(gym => gym.address.city.state.id === stateId);
    this.searchText = '';
    this.scrollService.scroll();
  }

  closeModal(): void {
    this.dialogRef.close(this.oldSelectedGyms);
  }

  confirmModal(): void {
    this.dialogRef.close(this.selectedGyms);
  }

  getStatesWithoutDuplicate(): void {
    this.states = this.data.gyms.map((x: any) => {
      return { name: x.address.city.state.name, id: x.address.city.state.id };
    });
    this.states = Array.from(new Set(this.states.map(a => a.id)))
      .map(id => this.states.find(a => a.id === id));
    this.states.sort((stateA: MappedState, stateB: MappedState) => {
      return StringUtil.strcmp(stateA.name, stateB.name);
    });
    if (this.states.length) {
      this.selectedStateId = this.states[0].id;
      if (this.selectedGyms.length) {
        this.selectedGyms.sort((a, b) => {
          return StringUtil.strcmp(
            a.address.city.state.name,
            b.address.city.state.name,
          );
        });
        this.selectedStateId = this.selectedGyms[0].address.city.state.id;
      }
      this.selectState(this.selectedStateId);
    }
  }

  onCheckChangeHandler(gym: GymUnit): void {
    const gymFound = this.selectedGyms.find(internalGym => internalGym.id === gym.id);
    if (gymFound) {
      this.selectedGyms = this.selectedGyms.filter(internalGym => internalGym.id !== gym.id);
    } else {
      this.selectedGyms.push(gym);
    }
    if (this.data.gyms.length !== this.selectedGyms.length) {
      this.checkAllSelected = false;
    }
  }

  getState(gym: GymUnit): boolean {
    return this.selectedGyms.some(internalGym => internalGym.id === gym.id);
  }

  selectAllToggler(state: boolean): void {
    if (state) {
      this.selectedGyms = this.data.gyms;
    } else {
      this.selectedGyms = [];
    }
  }

}
