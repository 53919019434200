import gql from 'graphql-tag';
import { GraphqlParser } from '@utils/graphql-parser';

export interface Activity {
  id: number;
  name: string;
  calories: string;
  description: string;
  activityClassification: ActivityClassification;
  activityGroup?: ActivityGroup;

  // created just for logical usage in modal of select activities. This attribute is not returned in the service
  owner?: number;
  dirty?: boolean;
}

export interface ActivityGroup {
  id: number;
  name: string;
}

export interface ActivityClassification {
  id: number;
  name: string;
}


export class ActivityGraphql {

  public static readonly queryListActiveActivities = gql`
    query {
      listActiveActivities {
        id
        name
        calories
        description
        activityClassification {
          id
          name
        }
        activityGroup {
          id
          name
        }
      }
    }
  `;

  public static readonly queryListActiveActivitiesWithPeriodization = gql`
    query {
      listActiveActivitiesWithPeriodization {
        id
        name
      }
    }
  `;

  public static parseToUpdateMutation(activities: Activity[]): any {
    let body = '';
    activities.forEach((activity, index) => {
      const activityToSend = {
        id: activity.id,
        calories: activity.calories,
        description: activity.description
      };
      body += `updateActivityCaloriesAndDescription${index}: updateActivityCaloriesAndDescription(
        ${GraphqlParser.parseJSONGraphQL(activityToSend)}
        ) {
              id
              errors {
              field
              message
          }
      }\n`;
    });

    return gql`mutation { ${body} }`;
  }
}
