<div class="modal-content">
  <div class="modal-header">
    EXTRAÇÃO DE RELATÓRIOS
    <img src="./assets/icon_custom/18-p-fechar-b.svg" class="close-btn" (click)="closeModal()">
  </div>
  <div class="modal-body" [formGroup]="form">
    <div class="main-container">
      <div class="select-gym mb-10">
        <div class="H5-LatoBold-C6 mb-10">UNIDADE (OBRIGATÓRIO)</div>
        <ng-select
          class="B1-LatoRegular-C7 w-100"
          [isOpen]="false"
          [clearable]="false"
          [searchable]="false"
          formControlName="gym"
          [placeholder]="'Selecione uma unidade'"
          (click)="gymUnits?.length > 1 ? selectGym() : null"
          ></ng-select>
        <app-error-message class="error" [control]="form.controls.gym"></app-error-message>
      </div>
      <div fxLayout="row">
        <div class="w-100">
          <div fxLayout="row" fxLayoutAlign="space-between" class="mb-10">
            <div class="H5-LatoBold-C6">DATA INICIAL (OBRIGATÓRIO)</div>
          </div>
      
            <div fxLayout="row" class="form-group mb-0" id="group-initial-date" (click)="picker.open()">
              <input matInput [matDatepicker]="picker" id="initial-date" name="initial-date" class="input-text date-input"
              formControlName="initialDate" [max]="yesterday" readonly>
              <mat-datepicker-toggle [class.date-picker-input-error]="form.controls.initialDate.invalid" matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker ></mat-datepicker>
            </div>
            <app-error-message class="error" [control]="form.controls.initialDate"></app-error-message>
        </div>
  
        <div class="ml-24 w-100">
          <div fxLayout="row" fxLayoutAlign="space-between" class="mb-10">
            <div class="H5-LatoBold-C6">DATA FINAL (OBRIGATÓRIO)</div>
          </div>
      
            <div fxLayout="row" class="form-group mb-0" id="group-final-date" (click)="picker2.open()">
              <input matInput [matDatepicker]="picker2"  [disabled]="!form.controls.initialDate.value" id="final-date" name="final-date"
              class="input-text date-input" formControlName="finalDate" [min]="form.controls.initialDate.value" [max]="yesterday"  readonly>
              <mat-datepicker-toggle [class.date-picker-input-error]="form.controls.finalDate.invalid" matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2 ></mat-datepicker>
            </div>
            <app-error-message class="error" [control]="form.controls.finalDate"></app-error-message>
        </div>
      </div>
      
    </div>
    <div fxLayout="row" fxLayoutAlign="center" class="mt-18 mobile-mb-20">
      <button class="bo-btn bo-tertiary-btn mw-250" (click)="generateReport()">EXTRAIR RELATÓRIO</button></div>
    </div>
</div>