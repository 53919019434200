import gql from 'graphql-tag';

export class GraphQLParam {
    id?: number;
    method?: string;
    object?: string;
}

export class GraphqlParser {

    public static parseToCreateMutation(objectList: any[], params: GraphQLParam): any {
        let body = '';
        let index = 0;

        objectList.forEach(object => {
          index++;

          body += `${params.method + index}: ${params.method}(${params.object}: {
            ${this.parseJSONGraphQL(object, true)}
            }) {
                id
                errors {
                    field
                    message
                }
            }\n`;
        });

        return gql`mutation { ${body} }`;
    }

    public static parseToUpdateMutation(objectList: any[], params: GraphQLParam): any {
        let body = '';
        let index = 0;

        objectList.forEach(object => {
            index++;
            if (params.object) {
              body += `${params.method + index}: ${params.method}(id: ${object.id}, ${
                params.object
              }: {
                  ${this.parseJSONGraphQL(object, true)}
                  }) {
                      id
                      errors {
                      field
                      message
                  }
              }\n`;
            } else {
              body += `${params.method + index}: ${params.method}(id: ${object.id}, ${this.parseJSONGraphQL(object, true)}) {
                      id
                      errors {
                      field
                      message
                  }
              }\n`;
            }
        });

        return gql`mutation { ${body} }`;
    }

    public static parseToDeleteMutation(objectList: any[], params: GraphQLParam): any {
        let body = '';
        let index = 0;

        objectList.forEach(object => {
            index++;
            body += `${params.method + index}: ${params.method}(id: ${object.id}) {
                success
                errors {
                    field
                    message
                }
            }\n`;
        });

        return gql`mutation { ${body} }`;
    }

    public static parseJSONGraphQL(object: any, deleteId: boolean = false) {
        if (deleteId) {
            delete object.id;
        }

        return Object.entries(object).map(([key, value]) => {

            if (typeof value === 'string') {
                return `${key}: ${JSON.stringify(value)}\n`;
            } else if (value instanceof Object) {
                return `${key}: { ${this.parseJSONGraphQL(value)} }\n`;
            } else {
                return `${key}: ${value}\n`;
            }
        });
    }
}
