<div [formGroup]="teacherForm">
  <div fxLayout="column" fxLayout.gt-md="row">
    <div ngClass.lt-lg="mb-16" class="justify-between" fxLayout="column" fxFlex="60">
      <div class="H5-LatoBold-C6 mb-10">PROFESSOR</div>
      <div class="form-group">
        <input name="banner-title" type="text" class="input-text fill-available-width" formControlName="name">
      </div>
      <app-error-message class="error" [control]="teacherForm.controls.name"></app-error-message>
    </div>
    <div ngClass.gt-md="ml-16" ngClass.lt-lg="mb-16" class="justify-between" fxLayout="column" fxFlex="40">
      <div class="H5-LatoBold-C6 mb-10">NOME REDUZIDO</div>
      <div class="form-group">
        <input name="banner-title" type="text" class="input-text fill-available-width" formControlName="reducedName">
      </div>
      <app-error-message class="error" [control]="teacherForm.controls.reducedName"></app-error-message>
    </div>
  </div>
  <div class="H5-LatoBold-C6 mb-10">
    ATIVIDADES
  </div>

  <tag-input #tagInputTeacherActivities formControlName="activities"  theme="tag-theme"
    class="teacher-activities"
    [placeholder]="isEdit ? 'Clique para alterar' : ''"
    [class.invalid]="isInvalidTagInput" 
    [secondaryPlaceholder]="isEdit ? 'Clique para selecionar' : ''" 
    [displayBy]="'name'"
    (onRemove)="updateInvalidTagInput()"
    (click)="addActivity()"
    [disable]="!isEdit" 
    [ngClass]="{'editing': isEdit}"
    ></tag-input>
  <app-error-message class="error" [control]="teacherForm.controls.activities"></app-error-message>

  <div class="H5-LatoBold-C6 mb-10">UNIDADES DE ATUAÇÃO</div>
    <textarea id="banner-text" name="banner-text" type="text" class="text-area input-text" disabled formControlName="gymUnities">
  </textarea>

  <div class="text-right" *ngIf="isEdit">
    <button (click)="save()" class="bo-btn bo-primary-btn fit-content-width mt-24 px-35 H5-LatoBold-B">
      SALVAR
    </button>
  </div>
</div>