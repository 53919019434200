<app-sided-menu-generic-modal [modalTitle]="modalTitle">
    <div aside class="aside-item H4-LatoRegular-B" (click)="selectItem(item.id)" *ngFor="let item of items"
    [ngClass]="{'selected-state': selectedItemId === item.id}">
    {{ item.name }}
  </div>

  <div header class="search-box-with-btn" fxHide.lt-sm>
    <input class="search-input" type="text" [(ngModel)]="searchText">
    <img class="search-icon" src="./assets/images/24-p-lupa-c-7.svg">
  </div>

  <div header class="select-state-box" fxHide.gt-xs>
    <ng-select [items]="items" [searchable]="false" [clearable]="false" [selectOnTab]="true" bindLabel="name"
      bindValue="id" placeholder="Selecione um estado" (change)="selectItem($event.id)" class="w-100"
      [(ngModel)]="selectedItemId">
    </ng-select>
  </div>

  <div main class="gyms-list">
    <div (click)="onCheckChangeHandler(subItem)" fxLayout="row" class="gym-name"
      *ngFor="let subItem of subItems | filter : searchText : 'name'">
      <div fxLayout="row" fxLayoutAlign="start center">
        <app-checkbox class="check" [check]="getItem(subItem)"></app-checkbox>
      </div>
      <div class="H4-LatoRegular-C9 w-100 ml-18">
        {{ subItem.name }}
      </div>
    </div>
  </div>
  <div footer fxLayout="row" fxLayoutAlign="{{ messageSelectAll ? 'start' : 'end' }} center" fxFlex="grow">
    <app-checkbox *ngIf="messageSelectAll" [ngClass]="{'font-16': messageSelectAll.length > 30}" class="w-100" ngClass.lt-sm="mb-16"
      [(check)]="checkAllSelected" (checkChange)="selectAllToggler($event)" [message]="messageSelectAll">
    </app-checkbox>
    <div>
      <button fxFlex="noshrink" class="bo-btn bo-tertiary-btn fit-content-width m-12 ml-auto px-20 H5-LatoBold-B"
      (click)="confirmModal()">
      APLICAR SELEÇÃO
    </button>
  </div>
  </div>
</app-sided-menu-generic-modal>