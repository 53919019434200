import gql from 'graphql-tag';

export interface Banner {
  title: string;
  subtitle: string;
  image: string;
  buttonText: string;
  linkUrl: string;
  legalText: string;
  displayOrder?: number;
  initialDate: Date;
  finalDate: Date;

  isSelected?: boolean;
  __typename?: string;
}


export class BannerGraphql {


  public static readonly mutationCreateOrUpdatePortalHomeBannerHeaders  = gql`
    mutation createOrUpdatePortalHomeBannerHeaders($portalHomeBannerHeaders: [PortalHomeBannerHeaderUpdate!]) {
      createOrUpdatePortalHomeBannerHeaders(portalHomeBannerHeaders: $portalHomeBannerHeaders) {
        title
        subtitle
        image
        buttonText
        linkUrl
        legalText
        displayOrder
        initialDate
        finalDate
        errors {
          field
          message
        }
      }
    }
  `;

  public static readonly queryListPortalHomeBannerHeaders = gql`
        query {
          listAllPortalHomeBannerHeaders {
            title
            subtitle
            image
            buttonText
            linkUrl
            legalText
            displayOrder
            initialDate
            finalDate
          }
        }
    `;
}

