import gql from 'graphql-tag';

export interface OnlineClassSchedule {
  id: string;
  appointmentDate: string;
  formattedInitialDate: string;
  formattedFinalDate: string;
  appointmentCanceledDate: string;
  liveClassUrl: string;
  clientRegistration: number;
  teacherAvailableHour: TeacherAvailableHour;
  clientName?: string;
  classBreak?: string;
  presence: boolean;
}

export enum PhoneType {
  HOME = 1,
  WORK,
  CELL
}

export interface ClientContactData {
  name: string;
  listCustomerPhones: ClientPhoneContact[];
  listCustomerEmergencialPhones: ClientEmergencyContact[];
}

export interface ClientPhone {
  ddi: string;
  ddd: string;
  phoneNumber: string;
  frontendFormattedNumber: string;
}

export interface ClientPhoneContact extends ClientPhone {
  phoneType: PhoneType;
}

export interface ClientEmergencyContact extends ClientPhoneContact {
  contactName: string;
  kinshipTypeName: string;
  mainPhone: number;
}

export interface TeacherAvailableHour {
  availableHour: AvailableHour;
}

export interface AvailableHour {
  initialHour: number;
  finalHour: number;
  initialMinute: number;
  finalMinute: number;
  formattedInitialTime: string;
  formattedFinalTime: string;
}

export interface AppointmentTeacher {
  id: string;
  name: string;
}
export interface FilterDate {
  label: string;
  value: string;
}

export class AppointmentLiveClassGraphql {

  public static readonly queryListAllAppointmentLiveClassByTeacher = gql`
    query($teacherId: String!) {
      listAllAppointmentLiveClassByTeacher(teacherId: $teacherId) {
        id
        clientName
        appointmentDate
        formattedInitialDate
        formattedFinalDate
        appointmentCanceledDate
        liveClassUrl
        clientRegistration
        presence
        teacherAvailableHour {
          availableHour {
            initialHour
            finalHour
            initialMinute
            finalMinute
            formattedInitialTime
            formattedFinalTime
          }
        }
      }
    }
  `;

  public static readonly queryListCustomerPhones = gql`
    query($registration: BigInteger!) {
      listCustomerPhones(registration: $registration) {
        id
        ddi
        ddd
        phoneNumber
        phoneType
      }
      listCustomerEmergencialPhones(registration: $registration) {
        id
        ddi
        ddd
        phoneNumber
        kinshipTypeName
        contactName
        mainPhone
      }
    }
  `;

  public static readonly mutationUpdatePresence = gql`
    mutation ($id: Long!, $registration: Long!, $status: Boolean!) {
      updateAppointmentLiveClassPresence(id: $id, registration: $registration, status: $status) {
        presence
        errors {
          field
          message
        }
      }
    }
  `;

}
