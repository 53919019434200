import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { GraphqlParser } from '@utils/graphql-parser';
import {
  InfoServiceGraphql,
  InfoServiceIcon,
  InfoService,
  InfoServiceUpdate,
  ResponseError,
} from '@models';
import { JSONUtil } from '@utils/json-util';
import { map, defaultIfEmpty } from 'rxjs/operators';

@Injectable()
export class InfoServiceService {
  constructor(private readonly apollo: Apollo) {}

  public listInfoServices(): Observable<any> {
    return this.apollo
      .query({
        query: InfoServiceGraphql.queryListInfoServices,
      })
      .pipe(map(JSONUtil.turnApolloMutable('listServices')));
  }

  save(infoServices: InfoService[]): Observable<any> {
    if (!infoServices.length) {
      return of().pipe(defaultIfEmpty('Observable.of() Empty!'));
    }

    const infoServiceUpdate = this.formatToInfoServiceUpdate(infoServices);

    return this.apollo
      .mutate<InfoService>({
        mutation: GraphqlParser.parseToUpdateMutation(infoServiceUpdate, {
          method: 'updateServiceIconAndDescription',
          object: null,
        }),
      })
      .pipe(map(JSONUtil.turnApolloMutable<ResponseError[]>()));
  }

  private formatToInfoServiceUpdate(infoServices: InfoService[]): InfoServiceUpdate[] {
    const infoServiceUpdate = infoServices.map(service => {
      const splittedIcon = service.icon.split('/');
      service.icon = `/${splittedIcon[splittedIcon.length - 2]}/${
        splittedIcon[splittedIcon.length - 1]
      }`;
      return {
        id: service.id,
        icon: service.icon,
        description: service.description,
      };
    });

    return infoServiceUpdate;
  }

  public getInfoServiceIcons(): Observable<InfoServiceIcon[]> {
    return this.apollo
      .query({
        query: InfoServiceGraphql.queryListInfoServiceIcons,
      })
      .pipe(map(JSONUtil.turnApolloMutable('listServiceIcons')));
  }
}
