/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./view-data-list-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./view-data-list-modal.component";
import * as i4 from "@angular/material/dialog";
var styles_ViewDataListModalComponent = [i0.styles];
var RenderType_ViewDataListModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewDataListModalComponent, data: {} });
export { RenderType_ViewDataListModalComponent as RenderType_ViewDataListModalComponent };
function View_ViewDataListModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["- ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_ViewDataListModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", "."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.emptyMessage; _ck(_v, 1, 0, currVal_0); }); }
export function View_ViewDataListModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "close-btn"], ["src", "./assets/icon_custom/18-p-fechar-b.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "ul", [["class", "p-24 H4-LatoRegular-C7"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewDataListModalComponent_1)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewDataListModalComponent_2)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.data.list; _ck(_v, 7, 0, currVal_1); var currVal_2 = (!_co.data.list || !_co.data.list.length); _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.header; _ck(_v, 2, 0, currVal_0); }); }
export function View_ViewDataListModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-view-data-list-modal", [], null, null, null, View_ViewDataListModalComponent_0, RenderType_ViewDataListModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.ViewDataListModalComponent, [i4.MatDialogRef, i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewDataListModalComponentNgFactory = i1.ɵccf("app-view-data-list-modal", i3.ViewDataListModalComponent, View_ViewDataListModalComponent_Host_0, {}, {}, []);
export { ViewDataListModalComponentNgFactory as ViewDataListModalComponentNgFactory };
