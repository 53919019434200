import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthenticationService } from '@services';

@Injectable()
export class LoginGuard implements CanActivate {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
    ) { }

    canActivate() {
        if (this.authenticationService.isLoggedIn()) {
            this.router.navigate(['/']);
            return false;
        }
        return true;
    }
}
