import * as tslib_1 from "tslib";
import gql from 'graphql-tag';
var PageHeaderGraphql = /** @class */ (function () {
    function PageHeaderGraphql() {
    }
    PageHeaderGraphql.queryGetPortalPageHeaderByTag = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n        query($tag: String!) {\n            getPortalPageHeaderByTag(tag: $tag) {\n            id\n            tag\n            title\n            image\n            }\n        }\n    "], ["\n        query($tag: String!) {\n            getPortalPageHeaderByTag(tag: $tag) {\n            id\n            tag\n            title\n            image\n            }\n        }\n    "])));
    PageHeaderGraphql.mutationUpdatePortalPageHeaderImage = gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n        mutation($tag: String!, $image: String!) {\n            updatePortalPageHeaderImage(tag: $tag, image: $image) {\n                id\n                tag\n                title\n                image\n                errors {\n                    field\n                    message\n                }\n            }\n        }\n    "], ["\n        mutation($tag: String!, $image: String!) {\n            updatePortalPageHeaderImage(tag: $tag, image: $image) {\n                id\n                tag\n                title\n                image\n                errors {\n                    field\n                    message\n                }\n            }\n        }\n    "])));
    return PageHeaderGraphql;
}());
export { PageHeaderGraphql };
var templateObject_1, templateObject_2;
