import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AppConstants } from '@utils/app-constants';
import { portalBack, PortalEndpoints, SurEndpoints } from '@utils/app-endpoints';

import { EndpointsService } from './endpoints.service';
import { StorageService } from './storage.service';
import { Login } from '../models';

@Injectable()
export class AuthenticationService {

  constructor(
    private http: HttpClient,
    private endpointsService: EndpointsService,
    private storageService: StorageService,
    private router: Router,
  ) { }

  public authenticate(login: Login): Observable<any> {
    return this.http.post(this.endpointsService.endpoints(SurEndpoints.Login), login);
  }

  public doLogin(userData: any) {
    this.storageService.set(AppConstants.STOR_LOGIN_DATA, userData);
    this.storageService.set(AppConstants.STOR_USER_TOKEN, userData.token);
  }

  public isLoggedIn() {
    return Boolean(this.storageService.get(AppConstants.STOR_LOGIN_DATA));
  }

  public getUserToken() {
    return this.storageService.get(AppConstants.STOR_USER_TOKEN);
  }

  public getAppUserToken(): string {
    return this.storageService.get(AppConstants.STOR_APP_USER_TOKEN);
  }

  public getAppToken(): Observable<string> {
    const token = this.storageService.get(AppConstants.STOR_SUR_TOKEN);

    if (token) {
      return of(token);
    }

    return this.http.get<any>(portalBack(PortalEndpoints.AppToken)).pipe(
      map(json => json.jwt),
      tap(tokenResult => {
        this.storageService.set(AppConstants.STOR_SUR_TOKEN, tokenResult as string);
      })
    );
  }

  public clearAppToken() {
    this.storageService.clearItem(AppConstants.STOR_LOGIN_DATA);
    this.storageService.clearItem(AppConstants.STOR_USER_TOKEN);
    this.storageService.clearItem(AppConstants.STOR_SUR_TOKEN);
  }

  public doLogout() {
    this.clearAppToken();
    this.router.navigate(['/login']);
  }

  // Schedule Authentication methods - agendamento para treinar na academia

  public clearSchedulesData() {
    this.storageService.clearItem(AppConstants.STOR_PORTAL_TOKEN_SCHEDULE);
    this.storageService.clearItem(AppConstants.STOR_RECEP_GYMS);
  }

  public doLogoutSchedule() {
    this.clearSchedulesData();
    this.router.navigate(['/agendamentos/login']);
  }

  public doScheduleLogin(userData: any) {
    this.storageService.set(AppConstants.STOR_RECEP_GYMS, userData);
  }

  public isLoggedInSchedule() {
    return Boolean(this.storageService.get(AppConstants.STOR_RECEP_GYMS));
  }

  // AppointmentLiveClass Authentication methods - agendamento de aulas onlines

  public clearAppointmentLiveClassData() {
    this.storageService.clearItem(AppConstants.STOR_PORTAL_TOKEN_APPOINTMENT_LIVE_CLASS);
    this.storageService.clearItem(AppConstants.STOR_ONLINE_TEACHER);
  }

  public doLogoutAppointmentLiveClass() {
    this.clearAppointmentLiveClassData();
    this.router.navigate(['/aulas-online/login']);
  }

  public doAppointmentLiveClassLogin(userData: any) {
    this.storageService.set(AppConstants.STOR_ONLINE_TEACHER, userData);
  }

  public isLoggedInAppoitmentLiveClass() {
    return Boolean(this.storageService.get(AppConstants.STOR_ONLINE_TEACHER));
  }
}
