import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-select-icon-modal',
  templateUrl: './select-icon-modal.component.html',
  styleUrls: ['./select-icon-modal.component.scss'],
})
export class SelectIconModalComponent<T> implements OnInit {
  public title: string;
  public icons: T[];
  public selectedIcon: string;

  constructor(
    public dialogRef: MatDialogRef<SelectIconModalComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.title = this.data.title;
    this.icons = this.data.icons;
    this.selectedIcon = this.data.selectedIcon;
  }

  closeModal(icon?: T): void {
    this.dialogRef.close(icon || false);
  }
}
