import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Convenience } from '@models';

@Component({
  selector: 'app-convenience-edit-modal',
  templateUrl: './convenience-edit-modal.component.html',
  styleUrls: ['./convenience-edit-modal.component.scss']
})
export class ConvenienceEditModalComponent {

  constructor(
    public dialogRef: MatDialogRef<ConvenienceEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  public closeModal(): void {
    this.dialogRef.close(false);
  }

  public editConvenience(convenience: Convenience) {
    this.dialogRef.close(convenience);
  }
}
