import { MatDialog } from '@angular/material';
import { DirtyCheckService } from '@services/dirty-check.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../services/dirty-check.service";
var DirtyCheckGuard = /** @class */ (function () {
    function DirtyCheckGuard(dialog, dirtyCheckService) {
        this.dialog = dialog;
        this.dirtyCheckService = dirtyCheckService;
    }
    DirtyCheckGuard.prototype.canDeactivate = function () {
        return this.dirtyCheckService.confirmDialog();
    };
    DirtyCheckGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DirtyCheckGuard_Factory() { return new DirtyCheckGuard(i0.ɵɵinject(i1.MatDialog), i0.ɵɵinject(i2.DirtyCheckService)); }, token: DirtyCheckGuard, providedIn: "root" });
    return DirtyCheckGuard;
}());
export { DirtyCheckGuard };
