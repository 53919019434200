<div class="modal-content">
  <div class="modal-header">
    PREVIEW
    <img src="./assets/icon_custom/18-p-fechar-b.svg" class="close-btn" (click)="closeModal()">
  </div>
  <div class="modal-body">
    <div class="main-container">
      <img [ngStyle]="{ 'background-image': 'url(' + banner.image + ')' }"
        src="assets/images/bt/app-banner-mask.svg" class="w-100 h-100">
    </div>
  </div>
</div>
