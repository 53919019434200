import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

enum stackActions {
  push = 0,
  pop = 1
}
@Injectable({
  providedIn: 'root'
})
export class ScrollService {


  private stack: boolean[] = [];

  private needScroll = new Subject<void>();
  needScroll$: Observable<void> = this.needScroll.asObservable();

  private sticky = new BehaviorSubject<boolean>(false);
  sticky$: Observable<boolean> = this.sticky.asObservable();

  private hostListenerSticker = false;

  constructor() { }

  scroll() {
    this.needScroll.next();
  }

  setSticky(value: boolean) {
    if (this.stack.length === 0 || this.stack[0] === value) {
      this.stack.push(value);
      this.sticky.next(value);
    } else {
      this.stack.pop();
    }

    if (this.stack.length === 0 ) {
      this.sticky.next(value);
    }
  }

  setHostListenerSticker(value: boolean) {
    this.hostListenerSticker = value;
  }

  getHostListenerSticker() {
    return this.hostListenerSticker;
  }

  getStackLength() {
    return this.stack.length;
  }

}
