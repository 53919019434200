<div class="modal-content">
  <div class="modal-header">
    {{ isEdit ? 'EDITAR' : 'VISUALIZAR' }} INFORMAÇÕES DO PROFESSOR
    <img src="./assets/icon_custom/18-p-fechar-b.svg" class="close-btn" (click)="closeModal()">
  </div>
  <div class="modal-body">
    <div class="main-container">

      <app-teachers-edit-form [teacherToEdit]="teacherToEdit" [allActiveActivities]="allActiveActivities"
        [allActivityGroups]="allActivityGroups" [isEdit]="isEdit" (teacherEmitter)="editTeacher($event)">
      </app-teachers-edit-form>
    </div>
  </div>
</div>