import { Component, OnInit, Input, EventEmitter, Output, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

import { Informative, InformativeStatus } from '@models/informative';

import { StringUtil } from '@utils/string.util';
import { Masks } from '@utils/masks';
import { Observable, merge, Subscription } from 'rxjs';
import { GymService } from '@services/gym.service';
import { GymUnit, AlertMessage, MessageMap, PhotoUpdate } from '@models';
import { ChooseGymsModalComponent } from '@sharedcomponents/sided-menu-generic-modal/choose-gyms-modal/choose-gyms-modal.component';
import { MatDialog } from '@angular/material';
import { ValidateHour } from '@validators/hour.validator';
import { FormUtil } from '@utils/form-util';
import { AlertMessageService } from '@services/alert-message.service';
import { TeacherService } from '@services/teacher.service';
import { PlaceService } from '@services/place.service';
import { SelectGymModalComponent } from '@sharedcomponents';
import { Place } from '@models/place';
import { ActivatedRoute } from '@angular/router';
import { InformativeUtil } from '@utils/informative-util';
import { Teacher } from '@models/teacher';
import { TagInputComponent } from 'ngx-chips';
import { throttleTime } from 'rxjs/operators';
import { ScrollService } from '@services/scroll.service';
import { DialogService } from '@services';

@Component({
  selector: 'app-informatives-form',
  templateUrl: './informatives-form.component.html',
  styleUrls: ['./informatives-form.component.scss']
})
export class InformativesFormComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() informativeToEdit: Informative;
  @Input() isToViewOnly: boolean;
  @Input() gymUnitContext = false;

  @Output() informativeEmitter: EventEmitter<Informative> = new EventEmitter();

  @ViewChild('tagInputGymUnits', {static: false}) tagInputGymUnits: TagInputComponent;

  informativeForm: FormGroup;

  tagInputEmptyMessage = 'Clique para selecionar';
  tagInputNotEmptyMessage = 'Clique para alterar';

  gymUnities: GymUnit[];
  teachersName: Teacher[] = [];
  places: Place[] = [];
  filteredRooms: Observable<string[]>;

  readonly hourMask = { mask: Masks.Mask.hour };
  InformativeStatus = InformativeStatus;

  file: PhotoUpdate;

  private subscriptionObs: Subscription;

  private compareHours =
    (target: AbstractControl, comparator: (from: AbstractControl, to: AbstractControl) => boolean): ValidatorFn => {
      return (control: AbstractControl): ValidationErrors | null => {
        if (control.value && control.value.length > 4
          && target.value && target.value.length > 4
          && comparator(control, target)) {
          return {
            validIntervalHour: true,
          };
        }
        return null;
      };
    }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly gymService: GymService,
    private readonly teacherService: TeacherService,
    private readonly placeService: PlaceService,
    private readonly dialog: MatDialog,
    private readonly alertMessageService: AlertMessageService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly scrollService: ScrollService,
    private readonly dialogService: DialogService
  ) { }

  ngOnInit() {
    this.gymService.getGymNamesWithState().subscribe(
      res => this.gymUnities = res
    );

    this.informativeForm = this.formBuilder.group({
      id: null,
      title: new FormControl({ value: null, disabled: this.isToViewOnly },
        [Validators.required, Validators.maxLength(255)]),
      description: new FormControl({ value: null, disabled: this.isToViewOnly }, Validators.required),
      imageUrl: new FormControl({ value: null, disabled: this.isToViewOnly }),
      initialPublishingDate: new FormControl({ value: null, disabled: this.isToViewOnly }, Validators.required),
      finalPublishingDate: new FormControl({ value: null, disabled: this.isToViewOnly }, Validators.required),
      status: null,
      isEvent: new FormControl({ value: false, disabled: this.isToViewOnly }, Validators.required),
      gymUnities: new FormControl({ value: null, disabled: this.isToViewOnly }, Validators.required),
      place: new FormControl({ value: null, disabled: this.isToViewOnly || !this.informativeToEdit }),
      otherPlace: new FormControl({ value: null, disabled: this.isToViewOnly }),
      teacherName: new FormControl({ value: null, disabled: this.isToViewOnly }),
      eventDate: new FormControl({ value: null, disabled: this.isToViewOnly }),
      initialTimeHour: new FormControl({ value: null, disabled: this.isToViewOnly }),
      finalTimeHour: new FormControl({ value: null, disabled: this.isToViewOnly }),
    });

    const titleField = this.informativeForm.get('title');
    titleField.valueChanges.subscribe(value => {
      if (value && titleField.untouched) {
        titleField.markAsTouched();
      }
    });
    const otherPlaceField = this.informativeForm.get('otherPlace');
    otherPlaceField.valueChanges.subscribe(value => {
      if (value && otherPlaceField.untouched) {
        otherPlaceField.markAsTouched();
      }
    });

    if (this.informativeToEdit) {
      this.informativeToEdit.gymUnities.sort((a, b) => StringUtil.strcmp(a.name, b.name));
      this.informativeForm.patchValue(this.informativeToEdit);

      if (this.informativeToEdit.teacherName) {
        this.informativeForm.get('teacherName').setValue(this.informativeToEdit.teacherName.split(';'));
      }

      if (!this.informativeToEdit.place) {
        this.informativeForm.get('place').setValue({ id: 0, name: 'Outro (preencher)' });
      }

      if (this.informativeToEdit.isEvent) {
        [ { control: this.informativeForm.get('place'), validators: [Validators.required] },
          { control: this.informativeForm.get('teacherName'), validators: [Validators.required] },
          { control: this.informativeForm.get('eventDate'), validators: [Validators.required] },
          { control: this.informativeForm.get('initialTimeHour'), validators: [Validators.required,
            this.compareHours(this.informativeForm.get('finalTimeHour'),
              (control: AbstractControl, target: AbstractControl) => {
                return (control.value || 1) >= (target.value || 0);
              }
            )]
          },
          { control: this.informativeForm.get('finalTimeHour'), validators: [Validators.required,
            this.compareHours(this.informativeForm.get('initialTimeHour'),
              (control: AbstractControl, target: AbstractControl) => {
                return (control.value || 0) <= (target.value || 1);
              }
            )]
          },
        ].forEach(field => {
          field.control.setValidators(field.validators);
        });

        if (otherPlaceField.value) {
          otherPlaceField.setValidators([Validators.required, Validators.maxLength(100)]);
        }

        this.informativeForm.get('finalPublishingDate').clearValidators();
        this.informativeForm.get('finalPublishingDate').updateValueAndValidity({
          onlySelf: true,
          emitEvent: true,
        });

        this.getTeachers();
        this.getPlaces();
      }
    }

    this.setupControls();

    if (this.gymUnitContext) {
      this.activatedRoute.data.subscribe(datas => {
        if (datas.gymUnit) {
          this.informativeForm.get('gymUnities').setValue([datas.gymUnit]);
        }
      });
    }

    if (!this.file) {
      this.file = new PhotoUpdate(0);
      this.file.url = (this.informativeToEdit && this.informativeToEdit.imageUrl)
        ? this.informativeToEdit.imageUrl
        : '/assets/images/imagem-empty-1080x1080.svg';
    }
  }

  ngOnDestroy() {
    this.subscriptionObs.unsubscribe();
  }

  ngAfterViewInit() {
    this.tagInputGymUnits.inputForm.input.nativeElement.disabled = true;
  }

  private setupControls() {
    this.informativeForm.get('initialPublishingDate').valueChanges.subscribe(value => {
      const eventDate = this.informativeForm.get('eventDate');
      if (!!eventDate.value && value > eventDate.value) {
        eventDate.setValue(value);
      }
    });

    this.informativeForm.get('gymUnities').valueChanges.subscribe(value => {
      const fields = [
        this.informativeForm.get('teacherName'),
        this.informativeForm.get('place'),
      ];

      fields.forEach((field: AbstractControl) => {
        field.reset();
      });

      if (value && value.length) {
        fields.forEach((field: AbstractControl) => field.enable({ emitEvent: false }));

        if (!!this.informativeForm.get('isEvent').value || this.gymUnitContext) {
          this.getTeachers();
          this.getPlaces();
        }
      } else {
        fields.forEach((field: AbstractControl) => {
          field.disable();
        });
      }
    });

    const setValidators = (field: AbstractControl, validators: ValidatorFn[]) => {
      field.setValidators(validators);
      field.markAsDirty();
      field.updateValueAndValidity({
        onlySelf: true,
        emitEvent: true,
      });
    };
    const removeValidators = (field: AbstractControl) => {
      field.setValidators(null);
      field.reset();
      field.updateValueAndValidity({
        onlySelf: true,
        emitEvent: true,
      });
    };
    this.informativeForm.get('isEvent').valueChanges.subscribe(isEvent => {
      const fields = [
        { control: this.informativeForm.get('place'),
          validators: [Validators.required] },
        { control: this.informativeForm.get('teacherName'),
          validators: [Validators.required] },
        { control: this.informativeForm.get('eventDate'),
          validators: [Validators.required] },
        { control: this.informativeForm.get('initialTimeHour'),
          validators: [Validators.required, ValidateHour(),
            this.compareHours(this.informativeForm.get('finalTimeHour'),
              (control: AbstractControl, target: AbstractControl) => {
                return (control.value || 1) >= (target.value || 0);
              }
            )]
        },
        { control: this.informativeForm.get('finalTimeHour'),
          validators: [Validators.required, ValidateHour(),
            this.compareHours(this.informativeForm.get('initialTimeHour'),
              (control: AbstractControl, target: AbstractControl) => {
                return (control.value || 0) <= (target.value || 1);
              }
            )]
        },
      ];
      const finalPublishingDateField = this.informativeForm.get('finalPublishingDate');
      if (!!isEvent) {
        if (!this.gymUnitContext) {
          this.informativeForm.get('gymUnities').reset();
        }
        fields.forEach(field => setValidators(field.control, field.validators));
        finalPublishingDateField.clearValidators();
        finalPublishingDateField.updateValueAndValidity({
          onlySelf: true,
          emitEvent: true,
        });
        finalPublishingDateField.reset();
      } else {
        fields.forEach(field => removeValidators(field.control));
        finalPublishingDateField.setValidators(Validators.required);
        finalPublishingDateField.updateValueAndValidity({
          onlySelf: true,
          emitEvent: true,
        });
      }
    });

    this.informativeForm.get('place').valueChanges.subscribe(place => {
      const otherPlaceField = this.informativeForm.get('otherPlace');
      if (!!place && place.id === 0) {
        setValidators(otherPlaceField, [Validators.required, Validators.maxLength(100)]);
      } else {
        removeValidators(otherPlaceField);
      }
    });

    this.informativeForm.get('eventDate').valueChanges.subscribe(date => {
      if (date) {
        this.informativeForm.get('finalPublishingDate').setValue(date);
      }
    });

    const initialTimeHourField = this.informativeForm.get('initialTimeHour');
    const finalTimeHourField = this.informativeForm.get('finalTimeHour');
    this.subscriptionObs = merge(
      initialTimeHourField.valueChanges,
      finalTimeHourField.valueChanges,
    ).pipe(
      throttleTime(0)
    ).subscribe(() => {
      initialTimeHourField.updateValueAndValidity({
        onlySelf: true,
        emitEvent: true,
      });
      finalTimeHourField.updateValueAndValidity({
        onlySelf: true,
        emitEvent: true,
      });
    });
  }

  public getFileUrl(url: string) {
    const control = this.informativeForm.get('imageUrl');
    if (url) {
      control.setValue(url);
      this.file.url = url;
    }
  }

  public gymsSelectionModal() {
    this.dialogService.openDialog(ChooseGymsModalComponent, {
      width: '800px',
      maxHeight: '90vh',
      data: {
        gyms: this.gymUnities,
        selectedGyms: this.informativeForm.get('gymUnities').value,
      },
    });
    this.dialogService.afterClosed().subscribe(gyms => {
      if (gyms) {
        gyms.sort((a, b) => {
          return StringUtil.strcmp(a.name, b.name);
        });
        this.informativeForm.get('gymUnities').setValue(gyms);
        this.informativeForm.get('gymUnities').markAsTouched();
      }
    });
  }

  public gymSelectionModal() {
    this.dialogService.openDialog(SelectGymModalComponent, {
      width: '800px',
      maxHeight: '90vh',
      data: {
        gyms: this.gymUnities,
        selectedGym: this.informativeForm.get('gymUnities').value ?
          this.informativeForm.get('gymUnities').value[0] : null,
      }
    });
    this.dialogService.afterClosed().subscribe(gym => {
      if (gym) {
        this.informativeForm.get('gymUnities').setValue([gym]);
        this.informativeForm.get('gymUnities').markAsTouched();
      }
    });
  }

  public saveDraft() {
    this.setStatus(InformativeStatus.DRAFT);
    this.save();
  }

  private setupInformative(informative) {
    const finalDate = informative.finalPublishingDate;
    finalDate.setSeconds(59);
    finalDate.setMinutes(59);
    finalDate.setHours(23);
    if (!!informative.teacherName) {
      informative.teacherName = informative.teacherName.join(';');
    }
    if (!!informative.place && informative.place.id === 0) {
      informative.place = null;
    }
    if (informative.status !== InformativeStatus.DRAFT) {
      informative.status = InformativeUtil.getStatus(informative);
    }
    if (informative.isEvent) {
      const hour = parseInt(informative.finalTimeHour.substring(0, 2), 10);
      informative.finalPublishingDate.setHours(hour);
      informative.eventDate.setHours(hour);
      const minute = parseInt(informative.finalTimeHour.substring(3), 10);
      informative.finalPublishingDate.setMinutes(minute);
      informative.eventDate.setMinutes(minute);
    }
    return informative;
  }

  public save() {
    FormUtil.trimFieldsForm(this.informativeForm);
    FormUtil.touchFormWihoutPropagation(this.informativeForm);
    if (this.informativeForm.valid) {
      const informative = this.setupInformative(this.informativeForm.value);
      this.informativeEmitter.emit(informative);
      this.informativeForm.reset({
        gymUnities: this.gymUnitContext ? informative.gymUnities : null
      });
      this.informativeForm.get('isEvent').setValue(false);
      this.file = new PhotoUpdate(0);
      this.file.url = '/assets/images/imagem-empty-1080x1080.svg';
    } else {
      this.informativeForm.markAsTouched();
      this.alertMessageService.showToastr(
        AlertMessage.error(MessageMap.CAMPOS_EM_VERMELHO),
      );
    }
  }

  public setStatus(status: InformativeStatus) {
    this.informativeForm.get('status').setValue(status);
  }

  public isStatus(status: InformativeStatus) {
    return this.informativeForm.value.status === status;
  }

  public isEvent(): boolean {
    return !!this.informativeForm.get('isEvent').value;
  }

  public getPlaceHolder(): string {
    if (!!this.informativeForm.get('isEvent').value
      || (this.gymUnitContext && this.informativeForm.get('gymUnities').value.length === 1)) {
      return 'UNIDADE VINCULADA';
    }
    return 'UNIDADES VINCULADAS';
  }

  public getTeachers() {
    const gymUnitID = this.informativeForm.get('gymUnities').value[0].id;
    this.teacherService.getActiveTeachersByGymUnit(gymUnitID).subscribe(teachersName => {
      this.teachersName = teachersName || [];
    });
  }

  public getPlaces() {
    const gymUnitID = this.informativeForm.get('gymUnities').value[0].id;
    this.placeService.getPlacesByGymUnit(gymUnitID).subscribe(places => {
      this.places = places || [];
      this.places.push({ id: 0, name: 'Outro (preencher)'});
    });
  }

}
