import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AppBanner } from '@models/app-banner';
import { AlertMessage, MessageMap, PhotoUpdate } from '@models';
import { FormUtil } from '@utils/form-util';
import { AlertMessageService } from '@services/alert-message.service';
import { AppBannersPreviewModalComponent } from './../app-banners-preview-modal/app-banners-preview-modal.component';
import { Observable, of } from 'rxjs';
import { links } from 'data/app-deep-links';
import { ValidateUrl } from '@validators/url.validator';
import { DialogService } from '@services';

@Component({
  selector: 'app-app-banners-form',
  templateUrl: './app-banners-form.component.html',
  styleUrls: ['./app-banners-form.component.scss']
})
export class AppBannersFormComponent implements OnInit {

  @Input() appBannerToEdit: AppBanner = null;
  @Output() appBannerEmitter: EventEmitter<AppBanner> = new EventEmitter();

  appBannersForm: FormGroup;

  SLUG_APP_BANNER = 'app_banner';

  // datas to mat-progress-spinner
  color = 'primary';
  mode = 'indeterminate';
  value = 50;

  links: string[] = links;
  filteredLinks: Observable<string[]>;

  public file: PhotoUpdate;

  constructor(
    private formBuilder: FormBuilder,
    private alertMessageService: AlertMessageService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.appBannersForm = this.formBuilder.group({
      image: new FormControl(
        this.appBannerToEdit ? this.appBannerToEdit.image : '',
        Validators.required,
      ),
      bannerAction: new FormControl(this.appBannerToEdit ? this.appBannerToEdit.bannerAction : '', [
        Validators.maxLength(255),
        ValidateUrl(true),
      ]),
      displayOrder: new FormControl(this.appBannerToEdit ? this.appBannerToEdit.displayOrder : 0),
      isSelected: false,
    });

    if (!this.file) {
      this.file = new PhotoUpdate(0);
      this.file.url = this.appBannerToEdit ? this.appBannerToEdit.image : 'assets/images/imagem.svg';
    }
  }

  onClickAndInputHandler(event): void {
    FormUtil.checkURL(this.appBannersForm.controls.bannerAction, ['http', 'https', 'ftp', 'bodytech']);
    this.filteredLinks = of(this._filter(event.target.value));
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLocaleLowerCase();
    return this.links.filter(option => option.toLocaleLowerCase().indexOf(filterValue) === 0);
  }

  getFileUrl(url: string) {
    const control = this.appBannersForm.get('image');
    if (url) {
      control.setValue(url);
      this.file.url = url;
    }
  }

  preview() {
    if (this.appBannersForm.controls.image.value) {
      const banner = {
        image: this.appBannersForm.value.image,
        bannerAction: null
      };
      this.dialogService.openDialog(AppBannersPreviewModalComponent, {
        width: '800px',
        maxWidth: window.innerWidth < 600 ? '100vw' : '800px',
        maxHeight: '90vh',
        data: {
          banner
        }
      });
    }
  }

  save() {
    FormUtil.touchForm(this.appBannersForm);
    if (this.appBannersForm.valid) {
      this.trimFields(this.appBannersForm);
      this.appBannerEmitter.emit(this.appBannersForm.value);
      this.appBannersForm.reset();
      this.appBannersForm.get('displayOrder').setValue(0);
      this.appBannersForm.get('isSelected').setValue(false);
      this.file = new PhotoUpdate(0);
      this.file.url = 'assets/images/imagem.svg';
    } else {
      this.appBannersForm.markAsTouched();
      this.alertMessageService.showToastr(AlertMessage.error(MessageMap.CAMPOS_EM_VERMELHO));
    }
  }

  trimFields(form: FormGroup) {
    form.value.bannerAction = form.value.bannerAction ? form.value.bannerAction.trim() : '';
  }

}
