import { Component, Input, OnInit } from '@angular/core';
import { FormControl, NgModel } from '@angular/forms';

import { Observable, Subscription, of, concat } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent implements OnInit {

  @Input() control: FormControl;
  @Input() ngModel: NgModel;

  error: string;

  controlValue$: Observable<any>;
  controlSubscription: Subscription;
  hasSubmitted: boolean;

  config = {
    pattern:           `Formato incorreto`,
    max:               `O valor ultrapassa o máximo permitido`,
    min:               `O valor é menor que o mínimo permitido`,
    maxlength:         `Limite máximo de caracteres atingido`,
    minlength:         `O campo não possui o valor mínimo de caracteres`,
    email:             `E-mail inválido`,
    required:          `Campo obrigatório`,
    cpfNotValid:       `CPF informado inválido`,
    password:          `A senha deve ser entre 6 e 12 caracteres contendo letras e números`,
    validUrl:          `Formato da URL inválido`,
    validHour:         `Hora inválida`,
    validIntervalHour: `Intervalo inválido`,
  };

  @Input() set messages(newConfig: any) {
    Object.assign(this.config, newConfig);
  }


  ngOnInit() {
    if (this.ngModel) {
      const formSubmit$ = this.ngModel.formDirective.ngSubmit.pipe(
        map(() => {
        this.hasSubmitted = true;
        return false;
        }),
      );
      this.controlValue$ = concat(this.control.valueChanges, of(''), formSubmit$);
    } else if (this.control) {
      this.load();
      this.controlValue$ = concat(this.control.valueChanges, of(''));
    }
    this.controlValue$.subscribe(() => this.setVisible());
  }

  private setVisible() {
    if (!this.control) {
      return;
    }
    if (this.control.invalid && (this.control.dirty || this.hasSubmitted)) {
      this.load();
    } else {
      this.error = '';
    }
  }

  load() {
    if (this.control.errors) {
      this.error = Object.keys(this.control.errors)
        .filter(keyError => !!this.config[keyError])
        .map(keyError => this.config[keyError])[0];
    }
  }
}
