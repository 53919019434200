import { SocialMedia } from './social-media';
import { GymUnit } from './gym-unit';
import gql from 'graphql-tag';

export interface GymUnitSocialMedia {
    url?: string;
    display?: string;

    socialMedia?: SocialMedia;
    gymUnit?: GymUnit;
}

export interface GymUnitSocialMediaUpdate {
    url: string;
    display: string;
    displayOrder: number;
    socialMediaId: number;
}

export class GymUnitSocialMediaGraphql {

    public static readonly queryListGymUnitSocialMediasByGymUnit = gql`
        query($gymUnitId: Long!) {
            listGymUnitSocialMediasByGymUnit(id: $gymUnitId) {
                url
                display
                socialMedia {
                    id
                    name
                }
                gymUnit {
                    id
                }
            }
        }
    `;

    public static readonly mutationAssociateGymUnitSocialMediasToGymUnit = gql`
        mutation AssociateGymUnitSocialMediasToGymUnit($gymUnitId: Long!, $gymUnitSocialMedias: [GymUnitSocialMediaUpdate!]) {
            associateGymUnitSocialMediasToGymUnit(gymUnitId: $gymUnitId, gymUnitSocialMedias: $gymUnitSocialMedias) {
                url
                display
                displayOrder
                gymUnit {
                    id
                }
                socialMedia {
                    id
                }
                insertUser
                insertDate
                updateUser
                updateDate
                errors {
                    field
                    message
                }
            }
        }
    `;
}
