import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserBackoffice } from '@models';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

interface Data {
  profileName: string;
  description: string;
  linkedUsers: UserBackoffice[];
  users: UserBackoffice[];
}

@Component({
  selector: 'app-user-permissions-edit-modal',
  templateUrl: './user-permissions-edit-modal.component.html',
  styleUrls: ['./user-permissions-edit-modal.component.scss']
})
export class UserPermissionsEditModalComponent implements OnInit {

  public linkedUsers: UserBackoffice[];

  constructor(
    public dialogRef: MatDialogRef<UserPermissionsEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
  ) { }

  ngOnInit() {
    this.linkedUsers = this.data.linkedUsers || [];
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  saveAndCloseModal(): void {
    this.dialogRef.close(this.linkedUsers);
  }

}
