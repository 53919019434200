/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-message.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./error-message.component";
var styles_ErrorMessageComponent = [i0.styles];
var RenderType_ErrorMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorMessageComponent, data: {} });
export { RenderType_ErrorMessageComponent as RenderType_ErrorMessageComponent };
export function View_ErrorMessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "error-container"], ["id", "error-container"]], [[2, "invisible", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "error-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.control.touched || !_co.error); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.error; _ck(_v, 2, 0, currVal_1); }); }
export function View_ErrorMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error-message", [], null, null, null, View_ErrorMessageComponent_0, RenderType_ErrorMessageComponent)), i1.ɵdid(1, 114688, null, 0, i2.ErrorMessageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorMessageComponentNgFactory = i1.ɵccf("app-error-message", i2.ErrorMessageComponent, View_ErrorMessageComponent_Host_0, { control: "control", ngModel: "ngModel", messages: "messages" }, {}, []);
export { ErrorMessageComponentNgFactory as ErrorMessageComponentNgFactory };
