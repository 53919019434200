import gql from 'graphql-tag';

export interface PhotoUpdate {
    id?: number;
    description?: string;
    fileName?: string;
    url?: string;
    formerUrl?: string;
    portalDescription?: string;
    displayOrder?: number;
    objectType?: string;
    isSelected?: boolean;
    __typename?: string;
}

export interface PhotosData {
    photos: PhotoUpdate[] | PhotoUpdate;
    photosType: string;
}

export class PhotoUpdate {
    constructor(displayOrder: number) {
        this.url = '';
        this.displayOrder = displayOrder || null;
    }
}

export class PhotoGraphql {
    public static readonly queryListPhotosByGymUnit = gql`
        query($id: Long!) {
            listPhotosByGymUnit(id: $id) {
                id
                url
                portalDescription
                objectType
            }
        }
    `;

    public static readonly mutationAssociatePhotosToGymUnit = gql`
        mutation AssociatePhotosToGymUnit($gymUnitId: Long!, $photos: [PhotoUpdate!]) {
            associatePhotosToGymUnit(gymUnitId: $gymUnitId, photos: $photos)
            {
                id
                insertUser
                insertDate
                updateUser
                updateDate
                errors {
                    field
                    message
                }
            }
        }
    `;
}
