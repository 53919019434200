<div class="main-container" fxLayout="column" [formGroup]="appBannersForm">
  <div fxLayout="row" fxLayout.lt-md="column">
    <div class="container-image" fxFlex="grow">
      <app-file-drop
        filePurpose="app_banner"
        bucketPath="banners"
        [gymSlug]="SLUG_APP_BANNER"
        [invalid]="appBannersForm.controls.image.invalid && appBannersForm.controls.image.touched"
        (fileUrlEmitter)="getFileUrl($event)"
        [file]="file">
      </app-file-drop>
    </div>

    <div fxLayout="column" fxFlex="grow" ngClass.gt-sm="ml-20" ngClass.lt-md="mt-18">
      <div class="H5-LatoBold-C6 mb-10">LINK DO BANNER (PARA LINK EXTERNO, INFORME MANUALMENTE)</div>
      <div class="form-group" id="group-banner-title">
        <input id="banner-title" name="banner-title" type="text" class="input-text fill-available-width"
          formControlName="bannerAction" [matAutocomplete]="auto"
          (click)="onClickAndInputHandler($event)" (input)="onClickAndInputHandler($event)">
        <mat-autocomplete #auto>
          <mat-option *ngFor="let link of filteredLinks | async" [value]="link">{{ link }}</mat-option>
        </mat-autocomplete>
      </div>
      <app-error-message class="error" [control]="appBannersForm.controls.bannerAction"></app-error-message>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="end center">
        <button
          type="button"
          id="login-button"
          class="bo-btn bo-btn-c7 px-30"
          (click)="preview()"
          [disabled]="!appBannersForm.value.image">
          PREVIEW
        </button>
        <button
          type="button"
          id="login-button"
          class="bo-btn bo-primary-btn px-50"
          ngClass.gt-xs="ml-18"
          ngClass.lt-sm="mt-18"
          (click)="save()">
          {{ appBannerToEdit ? 'SALVAR' : 'ADICIONAR BANNER' }}
        </button>
      </div>
    </div>
  </div>
</div>
