import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { StorageService } from '@services';
import { AppConstants } from '@utils/app-constants';
import { Router } from '@angular/router';
import { StringUtil } from '@utils/string.util';
import { ScrollService } from '@services/scroll.service';

interface MappedState {
  id: number;
  name: string;
}
@Component({
  selector: 'app-select-gym-modal',
  templateUrl: './select-gym-modal.component.html',
  styleUrls: ['./select-gym-modal.component.scss', '../sided-menu-generic-modal.component.scss']
})
export class SelectGymModalComponent implements OnInit {

  public states: MappedState[];
  public gyms = [];

  public selectedStateId: number;
  public selectedGym: number;

  searchText = '';

  constructor(
    public dialogRef: MatDialogRef<SelectGymModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storageService: StorageService,
    private router: Router,
    private scrollService: ScrollService
  ) { }

  ngOnInit() {
    this.getStatesWithoutDuplicate();
    const storageGym = this.data.selectedGym;
    if (storageGym) {
      this.selectedGym   = storageGym.id;
      this.selectedStateId = storageGym.address.city.state.id;
      this.selectState(this.selectedStateId);
    }
  }

  public selectState(stateId: number): void {
    this.selectedStateId = stateId;
    this.gyms = this.data.gyms.filter(gym => gym.address.city.state.id === stateId);
    this.searchText = '';
    this.scrollService.scroll();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  getStatesWithoutDuplicate(): void {
    this.states = this.data.gyms.map((x: any) => {
      return { name: x.address.city.state.name, id: x.address.city.state.id };
    });
    this.states =  Array.from(new Set(this.states.map(a => a.id)))
    .map(id => {
      return this.states.find(a => a.id === id);
    });
    this.states.sort((stateA: MappedState, stateB: MappedState) => {
      return StringUtil.strcmp(stateA.name, stateB.name);
    });
    if (this.states.length) {
      this.selectedStateId = this.states[0].id;
      this.selectState(this.selectedStateId);
    }
  }

  public selectGym(gymId: number, gymSlug: string): void {
    this.selectedGym = gymId;
    const sg = this.data.gyms.find(gym => gym.id === this.selectedGym);
    this.dialogRef.close(sg);
    const splittedUrl = this.router.url.split('/');
    if (!splittedUrl.find(subPath => subPath === 'geral') && !this.data.noRedirect) {
      splittedUrl[1] = gymSlug;
      const newUrl = splittedUrl.join('/');
      this.router.navigate([`/${newUrl}`]);
    }
  }
}
