import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { ConvenienceGraphql, ConvenienceIcon, Convenience } from '@models';
import { JSONUtil } from '@utils/json-util';
import { map } from 'rxjs/operators';

@Injectable()
export class ConvenienceService {

  constructor(
    private readonly apollo: Apollo,
  ) { }

  public listConveniencesByGymUnit(id: number): Observable<any> {
    return this.apollo.query({
      query: ConvenienceGraphql.queryListConveniencesByGymUnit,
      variables: {
        id
      },
    }).pipe(
      map(JSONUtil.turnApolloMutable('listConveniencesByGymUnit')),
    );
  }

  public saveConvenience(gymUnitId: number, conveniences: Convenience[]): Observable<Convenience[]> {
    return this.apollo.mutate<Convenience[]>(
      {
        mutation: ConvenienceGraphql.mutationAssociateConveniencesToGymUnit,
        variables: {
          gymUnitId,
          conveniences,
        }
      })
      .pipe(
        map(JSONUtil.turnApolloMutable('associateConveniencesToGymUnit')),
      );
  }
  public getConvenienceIcons(): Observable<ConvenienceIcon[]> {
    return this.apollo.query({
      query: ConvenienceGraphql.queryListConvenienceIcons,
    }).pipe(
      map(JSONUtil.turnApolloMutable('listConvenienceIcons')),
    );
  }
}

