import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ChooseGymsModalComponent } from '@sharedcomponents/sided-menu-generic-modal/choose-gyms-modal/choose-gyms-modal.component';
import { GymService } from '@services/gym.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AlertMessageService } from '@services/alert-message.service';
import { Promotion } from '@models/promotion';
import { FormUtil } from '@utils/form-util';
import { AlertMessage, MessageMap } from '@models';
import { StringUtil } from '@utils/string.util';
import { TagInputComponent } from 'ngx-chips';
import { DialogService } from '@services';

@Component({
  selector: 'app-promotions-form',
  templateUrl: './promotions-form.component.html',
  styleUrls: ['./promotions-form.component.scss'],
})
export class PromotionsFormComponent implements OnInit, AfterViewInit {

  @Input() public promotionToEdit: Promotion;
  @Input() public promotions: Promotion[];

  @Output() public promotionEmitter: EventEmitter<Promotion> = new EventEmitter();

  @ViewChild('tagInputGymUnits', { static: false }) tagInputGymUnits: TagInputComponent;

  public promotionsForm: FormGroup;

  public tagInputEmptyMessage = 'Clique para selecionar';
  public tagInputNotEmptyMessage = 'Clique para alterar';

  public isInvalidTagInput = false;

  public dateFilter: any;

  constructor(
    private dialogService: DialogService,
    private gymService: GymService,
    private formBuilder: FormBuilder,
    private alertMessageService: AlertMessageService,
  ) { }

  public ngOnInit() {
    this.promotionsForm = this.formBuilder.group({
      id: null,
      thumbnailText: new FormControl(null, [Validators.required, Validators.maxLength(80)]),
      purchaseTitle: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      initialDate: new FormControl(null, [Validators.required]),
      finalDate: new FormControl(null, [Validators.required]),
      purchaseText: new FormControl(null, [Validators.required, Validators.maxLength(250)]),
      gymUnities: new FormControl(null, [Validators.required]),
    });

    if (this.promotionToEdit) {
      this.promotionToEdit.gymUnities.sort((a, b) => {
        return StringUtil.strcmp(a.name, b.name);
      });
      this.promotionsForm.patchValue({
        id: this.promotionToEdit.id,
        thumbnailText: this.promotionToEdit.thumbnailText,
        purchaseTitle: this.promotionToEdit.purchaseTitle,
        initialDate: this.promotionToEdit.initialDate,
        finalDate: this.promotionToEdit.finalDate,
        purchaseText: this.promotionToEdit.purchaseText,
        gymUnities: this.promotionToEdit.gymUnities,
      });
/*       this.dateFilter = (date: Date) =>
        !this.promotions.some(
          d => date >= new Date(d.initialDate) && date <= new Date(d.finalDate),
        );
    } else {
      this.dateFilter = (date: Date) =>
        !this.promotions.some(
          d => date <= new Date(d.finalDate),
        ); */
    }
  }

  ngAfterViewInit() {
    this.tagInputGymUnits.inputForm.input.nativeElement.disabled = true;
  }

  public gymsSelectionModal() {
    this.gymService.getGymNamesWithState().subscribe(
      res => {
        this.dialogService.openDialog(ChooseGymsModalComponent, {
          width: '800px',
          maxHeight: '90vh',
          data: {
            gyms: res,
            selectedGyms: this.promotionsForm.get('gymUnities').value,
          },
        });
        this.dialogService.afterClosed().subscribe(gyms => {
          if (gyms) {
            gyms.sort((a, b) => {
              return StringUtil.strcmp(a.name, b.name);
            });
            this.promotionsForm.get('gymUnities').setValue(gyms);
            this.promotionsForm.get('gymUnities').markAsTouched();
            this.updateInvalidTagInput();
          }
        });
      },
      () => {
        this.alertMessageService.showToastr(AlertMessage.error(MessageMap.SEM_CONEXAO_INTERNET));
      },
    );
  }

  public save() {
    FormUtil.touchForm(this.promotionsForm);
    this.updateInvalidTagInput();
    this.trimFields(this.promotionsForm);
    if (this.promotionsForm.valid) {
/*
      if (this.checkInterval(this.promotionsForm.value)) {
        this.alertMessageService.showToastr(
          AlertMessage.error(MessageMap.CONFLICTING_PROMOTION_PERIOD),
        );
        return;
      }
      if (this.checkLastInterval(this.promotionsForm.value)) {
        this.alertMessageService.showToastr(
          AlertMessage.error(MessageMap.CONFLICTING_PROMOTION_LAST_DATE),
        );
        return;
      }
*/
      this.promotionEmitter.emit(this.promotionsForm.value);
      this.promotionsForm.reset();
    } else {
      this.promotionsForm.markAsTouched();
      this.alertMessageService.showToastr(AlertMessage.error(MessageMap.CAMPOS_EM_VERMELHO));
    }
  }

  private trimFields(form: FormGroup) {
    form.patchValue({
      thumbnailText: form.value.thumbnailText ? form.value.thumbnailText.trim() : '',
      purchaseTitle: form.value.purchaseTitle ? form.value.purchaseTitle.trim() : '',
      purchaseText: form.value.purchaseText ? form.value.purchaseText.trim() : '',
    });
  }

/*   private checkInterval(promotion: Promotion): boolean {
    return (
      !!this.promotions &&
      this.promotions.some(
        internalPromotion =>
          (new Date(promotion.initialDate) >= new Date(internalPromotion.initialDate) &&
            new Date(promotion.initialDate) <= new Date(internalPromotion.finalDate)) ||
          (new Date(promotion.finalDate) >= new Date(internalPromotion.initialDate) &&
            new Date(promotion.finalDate) <= new Date(internalPromotion.finalDate)) ||
          (new Date(promotion.initialDate) <= new Date(internalPromotion.initialDate) &&
            new Date(promotion.finalDate) >= new Date(internalPromotion.finalDate)),
      )
    );
  }
*/

/*   private checkLastInterval(promotion: Promotion): boolean {
    if (promotion.id !== null && promotion.id !== undefined) {
      return false;
    }

    return (
      !!this.promotions &&
      this.promotions.some(internalPromotion => new Date(promotion.initialDate) <= new Date(internalPromotion.finalDate))
    );
  }
*/

  public updateInvalidTagInput(): void {
    const control = this.promotionsForm.get('gymUnities');
    this.isInvalidTagInput =
      control.touched && (!control.value || (!!control.value && !control.value.length));
  }
}
