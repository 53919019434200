<app-sided-menu-generic-modal modalTitle="SELECIONE ESTADO E UNIDADE">
  <div aside class="aside-item H4-LatoRegular-B" (click)="selectState(state.id)" *ngFor="let state of states"
    [ngClass]="{'selected-state': selectedStateId === state.id}">
    {{ state.name }}
  </div>
  
  <div header class="search-box-with-btn" fxHide.lt-sm>
    <input class="search-input" type="text" [(ngModel)]="searchText">
    <img class="search-icon" src="./assets/images/24-p-lupa-c-7.svg">
    <button class="bo-btn search-button H5-LatoBold-B">Buscar</button>
  </div>

  <div header class="select-state-box" fxHide.gt-xs>
    <ng-select [items]="states" [searchable]="false" [clearable]="false" [selectOnTab]="true" bindLabel="name"
      bindValue="id" placeholder="Selecione um estado" (change)="selectState($event.id)" class="w-100"
      [(ngModel)]="selectedStateId">
    </ng-select>
  </div>

  <div main *ngIf="gyms">
    <div class="search-list" *ngFor="let gym of gyms | filter : searchText : 'name'">
      <div class="gym-name H4-LatoRegular-C9" (click)="selectGym(gym.id, gym.slug)"
        [ngClass]="{'selected': selectedGym === gym.id}">
        {{ gym.name }}
      </div>
    </div>
  </div>
</app-sided-menu-generic-modal>
