<div class="modal-content">
  <div class="modal-header">
    EDITAR PERIODIZAÇÃO
    <img src="./assets/icon_custom/18-p-fechar-b.svg" class="close-btn" (click)="closeModal()">
  </div>
  <div class="modal-body">
    <div class="main-container" [formGroup]="periodizationEditForm">
      <div fxLayout="row wrap">
        <div 
          [ngStyle]="{'width': hasDescription ? '100%' : i % 2 === 0 ? 'calc(50% - 16px)' : '50%'}" 
          [ngClass]="{'mb-16': (i % 2 === 0 && !hasDescription) , 'mr-16': (i % 2 === 0 && !hasDescription) }" 
          *ngFor="let periodizationDomain of periodizationDomainList; index as i"
        >
          <div class="H5-LatoBold-C6 banner-title mr-16 mb-10 uppercase w-100" ngClass.lt-sm="mb-8">
            {{ periodizationDomain.name }}</div>
            <ng-select class="w-100" [items]="periodizationDomain.periodizationDomainVariables" [searchable]="false" [clearable]="false"
            [selectOnTab]="true" bindLabel="nameAndDescription" [formControlName]="''+periodizationDomain.id"
            placeholder="Selecione">
          </ng-select>
          <app-error-message class="error" [control]="periodizationEditForm.controls[''+periodizationDomain.id]"></app-error-message>
        </div>
      </div>
      <div class="text-right">
        <button (click)="editPeriodization()" class="bo-btn bo-primary-btn fit-content-width mt-24 px-35 H5-LatoBold-B">
          SALVAR ALTERAÇÃO
        </button>
      </div>
    </div>
  </div>