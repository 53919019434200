import * as tslib_1 from "tslib";
import gql from 'graphql-tag';
var GymUnitSocialMediaGraphql = /** @class */ (function () {
    function GymUnitSocialMediaGraphql() {
    }
    GymUnitSocialMediaGraphql.queryListGymUnitSocialMediasByGymUnit = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n        query($gymUnitId: Long!) {\n            listGymUnitSocialMediasByGymUnit(id: $gymUnitId) {\n                url\n                display\n                socialMedia {\n                    id\n                    name\n                }\n                gymUnit {\n                    id\n                }\n            }\n        }\n    "], ["\n        query($gymUnitId: Long!) {\n            listGymUnitSocialMediasByGymUnit(id: $gymUnitId) {\n                url\n                display\n                socialMedia {\n                    id\n                    name\n                }\n                gymUnit {\n                    id\n                }\n            }\n        }\n    "])));
    GymUnitSocialMediaGraphql.mutationAssociateGymUnitSocialMediasToGymUnit = gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n        mutation AssociateGymUnitSocialMediasToGymUnit($gymUnitId: Long!, $gymUnitSocialMedias: [GymUnitSocialMediaUpdate!]) {\n            associateGymUnitSocialMediasToGymUnit(gymUnitId: $gymUnitId, gymUnitSocialMedias: $gymUnitSocialMedias) {\n                url\n                display\n                displayOrder\n                gymUnit {\n                    id\n                }\n                socialMedia {\n                    id\n                }\n                insertUser\n                insertDate\n                updateUser\n                updateDate\n                errors {\n                    field\n                    message\n                }\n            }\n        }\n    "], ["\n        mutation AssociateGymUnitSocialMediasToGymUnit($gymUnitId: Long!, $gymUnitSocialMedias: [GymUnitSocialMediaUpdate!]) {\n            associateGymUnitSocialMediasToGymUnit(gymUnitId: $gymUnitId, gymUnitSocialMedias: $gymUnitSocialMedias) {\n                url\n                display\n                displayOrder\n                gymUnit {\n                    id\n                }\n                socialMedia {\n                    id\n                }\n                insertUser\n                insertDate\n                updateUser\n                updateDate\n                errors {\n                    field\n                    message\n                }\n            }\n        }\n    "])));
    return GymUnitSocialMediaGraphql;
}());
export { GymUnitSocialMediaGraphql };
var templateObject_1, templateObject_2;
