import * as tslib_1 from "tslib";
import gql from 'graphql-tag';
var ConvenienceGraphql = /** @class */ (function () {
    function ConvenienceGraphql() {
    }
    ConvenienceGraphql.queryListConveniencesByGymUnit = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n        query($id: Long!) {\n            listConveniencesByGymUnit(id: $id) {\n                id\n                name\n                icon\n                displayOrder\n                url\n                description\n            }\n        }\n    "], ["\n        query($id: Long!) {\n            listConveniencesByGymUnit(id: $id) {\n                id\n                name\n                icon\n                displayOrder\n                url\n                description\n            }\n        }\n    "])));
    ConvenienceGraphql.mutationAssociateConveniencesToGymUnit = gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n        mutation associateConveniencesToGymUnit($gymUnitId: Long!, $conveniences: [ConvenienceUpdate!]) {\n            associateConveniencesToGymUnit(gymUnitId: $gymUnitId, conveniences: $conveniences)\n            {\n                id\n                insertUser\n                insertDate\n                updateUser\n                updateDate\n                errors {\n                    field\n                    message\n                }\n            }\n        }\n    "], ["\n        mutation associateConveniencesToGymUnit($gymUnitId: Long!, $conveniences: [ConvenienceUpdate!]) {\n            associateConveniencesToGymUnit(gymUnitId: $gymUnitId, conveniences: $conveniences)\n            {\n                id\n                insertUser\n                insertDate\n                updateUser\n                updateDate\n                errors {\n                    field\n                    message\n                }\n            }\n        }\n    "])));
    ConvenienceGraphql.queryListConvenienceIcons = gql(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n        query {\n            listConvenienceIcons {\n            id\n            icon\n            display\n            displayOrder\n            }\n        }\n    "], ["\n        query {\n            listConvenienceIcons {\n            id\n            icon\n            display\n            displayOrder\n            }\n        }\n    "])));
    return ConvenienceGraphql;
}());
export { ConvenienceGraphql };
var templateObject_1, templateObject_2, templateObject_3;
