import { Apollo } from 'apollo-angular';
import { PlaceGraphql } from '@models/place';
import { JSONUtil } from '@utils/json-util';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
var PlaceService = /** @class */ (function () {
    function PlaceService(apollo) {
        this.apollo = apollo;
    }
    PlaceService.prototype.getPlacesByGymUnit = function (gymUnitId) {
        return this.apollo.query({
            query: PlaceGraphql.queryListPlacesByGymUnit,
            variables: {
                id: gymUnitId
            },
        })
            .pipe(map(JSONUtil.turnApolloMutable('listPlacesByGymUnit')));
    };
    PlaceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlaceService_Factory() { return new PlaceService(i0.ɵɵinject(i1.Apollo)); }, token: PlaceService, providedIn: "root" });
    return PlaceService;
}());
export { PlaceService };
