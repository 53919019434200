import { Component, ViewChild, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BlockUiService } from '@services';
import { BlockUIAction } from '@models';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild(ToastContainerDirective, { read: true, static: false }) toastContainer: ToastContainerDirective;

  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private readonly blockUiService: BlockUiService,
    private readonly toastrService: ToastrService,
  ) {
    this.blockUiService.emitStatus.subscribe(status => {
      switch (status.action) {
        case BlockUIAction.START:
          this.blockUI.start();
          break;
        case BlockUIAction.START_CUSTOM_MESSAGE:
          this.blockUI.start(status.message);
          break;

        case BlockUIAction.STOP:
          this.blockUI.stop();
          break;
        case BlockUIAction.NETWORK_ERROR:
          this.blockUI.stop();
          // to do - verificar se é interessante colocar algo parecido no backoffice
          // this.networkErrorComponent.openModal();
          break;
      }
    });
  }

  ngOnInit() {
    this.toastrService.overlayContainer = this.toastContainer;
  }
}
