import * as tslib_1 from "tslib";
import gql from 'graphql-tag';
var InfoServiceGraphql = /** @class */ (function () {
    function InfoServiceGraphql() {
    }
    InfoServiceGraphql.queryListInfoServices = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n    query {\n      listServices {\n        id\n        name\n        icon\n        description\n      }\n    }\n  "], ["\n    query {\n      listServices {\n        id\n        name\n        icon\n        description\n      }\n    }\n  "])));
    InfoServiceGraphql.mutationUpdateServiceIconAndDescription = gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n    mutation updateServiceIconAndDescription($id: Long!, $icon: String!, $description: String!) {\n      updateServiceIconAndDescription(id: $id, icon: $icon, description: $description) {\n        id\n        icon\n        description\n        errors {\n          field\n          message\n        }\n      }\n    }\n  "], ["\n    mutation updateServiceIconAndDescription($id: Long!, $icon: String!, $description: String!) {\n      updateServiceIconAndDescription(id: $id, icon: $icon, description: $description) {\n        id\n        icon\n        description\n        errors {\n          field\n          message\n        }\n      }\n    }\n  "])));
    InfoServiceGraphql.queryListInfoServiceIcons = gql(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n    query {\n      listServiceIcons {\n        id\n        icon\n        display\n        displayOrder\n      }\n    }\n  "], ["\n    query {\n      listServiceIcons {\n        id\n        icon\n        display\n        displayOrder\n      }\n    }\n  "])));
    return InfoServiceGraphql;
}());
export { InfoServiceGraphql };
var templateObject_1, templateObject_2, templateObject_3;
