import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

export type Brand = 'bt' | 'fr';

@Injectable()
export class BrandService {

  getBrand(): Brand {
    return environment.brand as Brand;
  }

}
