<div class="main-container" fxLayout="row" fxLayout.lt-sm="column">
  <div>
    <div *ngIf="!infoServiceForm.controls.icon.value; else hasIcon"
      [class.invalid]="infoServiceForm.controls.icon.invalid && infoServiceForm.controls.icon.touched"
      class="select-icon"
      (click)="selectIcon()">
      <p class="py-5 mx-1 text-help">Selecionar Ícone</p>
      <img src="./assets/icon_custom/36-p-icones-c-4.svg">
    </div>
    <span *ngIf="infoServiceForm.controls.icon.invalid && infoServiceForm.controls.icon.touched" class="input-file-error">O ícone é obrigatório</span>
  </div>
  <ng-template #hasIcon>
    <div fxLayout="column" fxLayoutAlign="start center">
      <div class="selected-icon" fxLayoutAlign="center center">
        <img [src]="infoServiceForm.controls.icon.value" (click)="selectIcon()">
      </div>
      <div class="edit-icon-label B2-LatoRegular-C4">Clique no ícone para editar.</div>
    </div>
  </ng-template>
  <div class="w-100" [formGroup]="infoServiceForm" fxLayout="column" ngClass.lt-sm="mt-16" ngClass.gt-xs="ml-26">
    <div [fxLayout]="infoServiceToEdit ? 'column' : 'row'" fxLayout.lt-md="column">
      <div fxFlex="40">
        <div class="H5-LatoBold-C6 mb-2">NOME</div>
        <div class="B1-LatoRegular-C7 mb-16">{{ infoServiceToEdit.name }}</div>
      </div>
    </div>
    <div class="H5-LatoBold-C6 mb-10">DESCRIÇÃO</div>
    <textarea id="service-description" name="service-description" type="text" class="text-area input-text"
      formControlName="description">
    </textarea>
    <app-error-message class="error" [control]="infoServiceForm.controls.description"></app-error-message>
    <button
      type="button"
      id="login-button"
      class="bo-btn bo-primary-btn"
      (click)="save()"
      [fxFlexAlign]="infoServiceToEdit ? 'end' : 'start'"
      >
      {{ infoServiceToEdit ? 'SALVAR' : 'ADICIONAR SERVIÇO' }}
    </button>
  </div>
</div>
