import gql from 'graphql-tag';

export interface SocialMedia {
    id?: number;
    name?: string;
    urlImage?: string;
}

export class SocialMediaGraphql {
    public static readonly queryListSocialMedias = gql`
        query {
            listSocialMedias {
                id
                name
                urlImage
            }
        }
  `;
}
