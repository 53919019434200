import { Pipe, PipeTransform } from '@angular/core';
import { BrandService, Brand } from '../services/brand.service';
import { Item } from '@models';
@Pipe({
    name: 'brand'
})
export class BrandPipe implements PipeTransform {

    brand: Brand;

    constructor(
        public brandService: BrandService
    ) {
        this.brand = this.brandService.getBrand();
    }

    transform(items: Item[]): Item[] {
        if (!items) {
            return [];
        }
        return items.filter(it => {
            return it.brands ? it.brands.includes(this.brand) : true;
        });
    }
}
