import * as tslib_1 from "tslib";
import gql from 'graphql-tag';
var WorkingPeriodGraphql = /** @class */ (function () {
    function WorkingPeriodGraphql() {
    }
    WorkingPeriodGraphql.queryListWorkingPeriodsByGymUnit = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n    query($id: Long!) {\n      listWorkingPeriodsByGymUnit(id: $id) {\n        id\n        dayOfTheWeek\n        timeOfDay\n        initialTimeOne\n        finalTimeOne\n        initialTimeTwo\n        finalTimeTwo\n        closed\n        gymUnit {\n          id\n        }\n      }\n    }\n  "], ["\n    query($id: Long!) {\n      listWorkingPeriodsByGymUnit(id: $id) {\n        id\n        dayOfTheWeek\n        timeOfDay\n        initialTimeOne\n        finalTimeOne\n        initialTimeTwo\n        finalTimeTwo\n        closed\n        gymUnit {\n          id\n        }\n      }\n    }\n  "])));
    return WorkingPeriodGraphql;
}());
export { WorkingPeriodGraphql };
var templateObject_1;
