import * as tslib_1 from "tslib";
import gql from 'graphql-tag';
var PlanDetailsGraphql = /** @class */ (function () {
    function PlanDetailsGraphql() {
    }
    PlanDetailsGraphql.queryListGymUnitGroupPlans = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n    query {\n       listGymUnitGroupPlans {\n        gymUnitGroupPlan {\n            plan {\n              id\n              shortName\n            }\n        }\n        gymUnities {\n            id\n            name\n            address {\n                id\n                city {\n                    id\n                    state {\n                        id\n                        name\n                    }\n                }\n            }\n        }\n      }\n    }\n  "], ["\n    query {\n       listGymUnitGroupPlans {\n        gymUnitGroupPlan {\n            plan {\n              id\n              shortName\n            }\n        }\n        gymUnities {\n            id\n            name\n            address {\n                id\n                city {\n                    id\n                    state {\n                        id\n                        name\n                    }\n                }\n            }\n        }\n      }\n    }\n  "])));
    PlanDetailsGraphql.mutationUpdateBatchGymUnitGroupPlanDescriptionsNameAndAvailableActivities = gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n    mutation UpdateBatchGymUnitGroupPlanDescriptionsNameAndAvailableActivities(\n      $planId: Long!\n      $gymUnitIds: [Long!]\n      $shortDescription: String,\n      $longDescription: String,\n      $availableActivities: String,\n      $portalName: String,\n    ) {\n      updateBatchGymUnitGroupPlanDescriptionsNameAndAvailableActivities(\n        planId: $planId,\n        gymUnitIds: $gymUnitIds,\n        shortDescription: $shortDescription,\n        longDescription: $longDescription,\n        availableActivities: $availableActivities,\n        portalName: $portalName\n      ) {\n        plan {\n          id\n        }\n        errors {\n          field\n          message\n        }\n      }\n    }\n  "], ["\n    mutation UpdateBatchGymUnitGroupPlanDescriptionsNameAndAvailableActivities(\n      $planId: Long!\n      $gymUnitIds: [Long!]\n      $shortDescription: String,\n      $longDescription: String,\n      $availableActivities: String,\n      $portalName: String,\n    ) {\n      updateBatchGymUnitGroupPlanDescriptionsNameAndAvailableActivities(\n        planId: $planId,\n        gymUnitIds: $gymUnitIds,\n        shortDescription: $shortDescription,\n        longDescription: $longDescription,\n        availableActivities: $availableActivities,\n        portalName: $portalName\n      ) {\n        plan {\n          id\n        }\n        errors {\n          field\n          message\n        }\n      }\n    }\n  "])));
    return PlanDetailsGraphql;
}());
export { PlanDetailsGraphql };
var templateObject_1, templateObject_2;
