import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Banner, GymUnit, AlertMessage, MessageMap, PhotoUpdate } from '@models';
import { FormUtil } from '@utils/form-util';
import { BannersPreviewModalComponent } from './../banners-preview-modal/banners-preview-modal.component';
import { AlertMessageService } from '@services/alert-message.service';
import { ValidateUrl } from '@validators/url.validator';
import { DialogService } from '@services';

@Component({
  selector: 'app-banners-form',
  templateUrl: './banners-form.component.html',
  styleUrls: ['./banners-form.component.scss'],
})
export class BannersFormComponent implements OnInit {
  public bannersForm: FormGroup;
  public selectedGym: GymUnit;
  public banner: string;
  public isLoading = false;
  public file: PhotoUpdate;

  private SLUG_BANNER = 'banner_home';

  @Input() bannerToEdit: Banner = null;
  @Output() bannerEmitter: EventEmitter<Banner> = new EventEmitter();

  color = 'primary';
  mode = 'indeterminate';
  value = 50;

  constructor(
    private formBuilder: FormBuilder,
    private alertMessageService: AlertMessageService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.bannersForm = this.formBuilder.group({
      title: new FormControl(null, Validators.maxLength(255)),
      subtitle: new FormControl(null, Validators.maxLength(255)),
      image: new FormControl(null, [Validators.required]),
      buttonText: new FormControl(null, Validators.maxLength(22)),
      linkUrl: new FormControl(null, [Validators.maxLength(255), ValidateUrl(false)]),
      legalText: new FormControl(null, Validators.maxLength(255)),
      displayOrder: new FormControl(1, [Validators.required]),
      initialDate: new FormControl(null),
      finalDate: new FormControl(null),
      isSelected: false,
    });

    if (this.bannerToEdit) {
      this.bannersForm.patchValue({
        title: this.bannerToEdit.title,
        subtitle: this.bannerToEdit.subtitle,
        image: this.bannerToEdit.image,
        buttonText: this.bannerToEdit.buttonText,
        linkUrl: this.bannerToEdit.linkUrl,
        legalText: this.bannerToEdit.legalText,
        displayOrder: this.bannerToEdit.displayOrder,
        initialDate: this.bannerToEdit.initialDate,
        finalDate: this.bannerToEdit.finalDate,
      });
    }

    if (!this.file) {
      this.file = new PhotoUpdate(0);
      this.file.url = (this.bannerToEdit) ? this.bannerToEdit.image : '';
    }
  }

  getFileUrl(url: string) {
    const control = this.bannersForm.get('image');
    if (url) {
      control.setValue(url);
      this.file.url = url;
    }
  }

  preview(previewType: string) {
    if (this.bannersForm.controls.image.value) {
      this.dialogService.openDialog(BannersPreviewModalComponent, {
        width: '1100px',
        height: '800px',
        maxWidth: window.innerWidth < 600 ? '100vw' : '1100px',
        maxHeight: '90vh',
        data: {
          preview: { previewType, banner: this.bannersForm.value },
        },
      });
    }
  }

  save() {
    FormUtil.touchForm(this.bannersForm);
    if (this.bannersForm.valid) {
      this.trimFields(this.bannersForm);
      this.bannerEmitter.emit(this.bannersForm.value);
      this.bannersForm.reset();
      this.bannersForm.controls.displayOrder.setValue(1);
      this.bannersForm.controls.isSelected.setValue(false);
      this.file = new PhotoUpdate(0);
    } else {
      this.bannersForm.markAsTouched();
      this.alertMessageService.showToastr(
        AlertMessage.error(MessageMap.CAMPOS_EM_VERMELHO),
      );
    }
  }

  setLoading(state: boolean) {
    this.isLoading = state;
  }

  trimFields(form: FormGroup) {
    form.value.title = form.value.title ? form.value.title.trim() : '';
    form.value.subtitle = form.value.subtitle ? form.value.subtitle.trim() : '';
    form.value.buttonText = form.value.buttonText ? form.value.buttonText.trim() : '';
    form.value.linkUrl = form.value.linkUrl ? form.value.linkUrl.trim() : '';
    form.value.legalText = form.value.legalText ? form.value.legalText.trim() : '';
  }

  now(): Date {
    return this.bannerToEdit ? null : new Date();
  }

  clearFieldIfEmpty(value) {
    if (value === '') {
      this.bannersForm.get('linkUrl').setValue('');
    }
  }

  clearErroUrl() {
    FormUtil.checkURL(this.bannersForm.controls.linkUrl, ['http', 'https', 'ftp']);
  }
}
