import * as tslib_1 from "tslib";
import gql from 'graphql-tag';
var GraphQLParam = /** @class */ (function () {
    function GraphQLParam() {
    }
    return GraphQLParam;
}());
export { GraphQLParam };
var GraphqlParser = /** @class */ (function () {
    function GraphqlParser() {
    }
    GraphqlParser.parseToCreateMutation = function (objectList, params) {
        var _this = this;
        var body = '';
        var index = 0;
        objectList.forEach(function (object) {
            index++;
            body += params.method + index + ": " + params.method + "(" + params.object + ": {\n            " + _this.parseJSONGraphQL(object, true) + "\n            }) {\n                id\n                errors {\n                    field\n                    message\n                }\n            }\n";
        });
        return gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["mutation { ", " }"], ["mutation { ", " }"])), body);
    };
    GraphqlParser.parseToUpdateMutation = function (objectList, params) {
        var _this = this;
        var body = '';
        var index = 0;
        objectList.forEach(function (object) {
            index++;
            if (params.object) {
                body += params.method + index + ": " + params.method + "(id: " + object.id + ", " + params.object + ": {\n                  " + _this.parseJSONGraphQL(object, true) + "\n                  }) {\n                      id\n                      errors {\n                      field\n                      message\n                  }\n              }\n";
            }
            else {
                body += params.method + index + ": " + params.method + "(id: " + object.id + ", " + _this.parseJSONGraphQL(object, true) + ") {\n                      id\n                      errors {\n                      field\n                      message\n                  }\n              }\n";
            }
        });
        return gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["mutation { ", " }"], ["mutation { ", " }"])), body);
    };
    GraphqlParser.parseToDeleteMutation = function (objectList, params) {
        var body = '';
        var index = 0;
        objectList.forEach(function (object) {
            index++;
            body += params.method + index + ": " + params.method + "(id: " + object.id + ") {\n                success\n                errors {\n                    field\n                    message\n                }\n            }\n";
        });
        return gql(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["mutation { ", " }"], ["mutation { ", " }"])), body);
    };
    GraphqlParser.parseJSONGraphQL = function (object, deleteId) {
        var _this = this;
        if (deleteId === void 0) { deleteId = false; }
        if (deleteId) {
            delete object.id;
        }
        return Object.entries(object).map(function (_a) {
            var key = _a[0], value = _a[1];
            if (typeof value === 'string') {
                return key + ": " + JSON.stringify(value) + "\n";
            }
            else if (value instanceof Object) {
                return key + ": { " + _this.parseJSONGraphQL(value) + " }\n";
            }
            else {
                return key + ": " + value + "\n";
            }
        });
    };
    return GraphqlParser;
}());
export { GraphqlParser };
var templateObject_1, templateObject_2, templateObject_3;
