<div class="modal-content grid-container">
  <div class="modal-header H5-LatoBold-B">
    {{ modalTitle }}
    <img src="./assets/icon_custom/18-p-fechar-b.svg" class="close-btn" (click)="closeModal()">
  </div>
  <div class="modal-body">
    <div fxLayout="row" fxLayoutAlign="start stretch" class="row">
      <div *ngIf="hasAsideMenu" class="modal-aside" fxFlex="noshrink" fxHide.lt-sm>
        <ng-content select="[aside]"></ng-content>
      </div>
      <div fxLayout="column" fxFlex="grow">
        <ng-content select="[header]"></ng-content>
        <div class="modal-main" #mainModal>
          <ng-content select="[main]"></ng-content>
        </div>
        <div fxLayout="row" class="footer" fxLayout.lt-sm="column">
          <ng-content select="[footer]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>