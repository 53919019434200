/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-banners-edit-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../app-banners-form/app-banners-form.component.ngfactory";
import * as i3 from "../app-banners-form/app-banners-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../../core/services/alert-message.service";
import * as i6 from "../../../../../core/services/dialog.service";
import * as i7 from "./app-banners-edit-modal.component";
import * as i8 from "@angular/material/dialog";
var styles_AppBannersEditModalComponent = [i0.styles];
var RenderType_AppBannersEditModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppBannersEditModalComponent, data: {} });
export { RenderType_AppBannersEditModalComponent as RenderType_AppBannersEditModalComponent };
export function View_AppBannersEditModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" EDITAR INFORMA\u00C7\u00D5ES DO BANNER DO APLICATIVO "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "close-btn"], ["src", "./assets/icon_custom/18-p-fechar-b.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-app-banners-form", [], null, [[null, "appBannerEmitter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("appBannerEmitter" === en)) {
        var pd_0 = (_co.editBanner($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AppBannersFormComponent_0, i2.RenderType_AppBannersFormComponent)), i1.ɵdid(6, 114688, null, 0, i3.AppBannersFormComponent, [i4.FormBuilder, i5.AlertMessageService, i6.DialogService], { appBannerToEdit: [0, "appBannerToEdit"] }, { appBannerEmitter: "appBannerEmitter" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.bannerToEdit; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_AppBannersEditModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-app-banners-edit-modal", [], null, null, null, View_AppBannersEditModalComponent_0, RenderType_AppBannersEditModalComponent)), i1.ɵdid(1, 49152, null, 0, i7.AppBannersEditModalComponent, [i8.MatDialogRef, i8.MAT_DIALOG_DATA], null, null)], null, null); }
var AppBannersEditModalComponentNgFactory = i1.ɵccf("app-app-banners-edit-modal", i7.AppBannersEditModalComponent, View_AppBannersEditModalComponent_Host_0, {}, {}, []);
export { AppBannersEditModalComponentNgFactory as AppBannersEditModalComponentNgFactory };
