<div class="main-container" fxLayout="column" [formGroup]="informativeForm">
  <div fxLayout="column" class="mb-20" *ngIf="!informativeToEdit">
    <div class="H4-LatoRegular-C7">
      <strong class="H3-LatoBold-C7">Aviso</strong> é feito para divulgar horários especiais (como Carnaval e feriados), 
      fechamento de unidade ou de salas (obras, enchente) ou alguma outra informação relevante sobre a unidade.
    </div>
    <div class="H4-LatoRegular-C7 mt-14">
      <strong class="H3-LatoBold-C7">Evento</strong> é feito para divulgar aulões especiais. 
      Basta marcar a opção “Isto é um evento?”, colocar a foto, informação de data e horário do evento, local e professor. 
      A veiculação do evento no aplicativo acaba assim que o evento termina.
    </div>
  </div>
  <div fxLayout="row" fxLayout.lt-sm="column" >
    <div fxLayout="column" class="container-image">
      <app-file-drop filePurpose="informative" bucketPath="banners" gymSlug="SLUG_BANNER" [isToViewOnly]="isToViewOnly"
        [invalid]="informativeForm.controls.imageUrl.invalid && informativeForm.controls.imageUrl.touched"
        (fileUrlEmitter)="getFileUrl($event)" [file]="file"></app-file-drop>
    </div>
    <div fxLayout="column" fxFlex="shrink" class="hidden-overflow-x">
      <div fxLayout="column">
        <div class="H5-LatoBold-C6 mb-10">TÍTULO</div>
        <div class="form-group mb-0">
          <input class="input-text fill-available-width" formControlName="title">
        </div>
        <app-error-message class="error" [control]="informativeForm.controls.title"></app-error-message>
      </div>
      <div fxLayout="column">
        <div class="H5-LatoBold-C6 mb-10">DESCRIÇÃO</div>
        <div class="form-group mb-0 h-77">
          <textarea class="input-text no-resize fill-available-width" formControlName="description"></textarea>
        </div>
        <app-error-message class="error" [control]="informativeForm.controls.description"></app-error-message>
      </div>
      <div fxLayout="column" fxLayout.gt-xs="row">
        <div fxLayout="column" fxFlex="shrink">
          <div class="H5-LatoBold-C6 mb-10">INÍCIO DA VEICULAÇÃO</div>
          <div fxLayout="row" class="form-group mt-auto mb-0" (click)="initialPublishingDate.open();">
            <input matInput class="input-text w-100 no-pointer-events" readonly
              [max]="informativeForm.value.finalPublishingDate" [matDatepicker]="initialPublishingDate"
              formControlName="initialPublishingDate" placeholder="00/00/0000">
            <mat-datepicker-toggle matSuffix [for]="initialPublishingDate" *ngIf="!isToViewOnly"></mat-datepicker-toggle>
            <mat-datepicker #initialPublishingDate (closed)="informativeForm.controls.initialPublishingDate.markAsTouched()">
            </mat-datepicker>
          </div>
          <app-error-message class="error" [control]="informativeForm.controls.initialPublishingDate"></app-error-message>
        </div>
        <div fxLayout="column" fxFlex="shrink" ngClass.gt-xs="ml-18">
          <div class="H5-LatoBold-C6 mb-10">FIM DA VEICULAÇÃO</div>
          <div fxLayout="row" class="form-group mt-auto mb-0" (click)="finalPublishingDate.open()">
            <input matInput class="input-text w-100 no-pointer-events" readonly
              [min]="informativeForm.value.initialPublishingDate" [matDatepicker]="finalPublishingDate"
              formControlName="finalPublishingDate" placeholder="00/00/0000" [disabled]="informativeForm.controls.isEvent.value">
            <mat-datepicker-toggle matSuffix [for]="finalPublishingDate"
              *ngIf="!isToViewOnly && !informativeForm.controls.isEvent.value"></mat-datepicker-toggle>
            <mat-datepicker #finalPublishingDate (closed)="informativeForm.controls.finalPublishingDate.markAsTouched()">
            </mat-datepicker>
          </div>
          <app-error-message class="error" [control]="informativeForm.controls.finalPublishingDate"></app-error-message>
        </div>
        <div fxLayout="row" fxFlex="shrink" fxLayoutAlign="start center" class="pt-10" ngClass.gt-xs="ml-20" ngClass.lt-sm="mb-18" [class.invisible]="!!informativeToEdit">
          <mat-slide-toggle formControlName="isEvent"></mat-slide-toggle>
          <div class="H4-LatoRegular-C6 ml-10">Isto é um evento?</div>
        </div>
      </div>
      <div fxLayout="column">
        <div class="H5-LatoBold-C6 mb-10">{{ getPlaceHolder() }}</div>
        <div (click)="isToViewOnly || gymUnitContext ? null : (isEvent() ? gymSelectionModal() : gymsSelectionModal())">
          <tag-input #tagInputGymUnits formControlName="gymUnities" [disable]="isToViewOnly || gymUnitContext"
            theme="tag-theme" class="tag-input gymUnities" [displayBy]="'name'"
            [placeholder]="!isToViewOnly && !gymUnitContext ? tagInputNotEmptyMessage : ''" [secondaryPlaceholder]="tagInputEmptyMessage">
          </tag-input>
        </div>
        <app-error-message fxFlex class="error" [control]="informativeForm.controls.gymUnities"></app-error-message>
      </div>
      <div fxLayout="column" class="accordion" [class.active]="informativeForm.controls.isEvent.value">
        <div fxLayout="column">
          <div class="H5-LatoBold-C6 mb-10">PROFESSOR (A) (DIGITE O NOME DESEJADO E O SELECIONE NA LISTA OU TECLE
            “ENTER”)</div>
          <tag-input formControlName="teacherName" [separatorKeyCodes]="[188]" [modelAsStrings]="true"
            theme="tag-theme" [placeholder]="''" [secondaryPlaceholder]="'Nenhum selecionado'"
            class="tag-input" [clearOnBlur]="true"
            [disable]="(!informativeForm.value.gymUnities?.length && !gymUnitContext) || isToViewOnly">
            <tag-input-dropdown [autocompleteItems]="teachersName"
              [displayBy]="'name'" [identifyBy]="'reducedName'" [appendToBody]="false"></tag-input-dropdown>
          </tag-input>
          <app-error-message class="error" [control]="informativeForm.controls.teacherName"></app-error-message>
        </div>
        <div fxLayout="column" fxFlex="shrink">
          <div class="H5-LatoBold-C6 mb-10">SALA</div>
          <div fxLayout="row" fxLayout.lt-sm="column">
            <div fxLayout="column" fxFlex="shrink" fxFlex.lt-sm="grow">
              <ng-select [items]="places" bindLabel="name" [searchable]="true" [clearable]="false"
                [selectOnTab]="true" placeholder="Selecione" formControlName="place" class="w-100">
              </ng-select>
              <app-error-message class="error" [control]="informativeForm.controls.place"></app-error-message>
            </div>
            <div fxLayout="column" fxFlex="shrink" fxFlex.lt-sm="grow" ngClass.gt-xs="ml-20">
              <div class="form-group mb-0 other-location" [class.invisible]="informativeForm.controls.place.value?.id !== 0">
                <input class="input-text fill-available-width" placeholder="Digite para informar outro local."
                  formControlName="otherPlace">
              </div>
              <app-error-message class="error" [control]="informativeForm.controls.otherPlace"></app-error-message>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column">
          <div fxLayout="column" fxFlex.gt-xs="nogrow" fxFlex.lt-sm="grow">
            <div class="H5-LatoBold-C6 mb-10">DATA DO EVENTO</div>
            <div fxLayout="row" class="form-group mt-auto mb-0" (click)="eventDate.open();">
              <input matInput class="input-text w-100 no-pointer-events" readonly [matDatepicker]="eventDate"
                formControlName="eventDate" placeholder="00/00/0000" [min]="informativeForm.value.initialPublishingDate">
              <mat-datepicker-toggle matSuffix [for]="eventDate" *ngIf="!isToViewOnly"></mat-datepicker-toggle>
              <mat-datepicker #eventDate (closed)="informativeForm.controls.eventDate.markAsTouched()">
              </mat-datepicker>
            </div>
            <app-error-message class="error" [control]="informativeForm.controls.eventDate"></app-error-message>
          </div>
          <div fxLayout="row" fxFlex.lt-sm="grow">
            <div fxLayout="column" fxFlex="shrink" ngClass.gt-sm="ml-18">
              <div class="H5-LatoBold-C6 mb-10">HORA DE INÍCIO DO EVENTO</div>
              <div fxLayout="row" class="form-group mt-auto mb-0">
                <input placeholder="00:00" [appMaskedInput]="hourMask" class="input-text w-100"
                  formControlName="initialTimeHour" [class.disabled]="isToViewOnly">
              </div>
              <app-error-message class="error" [control]="informativeForm.controls.initialTimeHour"></app-error-message>
            </div>
            <div fxLayout="column" fxFlex="shrink" class="ml-18">
              <div class="H5-LatoBold-C6 mb-10">HORA DE FIM DO EVENTO</div>
              <div fxLayout="row" class="form-group mt-auto mb-0">
                <input placeholder="00:00" [appMaskedInput]="hourMask" class="input-text w-100"
                  formControlName="finalTimeHour" [class.disabled]="isToViewOnly">
              </div>
              <app-error-message class="error" [control]="informativeForm.controls.finalTimeHour"></app-error-message>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-md="column" class="mt-8" fxLayoutAlign="end">
        <div fxLayout="row" fxLayoutAlign="start center" *ngIf="!!informativeToEdit" ngClass.lt-md="mb-18">
          <div class="H5-LatoBold-C6 mr-10">STATUS:</div>
          <ng-container *ngIf="!isToViewOnly; else toViewOnly" class="h-100">
            <button (click)="setStatus(InformativeStatus.DRAFT)" [disabled]="isToViewOnly" class="btn-status h-100 px-16"
              [class.selected]="isStatus(InformativeStatus.DRAFT)">Rascunho</button>
            <button (click)="setStatus(InformativeStatus.PUBLISHED)" [disabled]="isToViewOnly" class="btn-status h-100 px-16"
              [class.selected]="!isStatus(InformativeStatus.DRAFT)">Publicado</button>
          </ng-container>
          <ng-template #toViewOnly>
            <button class="btn-status h-100" disabled>{{ informativeToEdit.status }}</button>
          </ng-template>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" class="ml-auto">
          <button (click)="saveDraft()" *ngIf="!informativeToEdit" ngClass.lt-sm="mb-10"
            class="bo-btn bo-btn-c7 fit-content-width px-20 H5-LatoBold-B">SALVAR COMO RASCUNHO</button>
          <button (click)="save()" *ngIf="!isToViewOnly"
            class="bo-btn bo-primary-btn fit-content-width ml-18 px-70 H5-LatoBold-B">
            {{ !!informativeToEdit ? 'SALVAR' : 'PUBLICAR' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
