/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-banners-preview-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/extended";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "@angular/platform-browser";
import * as i6 from "./app-banners-preview-modal.component";
import * as i7 from "@angular/material/dialog";
var styles_AppBannersPreviewModalComponent = [i0.styles];
var RenderType_AppBannersPreviewModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppBannersPreviewModalComponent, data: {} });
export { RenderType_AppBannersPreviewModalComponent as RenderType_AppBannersPreviewModalComponent };
export function View_AppBannersPreviewModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" PREVIEW "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "close-btn"], ["src", "./assets/icon_custom/18-p-fechar-b.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "main-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "img", [["class", "w-100 h-100"], ["src", "assets/images/bt/app-banner-mask.svg"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(9, { "background-image": 0 }), i1.ɵdid(10, 933888, null, 0, i3.DefaultStyleDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgStyleImpl, i5.DomSanitizer, [6, i2.NgStyle], [2, i4.SERVER_TOKEN], i1.PLATFORM_ID], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(11, { "background-image": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 9, 0, (("url(" + _co.banner.image) + ")")); _ck(_v, 8, 0, currVal_0); var currVal_1 = _ck(_v, 11, 0, (("url(" + _co.banner.image) + ")")); _ck(_v, 10, 0, currVal_1); }, null); }
export function View_AppBannersPreviewModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-app-banners-preview-modal", [], null, null, null, View_AppBannersPreviewModalComponent_0, RenderType_AppBannersPreviewModalComponent)), i1.ɵdid(1, 114688, null, 0, i6.AppBannersPreviewModalComponent, [i7.MatDialogRef, i7.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppBannersPreviewModalComponentNgFactory = i1.ɵccf("app-app-banners-preview-modal", i6.AppBannersPreviewModalComponent, View_AppBannersPreviewModalComponent_Host_0, {}, {}, []);
export { AppBannersPreviewModalComponentNgFactory as AppBannersPreviewModalComponentNgFactory };
