import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { AuthenticationService, AlertMessageService } from '@services';
import { AlertMessage, MessageMap } from '@models';
import { isEvoRedirectRequest, isEvoService, isToOldCanais } from '@utils/app-endpoints';

import { AbstractInterceptor } from './abstract.interceptor';


// tslint:disable-next-line:max-classes-per-file
@Injectable()
export class AuthInterceptor extends AbstractInterceptor {

  constructor(
    private readonly authService: AuthenticationService,
    private readonly alertMessageService: AlertMessageService,
  ) {
    super();
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (isEvoService(req.url)) {
      return;
    }

    if (isEvoRedirectRequest(req.url)) {
      const appToken = this.authService.getAppUserToken();

      if (isToOldCanais(req.url) && appToken) {
        return this.setRequestWithToken(req, next, appToken);
      }

      return this.authService.getAppToken().pipe(
        mergeMap((token: string) => this.setRequestWithToken(req, next, token)),
      );
    }

    const userToken = this.authService.getUserToken();

    if (userToken) {
      req = req.clone({
        setHeaders: {
          'X-Authorization': userToken,
        },
      });
    }

    return next.handle(req);
  }

  returning(res: HttpResponse<any>): HttpResponse<any> {
    if (res.status === 401) {
      this.alertMessageService.showToastr(AlertMessage.error(MessageMap.SEM_CONEXAO_INTERNET));
      this.authService.doLogout();
    } else if (res.status === 403) {
      this.alertMessageService.showToastr(AlertMessage.error(MessageMap.SEM_CONEXAO_INTERNET));
    }
    return res;
  }

  private setRequestWithToken(
    request: HttpRequest<unknown>,
    next: HttpHandler,
    token: string,
  ): Observable<HttpEvent<unknown>> {
    const newRequest = request.clone({
      setHeaders: { 'X-Authorization': token },
    });

    return next.handle(newRequest);
  }
}
