import { Directive, Attribute, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appMaxLength][formControlName],[appMaxLength][formControl],[appMaxLength][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MaxLengthValidatorDirective),
      multi: true,
    },
  ],
})
export class MaxLengthValidatorDirective implements Validator {
  constructor(@Attribute('appMaxLength') public appMaxLength: number) {}

  validate(control: AbstractControl): { [key: string]: any } {
    if (control.value && control.value.length > this.appMaxLength) {
      return { maxlength: true };
    }
    return null;
  }
}
