<div class="modal-content">
  <div class="modal-header">
    CONTATOS DO ALUNO
    <img src="./assets/icon_custom/18-p-fechar-b.svg" class="close-btn" (click)="closeModal()">
  </div>
  <div class="modal-body H4-LatoRegular-C7">
    <div class="main-container B1-LatoRegular-C7">
      <div class="mb-24">
        <div class="H5-LatoBold-C6">ALUNO</div>
        <div>{{data.name}}</div>
      </div>
      <div fxLayout fxLayout.lt-sm="column" fxLayoutAlign="space-between start">
        <div class="mb-24">
          <div class="H5-LatoBold-C6">TELEFONE RESIDENCIAL</div>
          <div>{{ homePhone.frontendFormattedNumber || '-'}}</div>
        </div>
        <div class="mb-24">
          <div class="H5-LatoBold-C6">TELEFONE COMERCIAL</div>
          <div>{{ workPhone.frontendFormattedNumber || '-'}}</div>
        </div>
        <div class="mb-24">
          <div class="H5-LatoBold-C6">CELULAR</div>
          <div>{{ cellPhone.frontendFormattedNumber || '-'}}</div>
        </div>
        <div><!-- flex spacer --></div>
      </div>
      <div class="H5-LatoBold-C6">CONTATOS DE EMERGÊNCIA</div>
      <div class="emergency-container">
        <div class="mb-4" *ngFor="let emergency of emergencyPhones">
          <div>{{ emergency.frontendFormattedNumber }} | {{emergency.contactName}} - {{emergency.kinshipTypeName}} </div>
        </div>
        <div *ngIf="data.listCustomerEmergencialPhones.length === 0">-</div>
      </div>
    </div>
  </div>
</div>
