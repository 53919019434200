import gql from 'graphql-tag';
import { EvoBaseParamsModel } from './evo.model';

export interface EvoMembershipParamsRequestModel extends EvoBaseParamsModel {
  idMembership: string;
}

export interface EvoMembershipResponseModel {
  accessBranches: AccessBranches[];
  additionalService: {
    value: number;
    idService?: number;
    name?: string;
  };
  description: string;
  displayName: string;
  differentials: DifferentialsMembershipResponseModel[];
  duration: number;
  durationType: string;
  entries: {
    entriesQuantity: number;
    idEntriesType: number;
    entriesTypeDescription: string;
  };
  idMembership: number;
  idBranch?: number;
  inactive: boolean;
  maxAmountInstallments: number;
  membershipType: string;
  nameMembership: string;
  onlineSalesObservations: string;
  urlSale: string;
  value: number;
}

interface DifferentialsMembershipResponseModel {
  order: number;
  title: string;
}

interface AccessBranches {
  idBranch: number;
  name: string;
}

export interface MembershipToHideModel {
  membershipName: string;
}

export class MembershipsToHideListGraphql {
  public static readonly querylistEvoMembershipsToHideOnPriceTable = gql`
    query {
      listEvoMembershipsToHideOnPriceTable {
        membershipName
      }
    }
  `;
}
