import { Apollo } from 'apollo-angular';
import { TeacherGraphql } from '@models/teacher';
import { JSONUtil } from '@utils/json-util';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
var TeacherService = /** @class */ (function () {
    function TeacherService(apollo) {
        this.apollo = apollo;
    }
    TeacherService.prototype.getActiveTeachersByGymUnit = function (gymUnitId) {
        return this.apollo.query({
            query: TeacherGraphql.queryListActiveTeachersByGymUnit,
            variables: {
                id: gymUnitId
            },
        })
            .pipe(map(JSONUtil.turnApolloMutable('listActiveTeachersByGymUnit')));
    };
    TeacherService.prototype.getTeachersByGymUnit = function (gymUnitId) {
        return this.apollo.query({
            query: TeacherGraphql.queryListTeachersByGymUnit,
            variables: {
                id: gymUnitId
            },
        })
            .pipe(map(JSONUtil.turnApolloMutable('listTeachersByGymUnit')));
    };
    TeacherService.prototype.updateAllTeachers = function (teacher) {
        return this.apollo
            .mutate({
            mutation: TeacherGraphql.parseToUpdateMutation(teacher),
        })
            .pipe(map(JSONUtil.turnApolloMutable()));
    };
    TeacherService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TeacherService_Factory() { return new TeacherService(i0.ɵɵinject(i1.Apollo)); }, token: TeacherService, providedIn: "root" });
    return TeacherService;
}());
export { TeacherService };
