/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./promotions-edit-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../promotions-form/promotions-form.component.ngfactory";
import * as i3 from "../promotions-form/promotions-form.component";
import * as i4 from "../../../../../core/services/dialog.service";
import * as i5 from "../../../../../core/services/gym.service";
import * as i6 from "@angular/forms";
import * as i7 from "../../../../../core/services/alert-message.service";
import * as i8 from "./promotions-edit-modal.component";
import * as i9 from "@angular/material/dialog";
var styles_PromotionsEditModalComponent = [i0.styles];
var RenderType_PromotionsEditModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PromotionsEditModalComponent, data: {} });
export { RenderType_PromotionsEditModalComponent as RenderType_PromotionsEditModalComponent };
export function View_PromotionsEditModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" EDITAR INFORMA\u00C7\u00D5ES DA PROMO\u00C7\u00C3O "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "close-btn"], ["src", "./assets/icon_custom/18-p-fechar-b.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-promotions-form", [], null, [[null, "promotionEmitter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("promotionEmitter" === en)) {
        var pd_0 = (_co.editPromotion($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PromotionsFormComponent_0, i2.RenderType_PromotionsFormComponent)), i1.ɵdid(6, 4308992, null, 0, i3.PromotionsFormComponent, [i4.DialogService, i5.GymService, i6.FormBuilder, i7.AlertMessageService], { promotionToEdit: [0, "promotionToEdit"], promotions: [1, "promotions"] }, { promotionEmitter: "promotionEmitter" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.promotionToEdit; var currVal_1 = _co.data.promotions; _ck(_v, 6, 0, currVal_0, currVal_1); }, null); }
export function View_PromotionsEditModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-promotions-edit-modal", [], null, null, null, View_PromotionsEditModalComponent_0, RenderType_PromotionsEditModalComponent)), i1.ɵdid(1, 114688, null, 0, i8.PromotionsEditModalComponent, [i9.MatDialogRef, i9.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PromotionsEditModalComponentNgFactory = i1.ɵccf("app-promotions-edit-modal", i8.PromotionsEditModalComponent, View_PromotionsEditModalComponent_Host_0, {}, {}, []);
export { PromotionsEditModalComponentNgFactory as PromotionsEditModalComponentNgFactory };
