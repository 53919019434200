import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'activePipe' })
export class ActivePipe implements PipeTransform {
  transform(allItems: any[], state: string) {
    if (state === 'actives') {
      return allItems.filter(hero => hero.active);
    } else if (state === 'inactives') {
      return allItems.filter(hero => !hero.active);
    } else {
      return allItems;
    }
  }
}
