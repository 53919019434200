import { Pipe, PipeTransform } from '@angular/core';
import * as removeAccents from 'remove-accents-diacritics';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, fieldToFilter: string): any[] {
    if (!searchText) {
      return items;
    }
    searchText = searchText.trim();
    searchText = removeAccents.remove(searchText);
    searchText = searchText.replace(/[\\^$.|?*+(){}[\]]/g, c => '\\' + c);

    const keysArray = fieldToFilter.split(',');
    const attributes = keysArray.map(key => key.split('.'));

    return (items || []).filter((item) => {
      return attributes.some(element => {
        return this.getNested(item, ...element) &&
        new RegExp(searchText, 'gi').test(removeAccents.remove(this.getNested(item, ...element)));
      });
    });
  }

  private getNested(object, ...args) {
    return args.reduce((obj, level) => obj && obj[level], object);
  }

}
