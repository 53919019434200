export enum Page {
  Activities         = 'Activities',
  AdhesionTerm       = 'AdhesionTerm',
  AppBanners         = 'AppBanners',
  Banners            = 'Banners',
  Conveniences       = 'Conveniences',
  GymName            = 'GymName',
  InformativeGymUnit = 'InformativeGymUnit',
  InformativeHolding = 'InformativeHolding',
  InfoServices       = 'InfoServices',
  Location           = 'Location',
  PageHeader         = 'PageHeader',
  Periodization      = 'Periodization',
  Photos             = 'Photos',
  PlanDetails        = 'PlanDetails',
  PlanPriceList      = 'PlanPriceList',
  Promotions         = 'Promotions',
  Schedule           = 'Schedule',
  SocialMedia        = 'SocialMedia',
  Tags               = 'Tags',
  Teachers           = 'Teachers',
  UserPermissions    = 'UserPermissions',
  WorkingPeriod      = 'WorkingPeriod',
}

export interface PageBackoffice {
    name: Page;
    id: number;
}
