import * as tslib_1 from "tslib";
import { NativeDateAdapter } from '@angular/material';
var SUPPORTS_INTL_API = typeof Intl !== 'undefined';
var CustomDateAdapter = /** @class */ (function (_super) {
    tslib_1.__extends(CustomDateAdapter, _super);
    function CustomDateAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.useUtcForDisplay = true;
        return _this;
    }
    CustomDateAdapter.prototype.parse = function (value) {
        if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
            var str = value.split('/');
            var year = Number(str[2]);
            var month = Number(str[1]) - 1;
            var date = Number(str[0]);
            return new Date(year, month, date);
        }
        var timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    };
    // to be removed when mmalerba merge the mods in the next beta
    CustomDateAdapter.prototype.format = function (date, displayFormat) {
        if (SUPPORTS_INTL_API) {
            if (this.useUtcForDisplay) {
                date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
                displayFormat = Object.assign({}, displayFormat, { timeZone: 'utc' });
            }
            var dtf = new Intl.DateTimeFormat(this.locale, displayFormat);
            return dtf.format(date).replace(/[\u200e\u200f]/g, '');
        }
    };
    return CustomDateAdapter;
}(NativeDateAdapter));
export { CustomDateAdapter };
