import * as tslib_1 from "tslib";
import gql from 'graphql-tag';
var PlaceGraphql = /** @class */ (function () {
    function PlaceGraphql() {
    }
    PlaceGraphql.queryListPlacesByGymUnit = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n    query($id: Long!) {\n      listPlacesByGymUnit(id: $id) {\n        id\n        name\n        description\n        placeType\n      }\n    }\n  "], ["\n    query($id: Long!) {\n      listPlacesByGymUnit(id: $id) {\n        id\n        name\n        description\n        placeType\n      }\n    }\n  "])));
    return PlaceGraphql;
}());
export { PlaceGraphql };
var templateObject_1;
