import * as tslib_1 from "tslib";
import { GraphqlParser } from '@utils/graphql-parser';
import gql from 'graphql-tag';
var PromotionGraphql = /** @class */ (function () {
    function PromotionGraphql() {
    }
    PromotionGraphql.parseToCreateMutation = function (promotions) {
        var body = '';
        promotions.forEach(function (promotion, index) {
            var newPromotion = JSON.parse(JSON.stringify(promotion));
            var gyms = newPromotion.gymUnities.map(function (g) { return g.id; });
            delete newPromotion.gymUnities;
            delete newPromotion.id;
            body += "createPromotion" + index + ": createPromotion(\n        gymUnitIds: [" + gyms.join(',') + "],\n        promotion: {\n          " + GraphqlParser.parseJSONGraphQL(newPromotion, true) + "\n          }) {\n              id\n              errors {\n              field\n              message\n          }\n      }\n";
        });
        return gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["mutation { ", " }"], ["mutation { ", " }"])), body);
    };
    PromotionGraphql.parseToUpdateMutation = function (promotions) {
        var body = '';
        promotions.forEach(function (promotion, index) {
            var gyms = promotion.gymUnities.map(function (g) { return g.id; });
            var newPromotion = JSON.parse(JSON.stringify(promotion));
            delete newPromotion.gymUnities;
            body += "updatePromotion" + index + ": updatePromotion(\n        id: " + promotion.id + ",\n        gymUnitIds: [" + gyms.join(',') + "],\n        promotion: {\n          " + GraphqlParser.parseJSONGraphQL(newPromotion, true) + "\n          }) {\n              id\n              errors {\n              field\n              message\n          }\n      }\n";
        });
        return gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["mutation { ", " }"], ["mutation { ", " }"])), body);
    };
    PromotionGraphql.queryPromotion = gql(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n    query($id: Long!) {\n      promotion(id: $id) {\n        id\n        thumbnailText\n        purchaseTitle\n        purchaseText\n        initialDate\n        finalDate\n        gymUnities {\n          id\n          name\n        }\n      }\n    }\n  "], ["\n    query($id: Long!) {\n      promotion(id: $id) {\n        id\n        thumbnailText\n        purchaseTitle\n        purchaseText\n        initialDate\n        finalDate\n        gymUnities {\n          id\n          name\n        }\n      }\n    }\n  "])));
    PromotionGraphql.queryListPromotion = gql(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n    query($max: Int!, $offset: Int!) {\n      listPromotions(max: $max, offset: $offset) {\n        results {\n          id\n          thumbnailText\n          purchaseTitle\n          purchaseText\n          initialDate\n          finalDate\n          gymUnities {\n            id\n            name\n            address {\n              id\n              city {\n                id\n                state {\n                  id\n                  name\n                  initials\n                }\n              }\n            }\n          }\n        }\n        totalCount\n      }\n    }\n  "], ["\n    query($max: Int!, $offset: Int!) {\n      listPromotions(max: $max, offset: $offset) {\n        results {\n          id\n          thumbnailText\n          purchaseTitle\n          purchaseText\n          initialDate\n          finalDate\n          gymUnities {\n            id\n            name\n            address {\n              id\n              city {\n                id\n                state {\n                  id\n                  name\n                  initials\n                }\n              }\n            }\n          }\n        }\n        totalCount\n      }\n    }\n  "])));
    PromotionGraphql.mutationCreatePromotion = gql(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["\n    mutation CreatePromotion($gymUnitIds: [Long!], $promotion: PromotionUpdate!) {\n      createPromotion(gymUnitIds: $gymUnitIds, promotion: $promotion) {\n        id\n        errors {\n          field\n          message\n        }\n      }\n    }\n  "], ["\n    mutation CreatePromotion($gymUnitIds: [Long!], $promotion: PromotionUpdate!) {\n      createPromotion(gymUnitIds: $gymUnitIds, promotion: $promotion) {\n        id\n        errors {\n          field\n          message\n        }\n      }\n    }\n  "])));
    PromotionGraphql.mutationUpdatePromotion = gql(templateObject_6 || (templateObject_6 = tslib_1.__makeTemplateObject(["\n    mutation UpdatePromotion($id: Long!, $gymUnitIds: [Long!], $promotion: PromotionUpdate!) {\n      updatePromotion(id: $id, gymUnitIds: $gymUnitIds, promotion: $promotion) {\n        id\n        errors {\n          field\n          message\n        }\n      }\n    }\n  "], ["\n    mutation UpdatePromotion($id: Long!, $gymUnitIds: [Long!], $promotion: PromotionUpdate!) {\n      updatePromotion(id: $id, gymUnitIds: $gymUnitIds, promotion: $promotion) {\n        id\n        errors {\n          field\n          message\n        }\n      }\n    }\n  "])));
    PromotionGraphql.mutationDeletePromotion = gql(templateObject_7 || (templateObject_7 = tslib_1.__makeTemplateObject(["\n    mutation DeletePromotion($id: Long!) {\n      deletePromotion(id: $id) {\n        success\n        errors {\n          field\n          message\n        }\n      }\n    }\n  "], ["\n    mutation DeletePromotion($id: Long!) {\n      deletePromotion(id: $id) {\n        success\n        errors {\n          field\n          message\n        }\n      }\n    }\n  "])));
    return PromotionGraphql;
}());
export { PromotionGraphql };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
