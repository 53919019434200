<div class="modal-content">
  <div class="modal-header">
    <ng-container *ngIf="data.modalTitle">{{ data.modalTitle }}</ng-container>
    <img src="./assets/icon_custom/18-p-fechar-b.svg" class="close-btn" (click)="closeModal()">
  </div>
  <div *ngIf="data.modalContent" class="modal-body H4-LatoRegular-C7">
      {{ data.modalContent }}
  </div>
  <div class="generic-modal-buttons">
    <button type="button" *ngIf="data.sButtonText" class="bo-btn bo-secundary-btn" (click)="closeModal()">{{ data.sButtonText }}</button>
    <button type="button" *ngIf="data.pButtonText" class="bo-btn bo-primary-btn" (click)="takeAction()">{{ data.pButtonText }}</button>
  </div>
</div>
