export const isProduction = true;
export const googlemapsapikey = 'AIzaSyDT8u2W9mcuJ-uESWb9ZAcd6sd6hkmAMVs';
export const environment = Object.freeze({
  production: true,
  homolog: true,
  brand: 'bt',

  baseUrl: 'https://services.homolog.bodytech.company:8002/api',
  portal: 'https://homolog.bodytech.company/api',

  services: {
    baseUrl: 'https://services.homolog.bodytech.company',
    evoBaseUrl: 'https://evo-integracao.w12app.com.br',
  },

  sentry: {
    dsn: 'https://910ce90f0e7e40fe8f185ad4d9dbf996@sentry.dev.bodytech.company/9',
    enabled: true,
    environment: 'homolog',
    release: 'bo-btportal-__SENTRY_RELEASE__',
    attachStacktrace: true,
  },

  recaptchaKey: '',

  s3buckets: {
    images: 'https://images.homolog.bodytech.company',
    medias: 'https://medias.homolog.bodytech.company',
    terms: 'https://terms.homolog.bodytech.company'
  },

  s3Region: 'us-east-1',
  s3Environment: 'homolog.bodytech.company'

});
