import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppBanner } from '@models/app-banner';

@Component({
  selector: 'app-app-banners-preview-modal',
  templateUrl: './app-banners-preview-modal.component.html',
  styleUrls: ['./app-banners-preview-modal.component.scss']
})
export class AppBannersPreviewModalComponent implements OnInit {

  banner: AppBanner;

  constructor(
    private dialogRef: MatDialogRef<AppBannersPreviewModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
    ) { }

  ngOnInit() {
    this.banner = this.data.banner;
  }

  closeModal() {
    this.dialogRef.close();
  }

}
