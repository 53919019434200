import * as tslib_1 from "tslib";
import gql from 'graphql-tag';
export var PhoneType;
(function (PhoneType) {
    PhoneType[PhoneType["HOME"] = 1] = "HOME";
    PhoneType[PhoneType["WORK"] = 2] = "WORK";
    PhoneType[PhoneType["CELL"] = 3] = "CELL";
})(PhoneType || (PhoneType = {}));
var AppointmentLiveClassGraphql = /** @class */ (function () {
    function AppointmentLiveClassGraphql() {
    }
    AppointmentLiveClassGraphql.queryListAllAppointmentLiveClassByTeacher = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n    query($teacherId: String!) {\n      listAllAppointmentLiveClassByTeacher(teacherId: $teacherId) {\n        id\n        clientName\n        appointmentDate\n        formattedInitialDate\n        formattedFinalDate\n        appointmentCanceledDate\n        liveClassUrl\n        clientRegistration\n        presence\n        teacherAvailableHour {\n          availableHour {\n            initialHour\n            finalHour\n            initialMinute\n            finalMinute\n            formattedInitialTime\n            formattedFinalTime\n          }\n        }\n      }\n    }\n  "], ["\n    query($teacherId: String!) {\n      listAllAppointmentLiveClassByTeacher(teacherId: $teacherId) {\n        id\n        clientName\n        appointmentDate\n        formattedInitialDate\n        formattedFinalDate\n        appointmentCanceledDate\n        liveClassUrl\n        clientRegistration\n        presence\n        teacherAvailableHour {\n          availableHour {\n            initialHour\n            finalHour\n            initialMinute\n            finalMinute\n            formattedInitialTime\n            formattedFinalTime\n          }\n        }\n      }\n    }\n  "])));
    AppointmentLiveClassGraphql.queryListCustomerPhones = gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n    query($registration: BigInteger!) {\n      listCustomerPhones(registration: $registration) {\n        id\n        ddi\n        ddd\n        phoneNumber\n        phoneType\n      }\n      listCustomerEmergencialPhones(registration: $registration) {\n        id\n        ddi\n        ddd\n        phoneNumber\n        kinshipTypeName\n        contactName\n        mainPhone\n      }\n    }\n  "], ["\n    query($registration: BigInteger!) {\n      listCustomerPhones(registration: $registration) {\n        id\n        ddi\n        ddd\n        phoneNumber\n        phoneType\n      }\n      listCustomerEmergencialPhones(registration: $registration) {\n        id\n        ddi\n        ddd\n        phoneNumber\n        kinshipTypeName\n        contactName\n        mainPhone\n      }\n    }\n  "])));
    AppointmentLiveClassGraphql.mutationUpdatePresence = gql(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n    mutation ($id: Long!, $registration: Long!, $status: Boolean!) {\n      updateAppointmentLiveClassPresence(id: $id, registration: $registration, status: $status) {\n        presence\n        errors {\n          field\n          message\n        }\n      }\n    }\n  "], ["\n    mutation ($id: Long!, $registration: Long!, $status: Boolean!) {\n      updateAppointmentLiveClassPresence(id: $id, registration: $registration, status: $status) {\n        presence\n        errors {\n          field\n          message\n        }\n      }\n    }\n  "])));
    return AppointmentLiveClassGraphql;
}());
export { AppointmentLiveClassGraphql };
var templateObject_1, templateObject_2, templateObject_3;
