<div class="modal-content">
  <div class="modal-header">
    AUDITAR AULA - {{ data.id }}
    <img src="./assets/icon_custom/18-p-fechar-b.svg" class="close-btn" (click)="closeModal()">
  </div>
  <div class="modal-body H4-LatoRegular-C7">
    <ng-container *ngIf="data.zoom?.participants && data.zoom.participants.length > 0; else empty">
      <div class="main-container participant-info mb-12" *ngFor="let participant of data.zoom.participants">
        <div class="mb-16 info-block" *ngFor="let entry of participant | keyvalue : preserveOriginalOrder">
          <div class="H5-LatoBold-C6 uppercase">{{ keyNames[entry.key] || entry.key }}</div>
          <ng-container [ngSwitch]="true">
            <div *ngSwitchCase="isDateField.includes(entry.key)">
              {{ entry.value | date:'dd/MM/yyyy HH:mm:ss' }}
            </div>
            <div *ngSwitchCase="isBooleanField.includes(entry.key)">
              {{ entry.value ? 'Sim' : 'Não' }}
            </div>
            <div *ngSwitchDefault [innerHTML]="entry.value || '-'"></div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-template #empty>
      <div class="main-container mb-12">
        <div fxLayout="column" class="mt-40 mb-40" fxLayoutAlign="center">
          <img src="../../../assets/icon_custom/bt/empty-geral-2.svg">
          <p class="H4-LatoRegular-C7 text-center mt-8 mb-0">Não há informações para exibir.</p>
        </div>
      </div>
    </ng-template>
  </div>
</div>
