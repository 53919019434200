import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { items } from 'data/side-bar-itens';
import { Item, PageBackoffice, Page } from '@models';
import { BrandService, StorageService } from '@services';
import { AppConstants } from '@utils/app-constants';

@Injectable()
export class BrandGuard implements CanActivateChild, CanActivate {
  items: Item[] = items;

  constructor(
    private readonly brandService: BrandService,
    private readonly router: Router,
    private readonly storageService: StorageService,
  ) {}

  canActivateChild = this.canActivate;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const pagesBackoffice = this.storageService.get(
      AppConstants.STOR_LOGIN_DATA,
    ).roleBackoffice.pagesBackoffice.map((page: PageBackoffice) => page.name);
    const searchItem = route.data.page;

    if (!this.hasBrand(items, searchItem, state.url)) {
      this.router.navigate(['/pagina-nao-encontrada']);
      return false;
    }
    return true;
  }

  private hasBrand(
    itemsToSearch: Item[],
    valueSearch: Page,
    url?: string,
  ): boolean {
    for (const item of itemsToSearch) {
      if (item.page === valueSearch) {
        if (item.children) {
          return item.children.some(
            v => v.path === url && this.hasIncludeBrand(v),
          );
        }
        return this.hasIncludeBrand(item);
      }
      if (item.children && this.hasBrand(item.children, valueSearch, url)) {
        return true;
      }
    }
    return false;
  }

  private hasIncludeBrand(item: Item): boolean {
    const brand = this.brandService.getBrand();
    if (item.brands) {
      return item.brands.includes(brand);
    }
    return false;
  }
}
