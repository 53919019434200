export class Masks {
    public static readonly Mask = {
        hour: (raw: string) => {
          const pattern = [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];
          const rawArray = Array.from(raw);

          if (rawArray[0] && rawArray[0] === '2') {
            pattern[1] = /[0-3]/;
          }

          return pattern;
        },
        dateFull: (raw: string) => {
          const pattern = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[1-2]/, /[0-9]/, /[0-9]/, /[0-9]/];
          const rawArray = Array.from(raw);

          if (rawArray[0] && rawArray[0] === '3') {
            pattern[1] = /[0-1]/;
          } else if (rawArray[0] && rawArray[0] === '0') {
            pattern[1] = /[1-9]/;
          }

          if (rawArray[3] && rawArray[3] === '1') {
            pattern[4] = /[0-2]/;
          } else if (rawArray[3] && rawArray[3] === '0') {
            pattern[4] = /[1-9]/;
          }

          if (rawArray[6] && rawArray[6] === '1') {
            pattern[7] = /[9]/;
          }

          return pattern;
        }
    };
}
