import { map, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import { GenericModalComponent } from '@sharedcomponents/generic-modal/generic-modal.component';
import { MatDialog } from '@angular/material';
import { MessageMap } from '@models';
import { DialogService } from './dialog.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "./dialog.service";
var DirtyCheckService = /** @class */ (function () {
    function DirtyCheckService(dialog, dialogService) {
        this.dialog = dialog;
        this.dialogService = dialogService;
        this.dirty = new BehaviorSubject(false);
        this.isDirty$ = this.dirty.asObservable();
    }
    DirtyCheckService.prototype.markAsDirty = function () {
        this.dirty.next(true);
    };
    DirtyCheckService.prototype.markAsClean = function () {
        this.dirty.next(false);
    };
    DirtyCheckService.prototype.isDirty = function () {
        return this.isDirty$;
    };
    DirtyCheckService.prototype.confirmDialog = function () {
        var _this = this;
        return this.isDirty$.pipe(switchMap(function (dirty) {
            if (dirty === false) {
                return of(true);
            }
            _this.dialogService.openDialog(GenericModalComponent, {
                width: '670px',
                data: {
                    modalTitle: 'ALTERAÇÕES NÃO SALVAS',
                    modalContent: MessageMap.UNSAVED_CHANGES,
                    pButtonText: 'SAIR DA PÁGINA',
                    sButtonText: 'PERMANECER NA PÁGINA',
                },
            });
            return _this.dialogService.afterClosed().pipe(map(function (result) {
                if (result) {
                    _this.markAsClean();
                }
                return result;
            }));
        }), take(1));
    };
    DirtyCheckService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DirtyCheckService_Factory() { return new DirtyCheckService(i0.ɵɵinject(i1.MatDialog), i0.ɵɵinject(i2.DialogService)); }, token: DirtyCheckService, providedIn: "root" });
    return DirtyCheckService;
}());
export { DirtyCheckService };
