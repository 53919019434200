import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { items } from 'data/side-bar-itens';
import { PageBackoffice, Item } from '@models';
import { StorageService } from '@services';
import { AppConstants } from '@utils/app-constants';

@Injectable()
export class RoleGuard implements CanActivateChild, CanActivate {
  items: Item[] = items;

  constructor(
    private readonly router: Router,
    private readonly storageService: StorageService,
  ) {}

  canActivateChild = this.canActivate;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const pagesBackoffice = this.storageService.get(
      AppConstants.STOR_LOGIN_DATA,
    ).roleBackoffice.pagesBackoffice.map((page: PageBackoffice) => page.name);
    const searchItem = route.data.page;

    // * Check permission to access the page
    if (!pagesBackoffice.includes(searchItem)) {
      this.router.navigate(['/pagina-nao-encontrada']);
      return false;
    }
    return true;
  }
}
