import gql from 'graphql-tag';

export interface Place {
  id: number;
  name: string;
  description?: string;
  placeType?: string;

  __typename?: string;
}

export class PlaceGraphql {

  public static readonly queryListPlacesByGymUnit = gql`
    query($id: Long!) {
      listPlacesByGymUnit(id: $id) {
        id
        name
        description
        placeType
      }
    }
  `;

}
