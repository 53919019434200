import gql from 'graphql-tag';
import { Activity } from './activity';
import { Error } from './response-error';

export interface PeriodizationFilterResult {
  activityId: number;
  month: number;
  year: number;
  activityName?: string;
}

export interface PeriodizationFormData {
  periodizationDomainList: PeriodizationDomainList[];
  dayPeriodization: PeriodizationDomain[];
}

export interface OneDayPeriodizationItem {
  dayOfAnotherMonth: boolean;
  periodizationItemDate: Date;
  periodizationItems: PeriodizationDomain[];
  intensityColorItem: PeriodizationDomain;
  mixTraining: PeriodizationDomain;
  isSelected: boolean;
}

export interface PeriodizationDomainList {
  id: number;
  name: string;
  periodizationDomainVariables: PeriodizationDomainVariable[];
}

// models to update mutation
export interface PeriodizationUpdate {
  id?: number;
  periodizationMonth: number;
  periodizationYear: number;
  published: boolean;
  periodizationItemsDates?: PeriodizationItemDate[];
  errors?: Error[];
}
export interface PeriodizationItemUpdate {
  periodizationItemDate: Date;
  periodizationDomainId: number;
  periodizationDomainVariableId: number;
}

// interfaces from backend service return
export interface PeriodizationDomainVariable {
  id: number;
  name: string;
  description: string;
  intensityColor: string;

  // created just for logical usage in modal of select domainVariables. This attribute is not returned in the service
  nameAndDescription?: string;
}

export interface PeriodizationDomain {
  id: number;
  name: string;
  periodizationDomainVariable: PeriodizationDomainVariable;
}

export interface PeriodizationItem {
  id: number;
  periodizationDomain: PeriodizationDomain;
}

export interface PeriodizationItemDate {
  periodizationItemDate: string;
  periodizationItems: PeriodizationItem[];

  // Front use only
  intensityColor?: string;
  domainsName?: string[];
}

export interface Periodization {
  id?: number;
  activity?: Activity;
  periodizationMonth?: number;
  periodizationYear?: number;
  published?: boolean;
  periodizationItemsDates?: PeriodizationItemDate[];
}

export class PeriodizationGraphql {

  public static readonly queryGetPeriodizationByActivityMonthAndYear = gql`
    query($activityId: Long!, $month: Int!, $year: Int!) {
      getPeriodizationByActivityMonthAndYear(activityId: $activityId, month: $month, year: $year) {
        id
        activity {
          id
          name
        }
        periodizationMonth
        periodizationYear
        published
        periodizationItemsDates {
          periodizationItemDate
          periodizationItems {
            id
            periodizationDomain {
              id
              name
              periodizationDomainVariable {
                id
                name
                description
                intensityColor
              }
            }
          }
        }
      }
    }
  `;

  public static readonly queryListPeriodizationDomainsByActivity = gql`
    query($id: Long!) {
      listPeriodizationDomainsByActivity(id: $id) {
        id
        name
        periodizationDomainVariables {
          id
          name
          description
          intensityColor
        }
      }
    }
  `;

  public static readonly mutationAssociatePeriodizationToActivity = gql`
    mutation AssociatePeriodizationToActivity(
      $activityId: Long!, $periodization: PeriodizationUpdate!, $periodizationItems: [PeriodizationItemUpdate!]) {
      associatePeriodizationToActivity(activityId: $activityId, periodization: $periodization, periodizationItems: $periodizationItems) {
        id
        periodizationMonth
        periodizationYear
        published
        periodizationItemsDates {
          periodizationItemDate
          periodizationItems {
            id
            periodizationDomain {
              id
              name
              periodizationDomainVariable {
                id
                name
                description
                intensityColor
              }
            }
          }
        }
        errors {
          field
          message
        }
      }
    }
  `;
}
