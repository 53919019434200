<div class="modal-content">
  <div class="modal-header">
    EDITAR INFORMAÇÕES DA PROMOÇÃO
    <img src="./assets/icon_custom/18-p-fechar-b.svg" class="close-btn" (click)="closeModal()">
  </div>
  <div class="modal-body">
    <app-promotions-form [promotionToEdit]="data.promotionToEdit" [promotions]="data.promotions"
      (promotionEmitter)="editPromotion($event)"></app-promotions-form>
  </div>
</div>
