import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { evoBack } from '@utils/app-endpoints';
var EvoGymService = /** @class */ (function () {
    function EvoGymService(http) {
        this.http = http;
    }
    EvoGymService.prototype.mapEvoContactInfoToLegacy = function (legacyContactInfo, evoContactInfo) {
        var address = tslib_1.__assign({}, legacyContactInfo.address, { city: tslib_1.__assign({}, legacyContactInfo.address.city, { name: evoContactInfo.city, state: tslib_1.__assign({}, legacyContactInfo.address.city.state, { initials: evoContactInfo.stateShort, name: evoContactInfo.state }) }), publicPlace: tslib_1.__assign({}, legacyContactInfo.address.publicPlace, { name: '' }), neighborhood: evoContactInfo.neighborhood, number: evoContactInfo.number, street: evoContactInfo.address, zipCode: evoContactInfo.zipCode });
        var phones = [tslib_1.__assign({}, legacyContactInfo.phones[0], { ddd: +evoContactInfo.telephone.slice(0, 2), number: evoContactInfo.telephone.slice(2) })];
        return tslib_1.__assign({}, legacyContactInfo, { name: evoContactInfo.name, portalName: evoContactInfo.name, businessName: evoContactInfo.internalName, cnpj: evoContactInfo.cnpj, address: address,
            phones: phones });
    };
    EvoGymService.prototype.getGymsContactInfo = function (params) {
        return this.http.get(evoBack("/api/v1/configuration" /* Configuration */), { params: tslib_1.__assign({}, params) });
    };
    return EvoGymService;
}());
export { EvoGymService };
