import * as tslib_1 from "tslib";
import gql from 'graphql-tag';
var PeriodizationGraphql = /** @class */ (function () {
    function PeriodizationGraphql() {
    }
    PeriodizationGraphql.queryGetPeriodizationByActivityMonthAndYear = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n    query($activityId: Long!, $month: Int!, $year: Int!) {\n      getPeriodizationByActivityMonthAndYear(activityId: $activityId, month: $month, year: $year) {\n        id\n        activity {\n          id\n          name\n        }\n        periodizationMonth\n        periodizationYear\n        published\n        periodizationItemsDates {\n          periodizationItemDate\n          periodizationItems {\n            id\n            periodizationDomain {\n              id\n              name\n              periodizationDomainVariable {\n                id\n                name\n                description\n                intensityColor\n              }\n            }\n          }\n        }\n      }\n    }\n  "], ["\n    query($activityId: Long!, $month: Int!, $year: Int!) {\n      getPeriodizationByActivityMonthAndYear(activityId: $activityId, month: $month, year: $year) {\n        id\n        activity {\n          id\n          name\n        }\n        periodizationMonth\n        periodizationYear\n        published\n        periodizationItemsDates {\n          periodizationItemDate\n          periodizationItems {\n            id\n            periodizationDomain {\n              id\n              name\n              periodizationDomainVariable {\n                id\n                name\n                description\n                intensityColor\n              }\n            }\n          }\n        }\n      }\n    }\n  "])));
    PeriodizationGraphql.queryListPeriodizationDomainsByActivity = gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n    query($id: Long!) {\n      listPeriodizationDomainsByActivity(id: $id) {\n        id\n        name\n        periodizationDomainVariables {\n          id\n          name\n          description\n          intensityColor\n        }\n      }\n    }\n  "], ["\n    query($id: Long!) {\n      listPeriodizationDomainsByActivity(id: $id) {\n        id\n        name\n        periodizationDomainVariables {\n          id\n          name\n          description\n          intensityColor\n        }\n      }\n    }\n  "])));
    PeriodizationGraphql.mutationAssociatePeriodizationToActivity = gql(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n    mutation AssociatePeriodizationToActivity(\n      $activityId: Long!, $periodization: PeriodizationUpdate!, $periodizationItems: [PeriodizationItemUpdate!]) {\n      associatePeriodizationToActivity(activityId: $activityId, periodization: $periodization, periodizationItems: $periodizationItems) {\n        id\n        periodizationMonth\n        periodizationYear\n        published\n        periodizationItemsDates {\n          periodizationItemDate\n          periodizationItems {\n            id\n            periodizationDomain {\n              id\n              name\n              periodizationDomainVariable {\n                id\n                name\n                description\n                intensityColor\n              }\n            }\n          }\n        }\n        errors {\n          field\n          message\n        }\n      }\n    }\n  "], ["\n    mutation AssociatePeriodizationToActivity(\n      $activityId: Long!, $periodization: PeriodizationUpdate!, $periodizationItems: [PeriodizationItemUpdate!]) {\n      associatePeriodizationToActivity(activityId: $activityId, periodization: $periodization, periodizationItems: $periodizationItems) {\n        id\n        periodizationMonth\n        periodizationYear\n        published\n        periodizationItemsDates {\n          periodizationItemDate\n          periodizationItems {\n            id\n            periodizationDomain {\n              id\n              name\n              periodizationDomainVariable {\n                id\n                name\n                description\n                intensityColor\n              }\n            }\n          }\n        }\n        errors {\n          field\n          message\n        }\n      }\n    }\n  "])));
    return PeriodizationGraphql;
}());
export { PeriodizationGraphql };
var templateObject_1, templateObject_2, templateObject_3;
