import { BehaviorSubject, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import * as i0 from "@angular/core";
var BlockUiService = /** @class */ (function () {
    function BlockUiService() {
        this.counter = 0;
        this.emitStats = new BehaviorSubject({ action: 0 /* NOOP */ });
        this.emitStatus = this.emitStats.pipe(debounce(function (status) {
            if (status.action === 1 /* START */ || status.action === 2 /* START_CUSTOM_MESSAGE */) {
                return timer(0);
            }
            else {
                return timer(200);
            }
        }));
    }
    BlockUiService.prototype.increment = function (message) {
        this.counter += 1;
        var status = null;
        if (message) {
            status = { action: 2 /* START_CUSTOM_MESSAGE */, message: message };
        }
        else {
            status = { action: 1 /* START */ };
        }
        this.emitStats.next(status);
    };
    BlockUiService.prototype.decrement = function () {
        this.counter -= 1;
        if (this.counter < 0) {
            this.counter = 0;
        }
        if (this.counter === 0) {
            this.emitStats.next({ action: 3 /* STOP */ });
        }
    };
    BlockUiService.prototype.clear = function () {
        this.counter = 0;
        this.emitStats.next({ action: 3 /* STOP */ });
    };
    BlockUiService.prototype.network_error = function () {
        this.counter = 0;
        this.emitStats.next({ action: 4 /* NETWORK_ERROR */ });
    };
    BlockUiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BlockUiService_Factory() { return new BlockUiService(); }, token: BlockUiService, providedIn: "root" });
    return BlockUiService;
}());
export { BlockUiService };
