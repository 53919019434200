import { Injectable, Input, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ScrollService } from './scroll.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ComponentType } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  private dialogModal: any;

  constructor(
    private readonly dialog: MatDialog,
    private readonly scrollService: ScrollService
  ) { }

  openDialog<T, D = any, R = any>(modalComponent: ComponentType<T> | TemplateRef<T>, inputData: MatDialogConfig<D>) {
    if (this.scrollService.getHostListenerSticker() || this.scrollService.getStackLength() > 0) {
    this.scrollService.setSticky(true);
    }
    this.dialogModal = this.dialog.open(modalComponent, inputData);
  }

  afterClosed(): Observable<any> {
    return this.dialogModal.afterClosed().pipe(
      tap(() => setTimeout(() => {
        if (this.scrollService.getHostListenerSticker() || this.scrollService.getStackLength() > 0) {
          this.scrollService.setSticky(false);
        }
      }))
    );
  }
}
