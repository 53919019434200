import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService, AlertMessageService, StorageService, BlockUiService, Brand, BrandService } from '@services';
import { LoginResponse, BlockUIAction, AlertMessage, MessageMap } from '@models';
import { Router } from '@angular/router';
import { AppConstants } from '@utils/app-constants';
import { FormUtil } from '@utils/form-util';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../login/login.component.scss', './login.component.scss']
})
export class LoginComponent implements OnInit {
  brand: Brand;

  public loginControl: FormGroup;
  public portalName: string;

  constructor(
    private readonly alertMessageService: AlertMessageService,
    private readonly authenticationService: AuthenticationService,
    private readonly blockUiService: BlockUiService,
    private readonly brandService: BrandService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly storageService: StorageService
  ) {
    this.brand = this.brandService.getBrand();
  }

  ngOnInit() {
    this.portalName = AppConstants.PORTAL_NAME;
    this.loginControl = this.formBuilder.group({
      username: ['', [Validators.required, Validators.min(4)]],
      password: ['', Validators.required],
    });
  }


  public doLogin() {
    FormUtil.touchForm(this.loginControl);

    if (this.loginControl.invalid) {
      return;
    }

    this.authenticationService.authenticate(this.loginControl.value)
      .subscribe(
        (loginResponse: LoginResponse) => {
          this.authenticationService.doLogin(loginResponse);
          this.router.navigate(['/']);
          this.storageService.clearItem(AppConstants.STOR_SELECTED_GYM);
        },
        err => {
          if (err.status === 0) {
            this.alertMessageService.showToastr(AlertMessage.error(MessageMap.SEM_CONEXAO_INTERNET));
          } else {
            this.alertMessageService.showToastr(err);
          }
          this.blockUiService.decrement();
        }
      );
  }
}
