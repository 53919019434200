import gql from 'graphql-tag';
import { Activity } from '@models';
import { GymUnit } from './gym-unit';

export interface Teacher {
  id: number;
  name: string;
  reducedName?: string;
  teacherType?: string;
  registration?: number;
  cpf?: number;
  foreigner?: string;
  birthDate?: string;
  gender?: string;
  foreignerDocument?: string;
  mainEmail?: string;
  active?: boolean;

  dirty: boolean;

  activities?: Activity[];

  gymUnitTeachers: [];
}

export class TeacherGraphql {

  public static readonly queryListActiveTeachersByGymUnit = gql`
    query($id: Long!) {
      listActiveTeachersByGymUnit(id: $id) {
        id
        name
        reducedName
      }
    }
  `;

  public static readonly queryListTeachersByGymUnit = gql`
    query($id: Long!) {
      listTeachersByGymUnit(id: $id) {
        id
        name
        reducedName
        active
        activities {
          id
          name
          activityGroup {
            id
            name
          }
        }
        gymUnitTeachers {
          gymUnit {
            name
            address {
              street
            }
          }
        }
      }
    }
  `;

  public static parseToUpdateMutation(teachers: Teacher[]): any {
    let body = '';
    teachers.forEach((teacher, index) => {
      const teacherId = teacher.id;
      const activitiesIds = teacher.activities.map(activity => activity.id);

      body += `associateActivitiesToTeacher${index}: associateActivitiesToTeacher(
        teacherId: "${teacherId}",
        activitiesIds: [${activitiesIds.join(',')}]
        ) {
              id
              errors {
              field
              message
          }
      }\n`;
    });

    return gql`mutation { ${body} }`;
  }
}
