import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { mergeMap, map } from 'rxjs/operators';
import { EndpointsService } from './endpoints.service';
import { BlockUiService } from './block-ui.service';
import { environment } from '@env/environment';
var UploadService = /** @class */ (function () {
    function UploadService(http, endpointsService, blockUiService) {
        this.http = http;
        this.endpointsService = endpointsService;
        this.blockUiService = blockUiService;
        this.policySettings = { region: environment.s3Region, bucket: null };
    }
    UploadService.prototype.getS3BucketUrl = function () {
        var _a = this.policySettings, region = _a.region, bucket = _a.bucket;
        return "https://s3.dualstack." + region + ".amazonaws.com/" + bucket;
    };
    // tslint:disable-next-line:max-line-length
    UploadService.prototype.uploadFile = function (files, bucketPath, blockUi, bucketName, gymSlug) {
        var _this = this;
        if (blockUi === void 0) { blockUi = true; }
        return this.generateS3Policy(files.length, bucketName).pipe(mergeMap(function (policies) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var filename_1, filePaths;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!policies) return [3 /*break*/, 2];
                        filename_1 = this.normalizeName(gymSlug);
                        this.policySettings.bucket = policies[0].bucket_name;
                        return [4 /*yield*/, Promise.all(policies.map(function (_, i) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.uploadFileToS3(bucketPath, policies[i], files[i], filename_1, blockUi)];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/, this.getS3FilePath(bucketPath, filename_1, files[i].name)];
                                    }
                                });
                            }); }))];
                    case 1:
                        filePaths = _a.sent();
                        return [2 /*return*/, filePaths];
                    case 2: return [2 /*return*/, null];
                }
            });
        }); }));
    };
    UploadService.prototype.generateS3Policy = function (numberOfFiles, bucketName) {
        return this.http.get(this.endpointsService.endpoints("/s3Policy/retrieveBucketPolicy?bucketName={{0}}&quantity={{1}}" /* BucketPolicy */, [bucketName + "." + environment.s3Environment, numberOfFiles])).pipe(map(function (res) { return res.policies; }));
    };
    UploadService.prototype.uploadFileToS3 = function (s3BucketPath, policy, file, fileName, blockUi) {
        var s3Url = this.getS3BucketUrl();
        var s3FilePath = this.getS3FilePath(s3BucketPath, fileName, file.name);
        var formData = this.createFormData([
            ['key', s3FilePath],
            ['AWSAccessKeyId', policy.key],
            ['acl', 'public-read'],
            ['policy', policy.policy],
            ['signature', policy.signature],
            ['Content-Type', file.type],
            ['Content-Disposition', "filename=\"" + fileName + "\""],
            ['success_action_status', '201'],
            ['file', file],
        ]);
        return this.httpRequest('POST', s3Url, formData, blockUi);
    };
    UploadService.prototype.getS3FilePath = function (s3BucketPath, identifier, filename) {
        var extension = this.fileExtension(filename);
        return s3BucketPath + "/" + identifier + extension;
    };
    UploadService.prototype.fileExtension = function (filename) {
        var lastDot = filename.lastIndexOf('.');
        if (lastDot < 0) {
            return '';
        }
        return filename.substr(lastDot);
    };
    UploadService.prototype.normalizeName = function (gymSlug) {
        return gymSlug + "-" + Date.now();
    };
    UploadService.prototype.createFormData = function (entries) {
        var formData = new FormData();
        for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
            var _a = entries_1[_i], key = _a[0], value = _a[1];
            formData.append(key, value);
        }
        return formData;
    };
    UploadService.prototype.httpRequest = function (method, url, formData, blockUi) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (blockUi) {
                _this.blockUiService.increment();
            }
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState !== 4) {
                    return;
                }
                else if (xhr.status >= 200 && xhr.status < 400) {
                    resolve(xhr.response);
                }
                else {
                    reject(xhr.response);
                }
                if (blockUi) {
                    _this.blockUiService.decrement();
                }
            };
            xhr.open(method, url, true);
            xhr.send(formData);
        });
    };
    return UploadService;
}());
export { UploadService };
