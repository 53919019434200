import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { MatDialog } from '@angular/material';
import { DirtyCheckService } from '@services/dirty-check.service';

@Injectable({ providedIn: 'root' })
export class DirtyCheckGuard implements CanDeactivate<any> {
  constructor(
    public dialog: MatDialog,
    private readonly dirtyCheckService: DirtyCheckService,
  ) {}

  canDeactivate() {
    return this.dirtyCheckService.confirmDialog();
  }
}
