<div class="modal-content">
  <div class="modal-header">
    {{ (data.isToViewOnly) ? 'VISUALIZAR' : 'EDITAR'}} INFORMAÇÕES DO {{ (data.informativeToEdit.isEvent) ? 'EVENTO' : 'AVISO' }}
    <img src="./assets/icon_custom/18-p-fechar-b.svg" class="close-btn" (click)="closeModal()">
  </div>
  <div class="modal-body">
    <app-informatives-form [informativeToEdit]="data.informativeToEdit" [gymUnitContext]="data.gymUnitContext"
      [isToViewOnly]="data.isToViewOnly" (informativeEmitter)="editInformative($event)"></app-informatives-form>
  </div>
</div>
