import { GraphqlParser } from '@utils/graphql-parser';
import gql from 'graphql-tag';
import { GymUnit } from './gym-unit';

export interface Promotion {
  id?: number;
  thumbnailText: string;
  purchaseTitle: string;
  purchaseText: string;
  initialDate: Date;
  finalDate: Date;
  gymUnities?: GymUnit[];
  dirty?: boolean;
  __typename?: string;
}

export interface PromotionPagedResult {
  results: Promotion[];
  totalCount: number;
}

export class PromotionGraphql {
  public static readonly queryPromotion = gql`
    query($id: Long!) {
      promotion(id: $id) {
        id
        thumbnailText
        purchaseTitle
        purchaseText
        initialDate
        finalDate
        gymUnities {
          id
          name
        }
      }
    }
  `;

  public static readonly queryListPromotion = gql`
    query($max: Int!, $offset: Int!) {
      listPromotions(max: $max, offset: $offset) {
        results {
          id
          thumbnailText
          purchaseTitle
          purchaseText
          initialDate
          finalDate
          gymUnities {
            id
            name
            address {
              id
              city {
                id
                state {
                  id
                  name
                  initials
                }
              }
            }
          }
        }
        totalCount
      }
    }
  `;

  public static readonly mutationCreatePromotion = gql`
    mutation CreatePromotion($gymUnitIds: [Long!], $promotion: PromotionUpdate!) {
      createPromotion(gymUnitIds: $gymUnitIds, promotion: $promotion) {
        id
        errors {
          field
          message
        }
      }
    }
  `;

  public static readonly mutationUpdatePromotion = gql`
    mutation UpdatePromotion($id: Long!, $gymUnitIds: [Long!], $promotion: PromotionUpdate!) {
      updatePromotion(id: $id, gymUnitIds: $gymUnitIds, promotion: $promotion) {
        id
        errors {
          field
          message
        }
      }
    }
  `;

  public static readonly mutationDeletePromotion = gql`
    mutation DeletePromotion($id: Long!) {
      deletePromotion(id: $id) {
        success
        errors {
          field
          message
        }
      }
    }
  `;

  public static parseToCreateMutation(promotions: Promotion[]): any {
    let body = '';
    promotions.forEach((promotion, index) => {
      const newPromotion: Promotion = JSON.parse(JSON.stringify(promotion));
      const gyms = newPromotion.gymUnities.map(g => g.id);
      delete newPromotion.gymUnities;
      delete newPromotion.id;
      body += `createPromotion${index}: createPromotion(
        gymUnitIds: [${gyms.join(',')}],
        promotion: {
          ${GraphqlParser.parseJSONGraphQL(newPromotion, true)}
          }) {
              id
              errors {
              field
              message
          }
      }\n`;
    });

    return gql`mutation { ${body} }`;
  }

  public static parseToUpdateMutation(promotions: Promotion[]): any {
    let body = '';
    promotions.forEach((promotion, index) => {
      const gyms = promotion.gymUnities.map(g => g.id);
      const newPromotion: Promotion = JSON.parse(JSON.stringify(promotion));
      delete newPromotion.gymUnities;
      body += `updatePromotion${index}: updatePromotion(
        id: ${promotion.id},
        gymUnitIds: [${gyms.join(',')}],
        promotion: {
          ${GraphqlParser.parseJSONGraphQL(newPromotion, true)}
          }) {
              id
              errors {
              field
              message
          }
      }\n`;
    });

    return gql`mutation { ${body} }`;
  }
}
