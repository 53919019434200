import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AppBanner } from '@models/app-banner';

@Component({
  selector: 'app-app-banners-edit-modal',
  templateUrl: './app-banners-edit-modal.component.html',
  styleUrls: ['./app-banners-edit-modal.component.scss']
})
export class AppBannersEditModalComponent {

  constructor(
    public dialogRef: MatDialogRef<AppBannersEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  public closeModal(): void {
    this.dialogRef.close(false);
  }

  public editBanner(banner: AppBanner) {
    this.dialogRef.close(banner);
  }

}
