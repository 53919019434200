import { Informative, InformativeStatus } from '@models';

export class InformativeUtil {

  static getStatus(informative: Informative): InformativeStatus {
    const now = new Date();
    if (new Date(informative.initialPublishingDate) > now) {
      return InformativeStatus.SCHEDULED;
    } else if (new Date(informative.finalPublishingDate) < now) {
      return InformativeStatus.EXPIRED;
    } else {
      return InformativeStatus.PUBLISHED;
    }
  }

}
