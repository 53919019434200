import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './modules/login/login.component';
import { AuthenticationGuard, LoginGuard } from '@guards';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'agendamentos',
    loadChildren: () => import('./modules/schedules/entry-schedule/entry-schedule-routing.module')
      .then(m => m.EntryScheduleRoutingModule),
  },
  {
    path: 'aulas-online',
    loadChildren: () => import('./modules/schedules/online-class-schedule/online-class-schedule-routing.module')
      .then(m => m.OnlineClassScheduleRoutingModule),
  },
  {
    path: '',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./modules/internal/internal.module')
      .then(m => m.InternalModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
