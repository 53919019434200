import { EMPTY, Observable } from 'rxjs';
import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { map, finalize } from 'rxjs/operators';

export class ShouldAbort extends Error {
  constructor() {
    super();
  }
}

// tslint:disable-next-line:max-classes-per-file
export abstract class AbstractInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      return next.handle(this.going(req)).pipe(
        map(res => {
          if (res.type === HttpEventType.Response) {
            return this.returning(res, req);
          } else {
            return res;
          }
        }),
        finalize(() => {
          this.onFinalize(req);
        })
      );
    } catch (e) {
      this.returning(e);
      if (e instanceof ShouldAbort) {
        return EMPTY;
      }
      throw e;
    }
  }

  going(req: HttpRequest<any>): HttpRequest<any> { return req; }

  // @ts-ignore
  returning(res: HttpResponse<any> | any, req?: HttpRequest<any>): HttpResponse<any> { return res; }

  onFinalize(req?: HttpRequest<any>): void {}
}
