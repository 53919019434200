/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./informatives-view-edit-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../informatives-form/informatives-form.component.ngfactory";
import * as i3 from "../informatives-form/informatives-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../../core/services/gym.service";
import * as i6 from "../../../../../core/services/teacher.service";
import * as i7 from "../../../../../core/services/place.service";
import * as i8 from "@angular/material/dialog";
import * as i9 from "../../../../../core/services/alert-message.service";
import * as i10 from "@angular/router";
import * as i11 from "../../../../../core/services/scroll.service";
import * as i12 from "../../../../../core/services/dialog.service";
import * as i13 from "./informatives-view-edit-modal.component";
var styles_InformativesViewEditModalComponent = [i0.styles];
var RenderType_InformativesViewEditModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InformativesViewEditModalComponent, data: {} });
export { RenderType_InformativesViewEditModalComponent as RenderType_InformativesViewEditModalComponent };
export function View_InformativesViewEditModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " INFORMA\u00C7\u00D5ES DO ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "close-btn"], ["src", "./assets/icon_custom/18-p-fechar-b.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-informatives-form", [], null, [[null, "informativeEmitter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("informativeEmitter" === en)) {
        var pd_0 = (_co.editInformative($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_InformativesFormComponent_0, i2.RenderType_InformativesFormComponent)), i1.ɵdid(6, 4440064, null, 0, i3.InformativesFormComponent, [i4.FormBuilder, i5.GymService, i6.TeacherService, i7.PlaceService, i8.MatDialog, i9.AlertMessageService, i10.ActivatedRoute, i11.ScrollService, i12.DialogService], { informativeToEdit: [0, "informativeToEdit"], isToViewOnly: [1, "isToViewOnly"], gymUnitContext: [2, "gymUnitContext"] }, { informativeEmitter: "informativeEmitter" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.data.informativeToEdit; var currVal_3 = _co.data.isToViewOnly; var currVal_4 = _co.data.gymUnitContext; _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.data.isToViewOnly ? "VISUALIZAR" : "EDITAR"); var currVal_1 = (_co.data.informativeToEdit.isEvent ? "EVENTO" : "AVISO"); _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_InformativesViewEditModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-informatives-view-edit-modal", [], null, null, null, View_InformativesViewEditModalComponent_0, RenderType_InformativesViewEditModalComponent)), i1.ɵdid(1, 49152, null, 0, i13.InformativesViewEditModalComponent, [i8.MatDialogRef, i8.MAT_DIALOG_DATA], null, null)], null, null); }
var InformativesViewEditModalComponentNgFactory = i1.ɵccf("app-informatives-view-edit-modal", i13.InformativesViewEditModalComponent, View_InformativesViewEditModalComponent_Host_0, {}, {}, []);
export { InformativesViewEditModalComponentNgFactory as InformativesViewEditModalComponentNgFactory };
