import * as tslib_1 from "tslib";
import { EMPTY } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { map, finalize } from 'rxjs/operators';
var ShouldAbort = /** @class */ (function (_super) {
    tslib_1.__extends(ShouldAbort, _super);
    function ShouldAbort() {
        return _super.call(this) || this;
    }
    return ShouldAbort;
}(Error));
export { ShouldAbort };
// tslint:disable-next-line:max-classes-per-file
var AbstractInterceptor = /** @class */ (function () {
    function AbstractInterceptor() {
    }
    AbstractInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        try {
            return next.handle(this.going(req)).pipe(map(function (res) {
                if (res.type === HttpEventType.Response) {
                    return _this.returning(res, req);
                }
                else {
                    return res;
                }
            }), finalize(function () {
                _this.onFinalize(req);
            }));
        }
        catch (e) {
            this.returning(e);
            if (e instanceof ShouldAbort) {
                return EMPTY;
            }
            throw e;
        }
    };
    AbstractInterceptor.prototype.going = function (req) { return req; };
    // @ts-ignore
    AbstractInterceptor.prototype.returning = function (res, req) { return res; };
    AbstractInterceptor.prototype.onFinalize = function (req) { };
    return AbstractInterceptor;
}());
export { AbstractInterceptor };
