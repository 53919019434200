import { TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ScrollService } from './scroll.service';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "./scroll.service";
var DialogService = /** @class */ (function () {
    function DialogService(dialog, scrollService) {
        this.dialog = dialog;
        this.scrollService = scrollService;
    }
    DialogService.prototype.openDialog = function (modalComponent, inputData) {
        if (this.scrollService.getHostListenerSticker() || this.scrollService.getStackLength() > 0) {
            this.scrollService.setSticky(true);
        }
        this.dialogModal = this.dialog.open(modalComponent, inputData);
    };
    DialogService.prototype.afterClosed = function () {
        var _this = this;
        return this.dialogModal.afterClosed().pipe(tap(function () { return setTimeout(function () {
            if (_this.scrollService.getHostListenerSticker() || _this.scrollService.getStackLength() > 0) {
                _this.scrollService.setSticky(false);
            }
        }); }));
    };
    DialogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DialogService_Factory() { return new DialogService(i0.ɵɵinject(i1.MatDialog), i0.ɵɵinject(i2.ScrollService)); }, token: DialogService, providedIn: "root" });
    return DialogService;
}());
export { DialogService };
