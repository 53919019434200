import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
  static config = {
    prefix: 'ls',
    separator: ':',
  };

  private memoryStorage = {};

  public set: (key: string, data: any) => void;
  public get: (key: string) => any;
  public clearItem: (key: string) => any;
  public clear: () => void;
  public loadAll: () => any;

  // === Data and key converters
  static convertData(data: any): string {
      return JSON.stringify({data});
  }
  static unconvertData(data: string): any {
      if (data) {
          return JSON.parse(data).data;
      } else {
          return null;
      }
  }
  static convertKey(key: string): string {
      return [StorageService.config.prefix, key].join(StorageService.config.separator);
  }
  static unconvertKey(key: string): string {
      return key.substr(StorageService.config.prefix.length + StorageService.config.separator.length);
  }

  // === Methods Local/Session Storage
  static _setLocalStorage(key: string, data: any) {
      localStorage.setItem(StorageService.convertKey(key), StorageService.convertData(data));
  }
  static _setSessionStorage(key: string, data: any) {
      sessionStorage.setItem(StorageService.convertKey(key), StorageService.convertData(data));
  }

  static _getLocalStorage(key: string) {
      return StorageService.unconvertData(localStorage.getItem(StorageService.convertKey(key)));
  }
  static _getSessionStorage(key: string) {
      return StorageService.unconvertData(sessionStorage.getItem(StorageService.convertKey(key)));
  }

  static __loadAllFromStorage(storage): any {
      const numKeys = storage.length;
      const tmp = {};
      for (let i = 0; i < numKeys; i++) {
          const key = storage.key(i);
          tmp[StorageService.unconvertKey(key)] = StorageService.unconvertData(storage.getItem(key));
      }
      return tmp;
  }

  static _loadAllLocalStorage() {
      return StorageService.__loadAllFromStorage(localStorage);
  }
  static _loadAllSessionStorage() {
      return StorageService.__loadAllFromStorage(sessionStorage);
  }

  static _clearLocalStorage() {
      localStorage.clear();
  }
  static _clearSessionStorage() {
      sessionStorage.clear();
  }

  static _clearItemLocalStorage(key: string) {
      localStorage.removeItem(StorageService.convertKey(key));
  }
  static _clearItemSessionStorage(key: string) {
      localStorage.removeItem(StorageService.convertKey(key));
  }

  constructor() {
      /*
      * Verifica se o navegador suporta LocalStorage.
      * Se não suportar, utiliza objetos na memória como Fallback
      * */
      if (typeof(Storage) !== 'undefined') {
          this.set = StorageService._setLocalStorage;
          this.get = StorageService._getLocalStorage;
          this.clearItem = StorageService._clearItemLocalStorage;
          this.clear = StorageService._clearLocalStorage;
          this.loadAll = StorageService._loadAllLocalStorage;
      } else {
          this.set = this._setMemoryStorage;
          this.get = this._getMemoryStorage;
          this.clearItem = StorageService._clearItemSessionStorage;
          this.clear = this._clearMemoryStorage;
          this.loadAll = this._loadAllMemoryStorage;
      }
  }

  // Methods Memory Storage
  private _setMemoryStorage(key: string, data: any) {
      this.memoryStorage[StorageService.convertKey(key)] = StorageService.convertData(data);
  }

  private _getMemoryStorage(key: string) {
      return StorageService.unconvertData(this.memoryStorage[StorageService.convertKey(key)]);
  }

  private _loadAllMemoryStorage() {
      return this.memoryStorage;
  }

  private _clearMemoryStorage() {
      this.memoryStorage = {};
  }
}
