import gql from 'graphql-tag';

export interface Term {
    id?: string;
    url?: string;
    subscriptionDate?: Date;
    updateDate?: Date;
    fileName?: string;
}
export interface SubscriptionTermUpdate {
    url: string;
    subscriptionDate: Date;
    gymUnit: {
        id: number;
    };
}

export class TermGraphql {
    public static readonly queryGetSubscriptionTermBySlug = gql`
        query($gymUnitSlug: String!) {
            getSubscriptionTermBySlug(gymUnitSlug: $gymUnitSlug) {
                id
                url
                subscriptionDate
                updateDate
            }
        }
  `;

    public static readonly mutationCreateOrUpdateSubscriptionTerm = gql`
        mutation($subscriptionTerm: SubscriptionTermUpdate!) {
            createOrUpdateSubscriptionTerm(subscriptionTerm: $subscriptionTerm) {
                url
                subscriptionDate
                gymUnit {
                    id
                }
                id
                errors {
                    field
                    message
                }
            }
        }
  `;
}
