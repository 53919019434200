import { Routes } from '@angular/router';
import { LoginComponent } from './modules/login/login.component';
import { AuthenticationGuard, LoginGuard } from '@guards';
var ɵ0 = function () { return import("./modules/schedules/entry-schedule/entry-schedule-routing.module.ngfactory")
    .then(function (m) { return m.EntryScheduleRoutingModuleNgFactory; }); }, ɵ1 = function () { return import("./modules/schedules/online-class-schedule/online-class-schedule-routing.module.ngfactory")
    .then(function (m) { return m.OnlineClassScheduleRoutingModuleNgFactory; }); }, ɵ2 = function () { return import("./modules/internal/internal.module.ngfactory")
    .then(function (m) { return m.InternalModuleNgFactory; }); };
var routes = [
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [LoginGuard]
    },
    {
        path: 'agendamentos',
        loadChildren: ɵ0,
    },
    {
        path: 'aulas-online',
        loadChildren: ɵ1,
    },
    {
        path: '',
        canActivate: [AuthenticationGuard],
        loadChildren: ɵ2,
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2 };
