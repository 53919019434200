import { EvoBaseParamsModel } from '@models';

export class AppConstants {
  public static HTTP_UNKNOWN = 0;
  public static HTTP_OK = 200;
  public static HTTP_BAD_REQUEST = 400;
  public static HTTP_UNAUTHORIZED = 401;
  public static HTTP_FORBIDDEN = 403;
  public static HTTP_NOT_ACCEPTABLE = 406;
  public static HTTP_SERVER_ERROR = 500;

  public static LOCALE = 'pt-BR';

  public static GYM_UNIT_ID: keyof Pick<EvoBaseParamsModel, 'gymUnitId'> = 'gymUnitId';
  public static STOR_LOGIN_DATA = 'loginData';
  public static STOR_USER_TOKEN = 'user-token';
  public static STOR_SELECTED_GYM = 'selectedGym';

  public static STOR_PORTAL_TOKEN = 'portal-token';
  public static STOR_PORTAL_TOKEN_APPOINTMENT_LIVE_CLASS = 'portal-token-appointment-live-class';
  public static STOR_PORTAL_TOKEN_SCHEDULE = 'portal-token-appointment-schedule';
  public static STOR_RECEP_GYMS = 'recep-gyms';
  public static STOR_ONLINE_TEACHER = 'online-teacher';
  public static STOR_SUR_TOKEN = 'ultra-application-token';

  public static STOR_APP_USER_TOKEN = 'app-user-token';

  public static PARAM_PROF = 'prof';

  public static SUR_ERROR = {
    INVALID_APP_TOKEN: 'br.com.bodytech.portal.token.unauthorized',
  };

  public static PORTAL_NAME = 'BCC 2.0';

  public static IMAGE_PNG = 'image/png';
  public static IMAGE_JPG = 'image/jpeg';
  public static PDF = 'application/pdf';

  public static HEADER_IMAGE = {
    WIDTH: 1920,
    HEIGHT: 900,
  };

  public static THUMBNAIL_IMAGE = {
    WIDTH: 1920,
    HEIGHT: 900,
  };

  public static CAROUSEL_IMAGE = {
    WIDTH: 1280,
    HEIGHT: 853,
  };
  public static BANNER_IMAGE = {
    WIDTH: 1920,
    HEIGHT: 1140,
  };
  public static APP_BANNER_IMAGE = {
    WIDTH: 1800,
    HEIGHT: 900,
  };
  public static INFORMATIVE_IMAGE = {
    WIDTH: 1080,
    HEIGHT: 1080,
  };

  public static END = 'END';
  public static START = 'START';
  public static FILEUNIDADE = 'FILEUNIDADE';
  public static CAROUSEL = 'CARROSSEL';
  public static THUMBNAIL = 'THUMBNAIL';
  public static HEADER = 'HEADER';
  public static BANNER = 'BANNER';

  public static BT_ONLINE_SCHEDULE_TITLE = 'Bodytech Home';
  public static FORMULA_ONLINE_SCHEDULE_TITLE = 'Fórmula Home';
  public static SCHEDULE_TITLE = 'Agendamento de Horários';

}
