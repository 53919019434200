import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthenticationService } from '@services';

@Injectable()
export class AuthenticationAppointmentLiveClassGuard implements CanActivate {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) { }

  canActivate() {
    if (this.authenticationService.isLoggedInAppoitmentLiveClass()) {
      return true;
    }
    this.router.navigate(['/aulas-online/login']);
    return false;
  }
}
