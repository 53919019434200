import * as tslib_1 from "tslib";
import gql from 'graphql-tag';
var TermGraphql = /** @class */ (function () {
    function TermGraphql() {
    }
    TermGraphql.queryGetSubscriptionTermBySlug = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n        query($gymUnitSlug: String!) {\n            getSubscriptionTermBySlug(gymUnitSlug: $gymUnitSlug) {\n                id\n                url\n                subscriptionDate\n                updateDate\n            }\n        }\n  "], ["\n        query($gymUnitSlug: String!) {\n            getSubscriptionTermBySlug(gymUnitSlug: $gymUnitSlug) {\n                id\n                url\n                subscriptionDate\n                updateDate\n            }\n        }\n  "])));
    TermGraphql.mutationCreateOrUpdateSubscriptionTerm = gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n        mutation($subscriptionTerm: SubscriptionTermUpdate!) {\n            createOrUpdateSubscriptionTerm(subscriptionTerm: $subscriptionTerm) {\n                url\n                subscriptionDate\n                gymUnit {\n                    id\n                }\n                id\n                errors {\n                    field\n                    message\n                }\n            }\n        }\n  "], ["\n        mutation($subscriptionTerm: SubscriptionTermUpdate!) {\n            createOrUpdateSubscriptionTerm(subscriptionTerm: $subscriptionTerm) {\n                url\n                subscriptionDate\n                gymUnit {\n                    id\n                }\n                id\n                errors {\n                    field\n                    message\n                }\n            }\n        }\n  "])));
    return TermGraphql;
}());
export { TermGraphql };
var templateObject_1, templateObject_2;
