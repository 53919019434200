import * as tslib_1 from "tslib";
import gql from 'graphql-tag';
import { GraphqlParser } from '@utils/graphql-parser';
var ActivityGraphql = /** @class */ (function () {
    function ActivityGraphql() {
    }
    ActivityGraphql.parseToUpdateMutation = function (activities) {
        var body = '';
        activities.forEach(function (activity, index) {
            var activityToSend = {
                id: activity.id,
                calories: activity.calories,
                description: activity.description
            };
            body += "updateActivityCaloriesAndDescription" + index + ": updateActivityCaloriesAndDescription(\n        " + GraphqlParser.parseJSONGraphQL(activityToSend) + "\n        ) {\n              id\n              errors {\n              field\n              message\n          }\n      }\n";
        });
        return gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["mutation { ", " }"], ["mutation { ", " }"])), body);
    };
    ActivityGraphql.queryListActiveActivities = gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n    query {\n      listActiveActivities {\n        id\n        name\n        calories\n        description\n        activityClassification {\n          id\n          name\n        }\n        activityGroup {\n          id\n          name\n        }\n      }\n    }\n  "], ["\n    query {\n      listActiveActivities {\n        id\n        name\n        calories\n        description\n        activityClassification {\n          id\n          name\n        }\n        activityGroup {\n          id\n          name\n        }\n      }\n    }\n  "])));
    ActivityGraphql.queryListActiveActivitiesWithPeriodization = gql(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n    query {\n      listActiveActivitiesWithPeriodization {\n        id\n        name\n      }\n    }\n  "], ["\n    query {\n      listActiveActivitiesWithPeriodization {\n        id\n        name\n      }\n    }\n  "])));
    return ActivityGraphql;
}());
export { ActivityGraphql };
var templateObject_1, templateObject_2, templateObject_3;
