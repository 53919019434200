import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthenticationService } from '@services';

@Injectable()
export class AuthenticationScheduleGuard implements CanActivate {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) { }

  canActivate() {
    if (this.authenticationService.isLoggedInSchedule()) {
      return true;
    }
    this.router.navigate(['/agendamentos/login']);
    return false;
  }
}
