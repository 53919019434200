<app-sided-menu-generic-modal modalTitle="SELECIONE ESTADO E UNIDADE">
  <div aside class="aside-item H4-LatoRegular-B" (click)="selectState(state.id)" *ngFor="let state of states"
    [ngClass]="{'selected-state': selectedStateId === state.id}">
    {{ state.name }}
  </div>

  <div header class="search-box-with-btn" fxHide.lt-sm>
    <input class="search-input" type="text" [(ngModel)]="searchText">
    <img class="search-icon" src="./assets/images/24-p-lupa-c-7.svg">
    <button class="bo-btn search-button H5-LatoBold-B">Buscar</button>
  </div>

  <div header class="select-state-box" fxHide.gt-xs>
    <ng-select [items]="states" [searchable]="false" [clearable]="false" [selectOnTab]="true" bindLabel="name"
      bindValue="id" placeholder="Selecione um estado" (change)="selectState($event.id)" class="w-100"
      [(ngModel)]="selectedStateId">
    </ng-select>
  </div>

  <div main class="gyms-list">
    <div (click)="onCheckChangeHandler(gym)" fxLayout="row" class="gym-name"
      *ngFor="let gym of gyms | filter : searchText : 'name'">
      <div fxLayout="row" fxLayoutAlign="start center">
        <app-checkbox class="check" [check]="getState(gym)"></app-checkbox>
      </div>
      <div class="H4-LatoRegular-C9 w-100 ml-18">
        {{ gym.name }}
      </div>
    </div>
  </div>
  <div footer fxLayout="row" fxLayoutAlign="start center" fxFlex="grow">
    <app-checkbox [ngClass]="{'font-16': messageSelectAll.length > 30}" class="w-100" ngClass.lt-sm="mb-16"
      [(check)]="checkAllSelected" (checkChange)="selectAllToggler($event)" [message]="messageSelectAll">
    </app-checkbox>
    <button fxFlex="noshrink" class="bo-btn bo-tertiary-btn fit-content-width m-12 ml-auto px-20 H5-LatoBold-B"
    (click)="confirmModal()">
    APLICAR SELEÇÃO
  </button>
</div>
</app-sided-menu-generic-modal>
