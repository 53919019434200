import * as tslib_1 from "tslib";
import gql from 'graphql-tag';
var SocialMediaGraphql = /** @class */ (function () {
    function SocialMediaGraphql() {
    }
    SocialMediaGraphql.queryListSocialMedias = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n        query {\n            listSocialMedias {\n                id\n                name\n                urlImage\n            }\n        }\n  "], ["\n        query {\n            listSocialMedias {\n                id\n                name\n                urlImage\n            }\n        }\n  "])));
    return SocialMediaGraphql;
}());
export { SocialMediaGraphql };
var templateObject_1;
