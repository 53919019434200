<div class="main-container" fxLayout="row" fxLayout.lt-sm="column">
  <div fxLayout="column" fxLayoutAlign="start center">
    <div *ngIf="!convenienceForm.controls.icon.value; else hasIcon"
      [class.invalid]="convenienceForm.controls.icon.invalid && convenienceForm.controls.icon.touched"
      class="select-icon"
      (click)="openIconModal()">
      <p class="py-5 mx-1 text-help">Selecionar Ícone</p>
      <img src="./assets/icon_custom/36-p-icones-c-4.svg">
    </div>
    <span *ngIf="convenienceForm.controls.icon.invalid && convenienceForm.controls.icon.touched" class="input-file-error">O ícone é obrigatório</span>
  </div>
  <ng-template #hasIcon>
    <div fxLayout="column" fxLayoutAlign="start center">
      <div class="selected-icon" fxLayoutAlign="center center">
        <img [src]="convenienceForm.controls.icon.value" (click)="openIconModal()">
      </div>
      <div class="edit-icon-label B2-LatoRegular-C4">Clique no ícone para editar.</div>
    </div>
  </ng-template>
  <div class="w-100" [formGroup]="convenienceForm" fxLayout="column" ngClass.lt-sm="mt-16" ngClass.gt-xs="ml-26">
    <div [fxLayout]="convenienceToEdit ? 'column' : 'row'" fxLayout.lt-md="column">
      <div fxFlex="40">
        <div class="H5-LatoBold-C6 mb-10">NOME</div>
        <div class="form-group" [ngClass]="{'mr-16': !convenienceToEdit}" id="group-service-name">
          <input id="service-name" name="service-name" type="text" class="input-text"
          formControlName="name">
        </div>
        <app-error-message class="error" [control]="convenienceForm.controls.name"></app-error-message>
      </div>
      <div fxFlex="60">
        <div class="H5-LatoBold-C6 mb-10">LINK</div>
        <div class="form-group" id="group-service-link">
          <input id="service-link" name="service-link" type="text" class="input-text"
          formControlName="url" (input)="clearErroUrl()">
        </div>
        <app-error-message class="error" [control]="convenienceForm.controls.url"></app-error-message>
      </div>
    </div>
    <div class="H5-LatoBold-C6 mb-10">DESCRIÇÃO</div>
    <textarea id="service-description" name="service-description" type="text" class="text-area input-text"
      formControlName="description">
    </textarea>
    <app-error-message class="error" [control]="convenienceForm.controls.description"></app-error-message>
    <button
      type="button"
      id="login-button"
      class="bo-btn bo-primary-btn"
      (click)="save()"
      [fxFlexAlign]="convenienceToEdit ? 'end' : 'start'"
      >
      {{ convenienceToEdit ? 'SALVAR' : 'ADICIONAR CONVENIÊNCIA' }}
    </button>
  </div>
</div>
