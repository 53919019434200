import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Banner } from '@models';

@Component({
  selector: 'app-banners-edit-modal',
  templateUrl: './banners-edit-modal.component.html',
  styleUrls: ['./banners-edit-modal.component.scss']
})
export class BannersEditModalComponent {

  constructor(
    public dialogRef: MatDialogRef<BannersEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  closeModal(): void {
    this.dialogRef.close(false);
  }

  editBanner(promotion: Banner) {
    this.dialogRef.close(promotion);
  }

}
