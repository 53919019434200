import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { TeacherGraphql, Teacher } from '@models/teacher';
import { JSONUtil } from '@utils/json-util';
import { map, defaultIfEmpty } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import { ResponseError } from '@models';

@Injectable({
  providedIn: 'root'
})
export class TeacherService {

  constructor(
    private readonly apollo: Apollo,
  ) { }

  public getActiveTeachersByGymUnit(gymUnitId: number) {
    return this.apollo.query<Teacher[]>({
      query: TeacherGraphql.queryListActiveTeachersByGymUnit,
      variables: {
        id: gymUnitId
      },
    })
    .pipe(
      map(JSONUtil.turnApolloMutable<Teacher[]>('listActiveTeachersByGymUnit')),
    );
  }

  public getTeachersByGymUnit(gymUnitId: number) {
    return this.apollo.query<Teacher[]>({
      query: TeacherGraphql.queryListTeachersByGymUnit,
      variables: {
        id: gymUnitId
      },
    })
    .pipe(
      map(JSONUtil.turnApolloMutable<Teacher[]>('listTeachersByGymUnit')),
    );
  }

  public updateAllTeachers(teacher: Teacher[]) {
    return this.apollo
      .mutate<Teacher>({
        mutation: TeacherGraphql.parseToUpdateMutation(teacher),
      })
      .pipe(map(JSONUtil.turnApolloMutable<ResponseError[]>()));
  }

}
