import { Injectable } from '@angular/core';
import { endpoints } from '@endpoints';
import { environment } from '@env/environment';

@Injectable()
export class EndpointsService {

  constructor() { }

  private get_path(schema: string, params?: any[] | object): string {
    let path = schema;
    if (params) {
      for (const i in params) {
        if (params.hasOwnProperty(i)) {
          const param = params[i];
          let value = '';
          if (param !== null && param !== undefined) {
            value = param.toString();
          }
          path = path.split(`{{${i}}}`).join(value);
        }
      }
    }
    return path;
  }

  endpoints(url: string, params?: any[] | object): string {
    return environment.baseUrl + this.get_path(url, params);
  }

}
