import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

    constructor() { }

    transform(value: string): string {
      if (!value) { return ''; }

      return value.split(' ')
        .map(word => this.capitalize(word))
        .join(' ');
    }

    private capitalize(word: string): string {
      return word[0].toUpperCase() + word.slice(1).toLowerCase();
    }
}
