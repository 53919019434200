import * as tslib_1 from "tslib";
import gql from 'graphql-tag';
var PhotoUpdate = /** @class */ (function () {
    function PhotoUpdate(displayOrder) {
        this.url = '';
        this.displayOrder = displayOrder || null;
    }
    return PhotoUpdate;
}());
export { PhotoUpdate };
var PhotoGraphql = /** @class */ (function () {
    function PhotoGraphql() {
    }
    PhotoGraphql.queryListPhotosByGymUnit = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n        query($id: Long!) {\n            listPhotosByGymUnit(id: $id) {\n                id\n                url\n                portalDescription\n                objectType\n            }\n        }\n    "], ["\n        query($id: Long!) {\n            listPhotosByGymUnit(id: $id) {\n                id\n                url\n                portalDescription\n                objectType\n            }\n        }\n    "])));
    PhotoGraphql.mutationAssociatePhotosToGymUnit = gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n        mutation AssociatePhotosToGymUnit($gymUnitId: Long!, $photos: [PhotoUpdate!]) {\n            associatePhotosToGymUnit(gymUnitId: $gymUnitId, photos: $photos)\n            {\n                id\n                insertUser\n                insertDate\n                updateUser\n                updateDate\n                errors {\n                    field\n                    message\n                }\n            }\n        }\n    "], ["\n        mutation AssociatePhotosToGymUnit($gymUnitId: Long!, $photos: [PhotoUpdate!]) {\n            associatePhotosToGymUnit(gymUnitId: $gymUnitId, photos: $photos)\n            {\n                id\n                insertUser\n                insertDate\n                updateUser\n                updateDate\n                errors {\n                    field\n                    message\n                }\n            }\n        }\n    "])));
    return PhotoGraphql;
}());
export { PhotoGraphql };
var templateObject_1, templateObject_2;
