/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-permissions-edit-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../../../../node_modules/ngx-chips/ngx-chips.ngfactory";
import * as i5 from "ngx-chips";
import * as i6 from "@angular/forms";
import * as i7 from "./user-permissions-edit-modal.component";
import * as i8 from "@angular/material/dialog";
var styles_UserPermissionsEditModalComponent = [i0.styles];
var RenderType_UserPermissionsEditModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserPermissionsEditModalComponent, data: {} });
export { RenderType_UserPermissionsEditModalComponent as RenderType_UserPermissionsEditModalComponent };
export function View_UserPermissionsEditModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 34, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "close-btn"], ["src", "./assets/icon_custom/18-p-fechar-b.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 29, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 28, "div", [["class", "main-container"], ["fxLayout", "column"]], null, null, null, null, null)), i1.ɵdid(7, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["fxLayout", "column"]], null, null, null, null, null)), i1.ɵdid(9, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "H5-LatoBold-C6"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["N\u00CDVEL DE PERMISS\u00C3O"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "B1-LatoRegular-C7"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 15, "div", [["class", "mt-16"], ["fxLayout", "column"]], null, null, null, null, null)), i1.ɵdid(15, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 1, "div", [["class", "H5-LatoBold-C6 mb-10"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["USU\u00C1RIOS"])), (_l()(), i1.ɵeld(18, 0, null, null, 11, "tag-input", [["class", "tag-input"], ["theme", "tag-theme"]], [[1, "tabindex", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.linkedUsers = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_TagInputComponent_0, i4.RenderType_TagInputComponent)), i1.ɵdid(19, 4308992, null, 2, i5.TagInputComponent, [i1.Renderer2, i5.ɵc], { displayBy: [0, "displayBy"], separatorKeyCodes: [1, "separatorKeyCodes"], placeholder: [2, "placeholder"], secondaryPlaceholder: [3, "secondaryPlaceholder"], onlyFromAutocomplete: [4, "onlyFromAutocomplete"], theme: [5, "theme"], clearOnBlur: [6, "clearOnBlur"] }, null), i1.ɵqud(603979776, 1, { dropdown: 0 }), i1.ɵqud(603979776, 2, { templates: 1 }), i1.ɵpad(22, 1), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.TagInputComponent]), i1.ɵdid(24, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(26, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), (_l()(), i1.ɵeld(27, 0, null, 0, 2, "tag-input-dropdown", [], null, [["window", "scroll"], ["window", "blur"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 28).scrollListener() !== false);
        ad = (pd_0 && ad);
    } if (("window:blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 28).onWindowBlur() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_TagInputDropdown_0, i4.RenderType_TagInputDropdown)), i1.ɵdid(28, 4243456, [[1, 4]], 1, i5.TagInputDropdown, [i1.Injector], { focusFirstElement: [0, "focusFirstElement"], displayBy: [1, "displayBy"], identifyBy: [2, "identifyBy"], appendToBody: [3, "appendToBody"], autocompleteItems: [4, "autocompleteItems"] }, null), i1.ɵqud(603979776, 3, { templates: 1 }), (_l()(), i1.ɵeld(30, 0, null, null, 4, "div", [["class", "mt-24"], ["fxLayout", "row"], ["fxLayoutAlign", "end"]], null, null, null, null, null)), i1.ɵdid(31, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(32, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(33, 0, null, null, 1, "button", [["class", "bo-btn bo-primary-btn fit-content-width ml-18 px-70 H5-LatoBold-B"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveAndCloseModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["SALVAR"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "column"; _ck(_v, 7, 0, currVal_1); var currVal_2 = "column"; _ck(_v, 9, 0, currVal_2); var currVal_4 = "column"; _ck(_v, 15, 0, currVal_4); var currVal_13 = "username"; var currVal_14 = _ck(_v, 22, 0, 188); var currVal_15 = ""; var currVal_16 = "Nenhum selecionado"; var currVal_17 = true; var currVal_18 = "tag-theme"; var currVal_19 = true; _ck(_v, 19, 0, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19); var currVal_20 = _co.linkedUsers; _ck(_v, 24, 0, currVal_20); var currVal_21 = true; var currVal_22 = "username"; var currVal_23 = "username"; var currVal_24 = false; var currVal_25 = _co.data.users; _ck(_v, 28, 0, currVal_21, currVal_22, currVal_23, currVal_24, currVal_25); var currVal_26 = "row"; _ck(_v, 31, 0, currVal_26); var currVal_27 = "end"; _ck(_v, 32, 0, currVal_27); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.profileName; _ck(_v, 3, 0, currVal_0); var currVal_3 = _co.data.description; _ck(_v, 13, 0, currVal_3); var currVal_5 = i1.ɵnov(_v, 19).tabindexAttr; var currVal_6 = i1.ɵnov(_v, 26).ngClassUntouched; var currVal_7 = i1.ɵnov(_v, 26).ngClassTouched; var currVal_8 = i1.ɵnov(_v, 26).ngClassPristine; var currVal_9 = i1.ɵnov(_v, 26).ngClassDirty; var currVal_10 = i1.ɵnov(_v, 26).ngClassValid; var currVal_11 = i1.ɵnov(_v, 26).ngClassInvalid; var currVal_12 = i1.ɵnov(_v, 26).ngClassPending; _ck(_v, 18, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); }); }
export function View_UserPermissionsEditModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-permissions-edit-modal", [], null, null, null, View_UserPermissionsEditModalComponent_0, RenderType_UserPermissionsEditModalComponent)), i1.ɵdid(1, 114688, null, 0, i7.UserPermissionsEditModalComponent, [i8.MatDialogRef, i8.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserPermissionsEditModalComponentNgFactory = i1.ɵccf("app-user-permissions-edit-modal", i7.UserPermissionsEditModalComponent, View_UserPermissionsEditModalComponent_Host_0, {}, {}, []);
export { UserPermissionsEditModalComponentNgFactory as UserPermissionsEditModalComponentNgFactory };
