import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss']
})
export class ListFilterComponent implements OnInit {

  @Input() filterLabel = 'STATUS:';
  @Input() filters = [
    {
      name: 'Ativos'
    },
    {
      name: 'Inativos'
    }
  ];
  @Output() filterEmitter: EventEmitter<any[]> = new EventEmitter();

  @Input() selectedFilters = [];

  constructor() { }

  ngOnInit() {

  }

  selectFilter(filter) {
    const filterAlredySelected = this.selectedFilters.find(f => f.name === filter.name);
    if (filterAlredySelected) {
      this.selectedFilters = this.selectedFilters.filter(f => f.name !== filter.name);
    } else {
      this.selectedFilters.push(filter);
    }
    this.filterEmitter.emit(this.selectedFilters);
  }

  findFilter(filter) {
    return this.selectedFilters.find(f => f.name === filter.name);
  }

}
