import * as tslib_1 from "tslib";
import { BlockUiService } from '@services';
import { HttpRequest } from '@angular/common/http';
import { AbstractInterceptor } from './abstract.interceptor';
import { NO_BLOCK_UI_HTTP_HEADER } from '@models';
var BlockUiInterceptor = /** @class */ (function (_super) {
    tslib_1.__extends(BlockUiInterceptor, _super);
    function BlockUiInterceptor(blockUiService) {
        var _this = _super.call(this) || this;
        _this.blockUiService = blockUiService;
        return _this;
    }
    BlockUiInterceptor.prototype.going = function (req) {
        var noBlockUi = req.headers.get(NO_BLOCK_UI_HTTP_HEADER);
        if (!noBlockUi) {
            this.blockUiService.increment();
        }
        return req.clone({
            headers: req.headers.delete(NO_BLOCK_UI_HTTP_HEADER),
        });
    };
    BlockUiInterceptor.prototype.onFinalize = function (req) {
        var noBlockUi = req.headers.get(NO_BLOCK_UI_HTTP_HEADER);
        if (!noBlockUi) {
            this.blockUiService.decrement();
        }
    };
    return BlockUiInterceptor;
}(AbstractInterceptor));
export { BlockUiInterceptor };
