import * as tslib_1 from "tslib";
import gql from 'graphql-tag';
var TeacherGraphql = /** @class */ (function () {
    function TeacherGraphql() {
    }
    TeacherGraphql.parseToUpdateMutation = function (teachers) {
        var body = '';
        teachers.forEach(function (teacher, index) {
            var teacherId = teacher.id;
            var activitiesIds = teacher.activities.map(function (activity) { return activity.id; });
            body += "associateActivitiesToTeacher" + index + ": associateActivitiesToTeacher(\n        teacherId: \"" + teacherId + "\",\n        activitiesIds: [" + activitiesIds.join(',') + "]\n        ) {\n              id\n              errors {\n              field\n              message\n          }\n      }\n";
        });
        return gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["mutation { ", " }"], ["mutation { ", " }"])), body);
    };
    TeacherGraphql.queryListActiveTeachersByGymUnit = gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n    query($id: Long!) {\n      listActiveTeachersByGymUnit(id: $id) {\n        id\n        name\n        reducedName\n      }\n    }\n  "], ["\n    query($id: Long!) {\n      listActiveTeachersByGymUnit(id: $id) {\n        id\n        name\n        reducedName\n      }\n    }\n  "])));
    TeacherGraphql.queryListTeachersByGymUnit = gql(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n    query($id: Long!) {\n      listTeachersByGymUnit(id: $id) {\n        id\n        name\n        reducedName\n        active\n        activities {\n          id\n          name\n          activityGroup {\n            id\n            name\n          }\n        }\n        gymUnitTeachers {\n          gymUnit {\n            name\n            address {\n              street\n            }\n          }\n        }\n      }\n    }\n  "], ["\n    query($id: Long!) {\n      listTeachersByGymUnit(id: $id) {\n        id\n        name\n        reducedName\n        active\n        activities {\n          id\n          name\n          activityGroup {\n            id\n            name\n          }\n        }\n        gymUnitTeachers {\n          gymUnit {\n            name\n            address {\n              street\n            }\n          }\n        }\n      }\n    }\n  "])));
    return TeacherGraphql;
}());
export { TeacherGraphql };
var templateObject_1, templateObject_2, templateObject_3;
