import { OnInit, ElementRef, EventEmitter, OnDestroy, AfterViewInit, ChangeDetectorRef, } from '@angular/core';
import { AppConstants } from '@utils/app-constants';
import { GenericModalComponent } from '@sharedcomponents/generic-modal/generic-modal.component';
import { UploadService, AlertMessageService, BlockUiService, DialogService } from '@services';
import { environment } from '@env/environment';
import { Observable } from 'apollo-link';
import { AlertMessage, MessageMap } from '@models';
var FileDropComponent = /** @class */ (function () {
    function FileDropComponent(uploadService, dialogService, blockUiService, alertMessageService, cd) {
        this.uploadService = uploadService;
        this.dialogService = dialogService;
        this.blockUiService = blockUiService;
        this.alertMessageService = alertMessageService;
        this.cd = cd;
        this.dropzoneActive = false;
        this.isToViewOnly = false;
        this.disabledComponent = false;
        this.invalid = false;
        this.fileUrlEmitter = new EventEmitter();
        this.isLoadingEmitter = new EventEmitter();
        this.widthUploadBox = '100%';
        this.heightUploadBox = 'auto';
        this.orderError = 2;
        this.orderProperty = 3;
        this.orderImage = 4;
        this.isHorizontal = true;
        this.hasPdfFile = false;
        this.isLoading = false;
        this.color = 'primary';
        this.mode = 'indeterminate';
        this.value = 50;
    }
    Object.defineProperty(FileDropComponent.prototype, "filePurpose", {
        set: function (value) {
            this.purpose = value;
            if (value === 'banner') {
                this.standardWidth = AppConstants.BANNER_IMAGE.WIDTH;
                this.standardHeigth = AppConstants.BANNER_IMAGE.HEIGHT;
            }
            else if (value === 'header' || value === 'thumbnail') {
                this.standardWidth = AppConstants.THUMBNAIL_IMAGE.WIDTH;
                this.standardHeigth = AppConstants.THUMBNAIL_IMAGE.HEIGHT;
            }
            else if (value === 'app_banner') {
                this.standardWidth = AppConstants.APP_BANNER_IMAGE.WIDTH;
                this.standardHeigth = AppConstants.APP_BANNER_IMAGE.HEIGHT;
            }
            else if (value === 'informative') {
                this.standardWidth = AppConstants.INFORMATIVE_IMAGE.WIDTH;
                this.standardHeigth = AppConstants.INFORMATIVE_IMAGE.HEIGHT;
            }
        },
        enumerable: true,
        configurable: true
    });
    FileDropComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.events) {
            this.eventsSubscription = this.events.subscribe(function () { return _this.selectFileWihtClick(); });
        }
        if (this.reset) {
            this.reset.subscribe(function () { return _this.hasPdfFile = false; });
        }
    };
    FileDropComponent.prototype.ngOnDestroy = function () {
        if (this.eventsSubscription) {
            this.eventsSubscription.unsubscribe();
        }
    };
    FileDropComponent.prototype.ngAfterViewInit = function () {
        if ((this.containerFileDrop.nativeElement.offsetWidth < 300 || window.innerWidth < 600) && this.uploadBox) {
            this.heightUploadBox = this.standardHeigth * (this.uploadBox.nativeElement.offsetWidth / this.standardWidth) + "px";
            this.isHorizontal = false;
        }
        else if (!this.isToViewOnly) {
            var scale = 0.1333333;
            this.heightUploadBox = this.standardHeigth * scale + "px";
            this.widthUploadBox = this.standardWidth * scale + "px";
            this.orderImage = 2;
            this.orderError = 3;
            this.orderProperty = 4;
            this.isHorizontal = true;
        }
        if (this.purpose === 'terms') {
            this.heightUploadBox = '160px';
        }
        this.cd.detectChanges();
    };
    FileDropComponent.prototype.dropzoneState = function ($event) {
        this.dropzoneActive = $event;
    };
    FileDropComponent.prototype.handleDrop = function (fileList) {
        if (this.disabledComponent) {
            return;
        }
        this.isLoadingEmitter.emit(this.isLoading);
        if (fileList.length > 1) {
            var data = {
                modalTitle: 'MÚLTIPLOS ARQUIVOS SELECIONADOS',
                modalContent: "Selecione apenas um arquivo para enviar.",
                pButtonText: 'CONTINUAR'
            };
            this.openAlertModal(data);
            return;
        }
        var file = fileList[0];
        if (file) {
            this.isLoading = true;
            // verifica primeiramente o tipo de arquivo
            if (this.purpose === 'header' || this.purpose === 'thumbnail'
                || this.purpose === 'banner' || this.purpose === 'app_banner'
                || this.purpose === 'informative') {
                this.bucketName = 'images';
                this.verifyImages(file);
            }
            else if (this.purpose === 'terms') {
                this.verifyPdf(file);
            }
            else {
                this.isLoading = false;
            }
            this.calculateSizeBox();
        }
    };
    FileDropComponent.prototype.doUpload = function (url, file) {
        var _this = this;
        this.uploadService.uploadFile([file], "" + this.bucketPath, false, this.bucketName, this.gymSlug).subscribe(function (res) {
            _this.fileUrlEmitter.emit("" + url + res[0]);
            _this.invalid = false;
            _this.isLoading = false;
            _this.isLoadingEmitter.emit(_this.isLoading);
            if (_this.purpose === 'terms') {
                _this.hasPdfFile = true;
            }
        }, function () {
            _this.isLoading = false;
            _this.isLoadingEmitter.emit(_this.isLoading);
            _this.blockUiService.decrement();
            _this.alertMessageService.showToastr(AlertMessage.error(MessageMap.SEM_CONEXAO_INTERNET));
        });
    };
    FileDropComponent.prototype.verifyImages = function (file) {
        var _this = this;
        if (file.type !== AppConstants.IMAGE_PNG && file.type !== AppConstants.IMAGE_JPG) {
            var data = {
                modalTitle: 'TIPO DE ARQUIVO INVÁLIDO',
                modalContent: "O arquivo \"" + file.name + "\" enviado \u00E9 inv\u00E1lido. Envie um arquivo de imagem em formato JPEG, JPG ou PNG.",
                pButtonText: 'CONTINUAR'
            };
            this.openAlertModal(data);
            this.isLoading = false;
            return;
        }
        // depois tranforma o arquivo em imagem para verificação de dimensões
        var img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onerror = function () {
            var data = {
                modalTitle: 'ERRO NO ARQUIVO',
                modalContent: "N\u00E3o foi poss\u00EDvel abrir o arquivo \"" + file.name + "\". Envie um arquivo de imagem em formato JPEG, JPG ou PNG.",
                pButtonText: 'CONTINUAR'
            };
            _this.openAlertModal(data);
            _this.isLoading = false;
            return;
        };
        img.onload = function () {
            var width = img.naturalWidth;
            var height = img.naturalHeight;
            window.URL.revokeObjectURL(img.src);
            var data = {
                modalTitle: 'DIMENSÕES DA IMAGEM INVÁLIDAS',
                modalContent: "A imagem \"" + file.name + "\" tem " + width + "x" + height + "px e o tamanho permitido \u00E9\n        " + _this.standardWidth + "x" + _this.standardHeigth + "px.",
                pButtonText: 'CONTINUAR'
            };
            if (width !== _this.standardWidth || height !== _this.standardHeigth) {
                _this.openAlertModal(data);
                _this.isLoading = false;
                return;
            }
            if (!_this.disabledComponent) {
                _this.doUpload(environment.s3buckets.images + "/", file);
            }
        };
    };
    FileDropComponent.prototype.verifyPdf = function (file) {
        var _this = this;
        var data = {
            modalTitle: 'TIPO DE ARQUIVO INVÁLIDO',
            modalContent: "O arquivo \"" + file.name + "\" enviado \u00E9 inv\u00E1lido. Envie um arquivo em formato PDF.",
            pButtonText: 'CONTINUAR',
        };
        if (file.type !== AppConstants.PDF) {
            this.isLoading = false;
            this.openAlertModal(data);
            return;
        }
        var reader = new FileReader();
        reader.onerror = function () {
            _this.openAlertModal(data);
            _this.isLoading = false;
            return;
        };
        reader.onload = function () {
            var dataPdf = reader.result.toString().substr(0, 8);
            var regex = new RegExp('%PDF-(1\.[0-7]|2\.0)');
            if (!dataPdf.match(regex)) {
                _this.isLoading = false;
                _this.openAlertModal(data);
                return;
            }
            _this.bucketName = 'terms';
            _this.doUpload('', file);
        };
        reader.readAsText(file);
    };
    FileDropComponent.prototype.openAlertModal = function (data) {
        this.dialogService.openDialog(GenericModalComponent, {
            width: '796px',
            data: data
        });
        this.fileUrlEmitter.emit(null);
        this.isLoadingEmitter.emit(this.isLoading);
    };
    FileDropComponent.prototype.selectFile = function (event) {
        this.handleDrop(event.target.files);
    };
    FileDropComponent.prototype.selectFileWihtClick = function () {
        if (!this.disabledComponent) {
            this.someInput.nativeElement.click();
        }
    };
    FileDropComponent.prototype.calculateSizeBox = function () {
        return {
            width: this.widthUploadBox,
            height: this.heightUploadBox
        };
    };
    return FileDropComponent;
}());
export { FileDropComponent };
