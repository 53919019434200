<div class="modal-content">
  <div class="modal-header">
    <div>{{ data.profileName }}</div>
    <img src="./assets/icon_custom/18-p-fechar-b.svg" class="close-btn" (click)="closeModal()">
  </div>
  <div class="modal-body">
    <div class="main-container" fxLayout="column">
      <div fxLayout="column">
        <div class="H5-LatoBold-C6">NÍVEL DE PERMISSÃO</div>
        <div class="B1-LatoRegular-C7">{{ data.description }}</div>
      </div>
      <div fxLayout="column" class="mt-16">
        <div class="H5-LatoBold-C6 mb-10">USUÁRIOS</div>
        <tag-input [(ngModel)]="linkedUsers" [separatorKeyCodes]="[188]"
          theme="tag-theme" [placeholder]="''" [secondaryPlaceholder]="'Nenhum selecionado'"
          class="tag-input" [clearOnBlur]="true" [onlyFromAutocomplete]="true"
          [displayBy]="'username'">
          <tag-input-dropdown [autocompleteItems]="data.users" [focusFirstElement]="true"
            [displayBy]="'username'" [identifyBy]="'username'" [appendToBody]="false"></tag-input-dropdown>
        </tag-input>
      </div>
      <div fxLayout="row" class="mt-24" fxLayoutAlign="end">
        <button (click)="saveAndCloseModal()"
          class="bo-btn bo-primary-btn fit-content-width ml-18 px-70 H5-LatoBold-B">SALVAR</button>
      </div>
    </div>
  </div>
</div>
