import * as tslib_1 from "tslib";
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthenticationService, AlertMessageService } from '@services';
import { AlertMessage, MessageMap } from '@models';
import { isEvoRedirectRequest, isEvoService, isToOldCanais } from '@utils/app-endpoints';
import { AbstractInterceptor } from './abstract.interceptor';
// tslint:disable-next-line:max-classes-per-file
var AuthInterceptor = /** @class */ (function (_super) {
    tslib_1.__extends(AuthInterceptor, _super);
    function AuthInterceptor(authService, alertMessageService) {
        var _this = _super.call(this) || this;
        _this.authService = authService;
        _this.alertMessageService = alertMessageService;
        return _this;
    }
    AuthInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        if (isEvoService(req.url)) {
            return;
        }
        if (isEvoRedirectRequest(req.url)) {
            var appToken = this.authService.getAppUserToken();
            if (isToOldCanais(req.url) && appToken) {
                return this.setRequestWithToken(req, next, appToken);
            }
            return this.authService.getAppToken().pipe(mergeMap(function (token) { return _this.setRequestWithToken(req, next, token); }));
        }
        var userToken = this.authService.getUserToken();
        if (userToken) {
            req = req.clone({
                setHeaders: {
                    'X-Authorization': userToken,
                },
            });
        }
        return next.handle(req);
    };
    AuthInterceptor.prototype.returning = function (res) {
        if (res.status === 401) {
            this.alertMessageService.showToastr(AlertMessage.error(MessageMap.SEM_CONEXAO_INTERNET));
            this.authService.doLogout();
        }
        else if (res.status === 403) {
            this.alertMessageService.showToastr(AlertMessage.error(MessageMap.SEM_CONEXAO_INTERNET));
        }
        return res;
    };
    AuthInterceptor.prototype.setRequestWithToken = function (request, next, token) {
        var newRequest = request.clone({
            setHeaders: { 'X-Authorization': token },
        });
        return next.handle(newRequest);
    };
    return AuthInterceptor;
}(AbstractInterceptor));
export { AuthInterceptor };
