import { Injectable, Optional } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { GymUnit, GymUnitGraphql, GymUnitInfo, ResponseError } from '@models';
import { JSONUtil } from '@utils/json-util';

import { Apollo } from 'apollo-angular';

import { EvoGymService } from './evo/evo-gym.service';


@Injectable()
export class GymService {


  constructor(
    private readonly apollo: Apollo,
    @Optional() private readonly evoGymService: EvoGymService,
  ) { }

  getGymNamesWithState(): Observable<GymUnit[]> {
    return this.apollo.query({
      query: GymUnitGraphql.queryListGymUnitsNotHoldingWithState,
    }).pipe(
      map(JSONUtil.turnApolloMutable('listGymUnitsThatAreNotHoldingForBackOffice')),
    );
  }

  updateGymPortalName(gymUnit: GymUnit, newName: string) {
    return this.apollo.mutate<any>({
      mutation: GymUnitGraphql.mutationUpdatePortalName,
      variables: {
        id: gymUnit.id,
        portalName: newName,
      },
    }).pipe(
      map(JSONUtil.turnApolloMutable<ResponseError[]>()),
    );
  }

  getGym(slug: string): Observable<GymUnitInfo> {
    return this.apollo.query({
      query: GymUnitGraphql.queryGetGymUnitBySlug,
      variables: { slug },
    }).pipe(
      map(JSONUtil.turnApolloMutable<{ getGymUnitBySlug: GymUnitInfo }>()),
      map(data => data.getGymUnitBySlug),
      mergeMap(response => {
        if (!this.evoGymService || !response.evoMigratedUnit) {
          return of(response);
        }

        const gymUnitId = response.id.toString();

        return this.evoGymService.getGymsContactInfo({ gymUnitId }).pipe(
          map(evoResponse => this.evoGymService.mapEvoContactInfoToLegacy(response, evoResponse[0])),
        );
      }),
    );
  }

}
