import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthenticationService } from '@services';

@Injectable()
export class AuthenticationGuard implements CanActivate {

    constructor(
        private authService: AuthenticationService,
        private router: Router,
    ) { }

    canActivate() {
        if (this.authService.isLoggedIn()) {
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }
}
