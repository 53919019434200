import { AlertMessage, MessageMap } from '@models';
import { onError } from 'apollo-link-error';
import { AlertMessageService, BlockUiService, AuthenticationService } from '@services';
import { ApolloLink } from 'apollo-link';
import { AppConstants } from '@utils/app-constants';

export function linkError(
    authService: AuthenticationService,
    alertMessageService: AlertMessageService,
    blockUiService: BlockUiService,
): ApolloLink {
  return onError((apolloError: any) => {
    if (apolloError.networkError) {
      blockUiService.network_error();
      if (apolloError.networkError.status === AppConstants.HTTP_UNAUTHORIZED) {
        alertMessageService.showToastr(AlertMessage.error(apolloError.networkError.error.message), [apolloError.networkError.message]);
        authService.doLogout();
      } else {
        alertMessageService.showToastr(AlertMessage.error(MessageMap.SEM_CONEXAO_INTERNET));
      }
    }

  });
}
