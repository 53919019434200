/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./info-services-edit-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../info-services-form/info-services-form.component.ngfactory";
import * as i3 from "../info-services-form/info-services-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../../core/services/info-service.service";
import * as i6 from "../../../../../core/services/alert-message.service";
import * as i7 from "../../../../../core/services/dialog.service";
import * as i8 from "./info-services-edit-modal.component";
import * as i9 from "@angular/material/dialog";
var styles_InfoServicesEditModalComponent = [i0.styles];
var RenderType_InfoServicesEditModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InfoServicesEditModalComponent, data: {} });
export { RenderType_InfoServicesEditModalComponent as RenderType_InfoServicesEditModalComponent };
export function View_InfoServicesEditModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["EDITAR INFORMA\u00C7\u00D5ES DO SERVI\u00C7O"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "close-btn"], ["src", "./assets/icon_custom/18-p-fechar-b.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-info-services-form", [], null, [[null, "infoServiceEmitter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("infoServiceEmitter" === en)) {
        var pd_0 = (_co.editInfoService($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_InfoServicesFormComponent_0, i2.RenderType_InfoServicesFormComponent)), i1.ɵdid(7, 114688, null, 0, i3.InfoServicesFormComponent, [i4.FormBuilder, i5.InfoServiceService, i6.AlertMessageService, i7.DialogService], { infoServiceToEdit: [0, "infoServiceToEdit"] }, { infoServiceEmitter: "infoServiceEmitter" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.infoService; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_InfoServicesEditModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-info-services-edit-modal", [], null, null, null, View_InfoServicesEditModalComponent_0, RenderType_InfoServicesEditModalComponent)), i1.ɵdid(1, 49152, null, 0, i8.InfoServicesEditModalComponent, [i9.MatDialogRef, i9.MAT_DIALOG_DATA], null, null)], null, null); }
var InfoServicesEditModalComponentNgFactory = i1.ɵccf("app-info-services-edit-modal", i8.InfoServicesEditModalComponent, View_InfoServicesEditModalComponent_Host_0, {}, {}, []);
export { InfoServicesEditModalComponentNgFactory as InfoServicesEditModalComponentNgFactory };
