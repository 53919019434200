import * as tslib_1 from "tslib";
import { MatDialogRef } from '@angular/material';
import { PhoneType } from '@models';
var ShowContactsModalComponent = /** @class */ (function () {
    function ShowContactsModalComponent(dialogRef, data) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.data = data;
        var homePhone = data.listCustomerPhones.find(function (phone) { return phone.phoneType === PhoneType.HOME; });
        this.homePhone = tslib_1.__assign({}, homePhone, { frontendFormattedNumber: this.formatPhoneToString(homePhone) });
        var workPhone = data.listCustomerPhones.find(function (phone) { return phone.phoneType === PhoneType.WORK; });
        this.workPhone = tslib_1.__assign({}, workPhone, { frontendFormattedNumber: this.formatPhoneToString(workPhone) });
        var cellPhone = data.listCustomerPhones.find(function (phone) { return phone.phoneType === PhoneType.CELL; });
        this.cellPhone = tslib_1.__assign({}, cellPhone, { frontendFormattedNumber: this.formatPhoneToString(cellPhone) });
        this.emergencyPhones = data.listCustomerEmergencialPhones.sort(function (a, b) { return b.mainPhone - a.mainPhone; });
        this.emergencyPhones = this.emergencyPhones.map(function (phone) { return (tslib_1.__assign({}, phone, { frontendFormattedNumber: _this.formatPhoneToString(phone) })); });
    }
    ShowContactsModalComponent.prototype.formatPhoneToString = function (phone) {
        if (!phone) {
            return '';
        }
        var phoneString = phone.phoneNumber.trim(); // 912345678
        var dashIndex = phoneString.length - 4;
        var spaceIndex = Math.max(phoneString.length - 8, 0);
        var formattedNumber = '';
        if (spaceIndex > 0) {
            formattedNumber = phoneString.slice(0, spaceIndex) + ' '; // "9 "
        }
        var firstPart = phoneString.slice(spaceIndex, dashIndex); // 1234
        var secondPart = phoneString.slice(dashIndex); // 5678
        formattedNumber += firstPart + '-' + secondPart; // "9 1234-5678"
        return "(" + phone.ddd + ") " + formattedNumber;
    };
    ShowContactsModalComponent.prototype.closeModal = function (icon) {
        this.dialogRef.close(icon || false);
    };
    return ShowContactsModalComponent;
}());
export { ShowContactsModalComponent };
