import gql from 'graphql-tag';
import { GymUnit } from './gym-unit';
import { GraphqlParser } from '@utils/graphql-parser';
import { Place } from './place';

export enum InformativeStatus {
  DRAFT = 'Rascunho',
  PUBLISHED = 'Publicado',
  EXPIRED = 'Expirado',
  SCHEDULED = 'Agendado'
}

export interface InformativeFilter {
  gym: GymUnit;
  status: InformativeStatus[];
  isEvent: boolean;
}

export interface Informative {
  id: number;
  title: string;
  description: string;
  imageUrl?: string;
  initialPublishingDate: Date;
  finalPublishingDate: Date;
  status?: InformativeStatus;
  isEvent: boolean;
  gymUnities: GymUnit[];
  place: Place;
  otherPlace?: string;
  teacherName: string;
  eventDate: Date;
  initialTimeHour: string;
  finalTimeHour: string;

  dirty?: boolean;
  __typename?: string;
}

export class InformativeGraphql {

  public static readonly queryListInformativesByGymUnit = gql`
    query($gymUnitId: Long!) {
      listInformativesByGymUnit(gymUnitId: $gymUnitId) {
        id
        title
        description
        imageUrl
        published
        isEvent
        status
        gymUnities {
          id
          name
          address {
            id
            city {
              id
              state {
                id
                name
                initials
              }
            }
          }
        }
        teacherName
        place {
          id
          name
        }
        otherPlace
        initialPublishingDate
        finalPublishingDate
        eventDate
        initialTimeHour
        finalTimeHour
        errors {
          field
          message
        }
      }
    }
  `;

  public static readonly queryListAllInformatives = gql`
    query {
      listAllInformatives {
        id
        title
        description
        imageUrl
        published
        isEvent
        status
        gymUnities {
          id
          name
          address {
            id
            city {
              id
              state {
                id
                name
                initials
              }
            }
          }
        }
        teacherName
        place {
          id
          name
        }
        otherPlace
        initialPublishingDate
        finalPublishingDate
        eventDate
        initialTimeHour
        finalTimeHour
        errors {
          field
          message
        }
      }
    }
  `;

  public static parseToCreateMutation(informatives: Informative[]): any {
    let body = '';
    informatives.forEach((informative: Informative, index: number) => {
      const newInformative: Informative = JSON.parse(JSON.stringify(informative));
      const gyms = newInformative.gymUnities.map(g => g.id);
      delete newInformative.gymUnities;
      delete newInformative.id;
      if (newInformative.place) {
        delete newInformative.place.__typename;
      }
      body += `createInformative${index}: createInformative(
        gymUnitIds: [${gyms.join(',')}],
        informative: {
          ${GraphqlParser.parseJSONGraphQL(newInformative, true)}
        }
      ) {
        id
        errors {
          field
          message
        }
      }\n`;
    });

    return gql`mutation { ${body} }`;
  }

  public static parseToUpdateMutation(informatives: Informative[]): any {
    let body = '';
    informatives.forEach((informative, index) => {
      const gyms = informative.gymUnities.map(g => g.id);
      const informativeToUpdate: Informative = JSON.parse(JSON.stringify(informative));
      delete informativeToUpdate.gymUnities;
      if (informativeToUpdate.place) {
        delete informativeToUpdate.place.__typename;
      }
      body += `updateInformative${index}: updateInformative(
        id: ${informative.id},
        gymUnitIds: [${gyms.join(',')}],
        informative: {
          ${GraphqlParser.parseJSONGraphQL(informativeToUpdate, true)}
        }
      ) {
        id
        errors {
          field
          message
        }
      }\n`;
    });

    return gql`mutation { ${body} }`;
  }

}
