import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Place, PlaceGraphql } from '@models/place';
import { JSONUtil } from '@utils/json-util';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PlaceService {

  constructor(
    private readonly apollo: Apollo
  ) { }

  public getPlacesByGymUnit(gymUnitId: number) {
    return this.apollo.query<Place[]>({
      query: PlaceGraphql.queryListPlacesByGymUnit,
      variables: {
        id: gymUnitId
      },
    })
    .pipe(
      map(JSONUtil.turnApolloMutable<Place[]>('listPlacesByGymUnit')),
    );
  }
}
