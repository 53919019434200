import gql from 'graphql-tag';

export interface InfoServiceUpdate {
  id: number;
  icon: string;
  description: string;
  isDirty?: boolean;
}

export interface InfoService extends InfoServiceUpdate {
  name: string;
}

export interface InfoServiceIcon {
  display: boolean;
  displayOrder: number;
  icon: string;
  id: number;
}

export class InfoServiceGraphql {
  public static readonly queryListInfoServices = gql`
    query {
      listServices {
        id
        name
        icon
        description
      }
    }
  `;

  public static readonly mutationUpdateServiceIconAndDescription = gql`
    mutation updateServiceIconAndDescription($id: Long!, $icon: String!, $description: String!) {
      updateServiceIconAndDescription(id: $id, icon: $icon, description: $description) {
        id
        icon
        description
        errors {
          field
          message
        }
      }
    }
  `;

  public static readonly queryListInfoServiceIcons = gql`
    query {
      listServiceIcons {
        id
        icon
        display
        displayOrder
      }
    }
  `;
}
