export * from './authentication.guard';
export * from './brand.guard';
export * from './login.guard';
export * from './dirty-check.guard';
export * from './role.guard';

import { BrandGuard } from './brand.guard';
import { DirtyCheckGuard } from './dirty-check.guard';
import { RoleGuard } from './role.guard';

export const guards = [
    BrandGuard,
    RoleGuard,
    DirtyCheckGuard,
];
