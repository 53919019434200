<div class="modal-content">
  <div class="modal-header">
    {{ data.header }}
    <img src="./assets/icon_custom/18-p-fechar-b.svg" class="close-btn" (click)="closeModal()">
  </div>
  <div class="modal-body">
    <ul class="p-24 H4-LatoRegular-C7">
      <li *ngFor="let item of data.list">- {{ item }}</li>
      <li *ngIf="!data.list || !data.list.length">{{ data.emptyMessage }}.</li>
    </ul>
  </div>
</div>
