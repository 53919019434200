<div class="modal-content">
    <div class="modal-header">
      <div class="modal-title">EDITAR INFORMAÇÕES DO SERVIÇO</div>
      <img src="./assets/icon_custom/18-p-fechar-b.svg" class="close-btn" (click)="closeModal()" />
    </div>

    <div class="modal-body">
      <app-info-services-form [infoServiceToEdit]="data.infoService" (infoServiceEmitter)="editInfoService($event)"></app-info-services-form>
    </div>
</div>
