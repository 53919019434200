import { MessageMap } from 'app/core/models';
import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { InfoService, InfoServiceIcon, AlertMessage } from '@models';
import { InfoServiceService, AlertMessageService, DialogService } from '@services';
import { FormUtil } from '@utils/form-util';
import { SelectIconModalComponent } from '@sharedcomponents/select-icon-modal/select-icon-modal.component';

@Component({
  selector: 'app-info-services-form',
  templateUrl: './info-services-form.component.html',
  styleUrls: ['./info-services-form.component.scss'],
})
export class InfoServicesFormComponent implements OnInit {
  public infoServiceForm: FormGroup;

  @Input() infoServiceToEdit: InfoService;
  @Output() infoServiceEmitter: EventEmitter<InfoService> = new EventEmitter();

  icons: InfoServiceIcon[];
  selectedIconId: number;

  constructor(
    private formBuilder: FormBuilder,
    private infoServiceService: InfoServiceService,
    private alertMessageService: AlertMessageService,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
    this.infoServiceForm = this.formBuilder.group({
      id: new FormControl(null),
      description: new FormControl(null),
      icon: new FormControl(null, [Validators.required]),
    });

    if (this.infoServiceToEdit) {
      this.infoServiceForm.patchValue({
        id: this.infoServiceToEdit.id,
        description: this.infoServiceToEdit.description,
        icon: this.infoServiceToEdit.icon,
      });
    }
  }

  public selectIcon() {
    if (!this.icons) {
      this.infoServiceService.getInfoServiceIcons().subscribe(res => {
        this.icons = res;
        this.openIconModal();
      });
    } else {
      this.openIconModal();
    }
  }

  private openIconModal() {
    this.dialogService.openDialog<SelectIconModalComponent<InfoServiceIcon>>(SelectIconModalComponent, {
      width: '632px',
      maxWidth: window.innerWidth < 600 ? '100vw' : '632px',
      maxHeight: '90vh',
      data: {
        title: 'SELECIONE O ÍCONE DO SERVIÇO',
        icons: this.icons,
        selectedIcon: this.infoServiceForm.controls.icon.value
          ? this.infoServiceForm.controls.icon.value
          : null,
      },
    });
    this.dialogService.afterClosed().subscribe(icon => {
      if (icon) {
        this.infoServiceForm.controls.icon.setValue(icon.icon);
        this.selectedIconId = icon.displayOrder;
      }
    });
  }

  public save() {
    FormUtil.touchForm(this.infoServiceForm);
    FormUtil.trimFieldsForm(this.infoServiceForm);
    if (this.infoServiceForm.valid) {
      this.selectedIconId = null;
      this.infoServiceEmitter.emit(this.infoServiceForm.value);
      this.infoServiceForm.reset();
    } else {
      this.alertMessageService.showToastr(AlertMessage.error(MessageMap.CAMPOS_EM_VERMELHO));
    }
  }
}
