import { map, switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { GenericModalComponent } from '@sharedcomponents/generic-modal/generic-modal.component';
import { MatDialog } from '@angular/material';
import { MessageMap } from '@models';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root',
})
export class DirtyCheckService {
  private dirty = new BehaviorSubject(false);
  private isDirty$: Observable<boolean> = this.dirty.asObservable();

  constructor(
    public dialog: MatDialog,
    private dialogService: DialogService
  ) {}

  markAsDirty() {
    this.dirty.next(true);
  }

  markAsClean() {
    this.dirty.next(false);
  }

  isDirty(): Observable<boolean> {
    return this.isDirty$;
  }

  confirmDialog(): Observable<boolean> {
    return this.isDirty$.pipe(
      switchMap(dirty => {
        if (dirty === false) {
          return of(true);
        }
        this.dialogService.openDialog(GenericModalComponent, {
          width: '670px',
          data: {
            modalTitle: 'ALTERAÇÕES NÃO SALVAS',
            modalContent: MessageMap.UNSAVED_CHANGES,
            pButtonText: 'SAIR DA PÁGINA',
            sButtonText: 'PERMANECER NA PÁGINA',
          },
        });
        return this.dialogService.afterClosed().pipe(
          map(result => {
            if (result) {
              this.markAsClean();
            }
            return result;
          }),
        );
      }),
      take(1),
    );
  }
}
