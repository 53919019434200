import { environment } from '@env/environment';
var genericEndpointInterpolation = function (baseurl, path, params) {
    if (params === void 0) { params = []; }
    var interpoled = params.reduce(function (old, curr, i) { return old.split("{{" + i + "}}").join(curr.toString()); }, path);
    return baseurl + interpoled;
};
var ɵ0 = genericEndpointInterpolation;
export var sur = function (path) {
    var params = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        params[_i - 1] = arguments[_i];
    }
    return genericEndpointInterpolation(environment.baseUrl, path, params);
};
export var portalBack = function (path) {
    var params = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        params[_i - 1] = arguments[_i];
    }
    return genericEndpointInterpolation(environment.portal, path, params);
};
export var serviceBack = function (path) {
    var params = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        params[_i - 1] = arguments[_i];
    }
    return genericEndpointInterpolation(environment.services.baseUrl, path, params);
};
export var evoBack = function (path) {
    var params = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        params[_i - 1] = arguments[_i];
    }
    return genericEndpointInterpolation(environment.services.evoBaseUrl, path, params);
};
// tslint:disable-next-line:max-classes-per-file
var AppEndpoints = /** @class */ (function () {
    function AppEndpoints() {
    }
    AppEndpoints.get = function (value) {
        if (value === void 0) { value = ''; }
        var params = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            params[_i - 1] = arguments[_i];
        }
        // Replaces {{i}} with params[i]
        return params.reduce(function (old, curr, i) { return old.split("{{" + i + "}}").join(curr.toString()); }, value);
    };
    return AppEndpoints;
}());
export { AppEndpoints };
export var isToOldCanais = function (url) { return url.startsWith(sur("/" /* Root */)); };
export var isEvoService = function (url) { return url.startsWith(evoBack("/" /* Root */)); };
export var isEvoRedirectRequest = function (url) { return url.startsWith(serviceBack("/legacy/evo/sendRequest" /* EvoSendRequest */)); };
export { ɵ0 };
