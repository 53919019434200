import gql from 'graphql-tag';
import { GymUnit } from './gym-unit';

export interface WorkingPeriodUpdate {
  id?: number;
  dayOfTheWeek?: string;
  initialTimeOne?: string;
  finalTimeOne?: string;
  initialTimeTwo?: string;
  finalTimeTwo?: string;
  closed?: boolean;

  gymUnit?: GymUnit;
}

export interface WorkingPeriod extends WorkingPeriodUpdate {
    id?: number;
    timeOfDay?: string;
}

export class WorkingPeriodGraphql {

  public static readonly queryListWorkingPeriodsByGymUnit = gql`
    query($id: Long!) {
      listWorkingPeriodsByGymUnit(id: $id) {
        id
        dayOfTheWeek
        timeOfDay
        initialTimeOne
        finalTimeOne
        initialTimeTwo
        finalTimeTwo
        closed
        gymUnit {
          id
        }
      }
    }
  `;
}
