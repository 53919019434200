import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BlockUI } from 'ng-block-ui';
import { BlockUiService } from '@services';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
var AppComponent = /** @class */ (function () {
    function AppComponent(blockUiService, toastrService) {
        var _this = this;
        this.blockUiService = blockUiService;
        this.toastrService = toastrService;
        this.blockUiService.emitStatus.subscribe(function (status) {
            switch (status.action) {
                case 1 /* START */:
                    _this.blockUI.start();
                    break;
                case 2 /* START_CUSTOM_MESSAGE */:
                    _this.blockUI.start(status.message);
                    break;
                case 3 /* STOP */:
                    _this.blockUI.stop();
                    break;
                case 4 /* NETWORK_ERROR */:
                    _this.blockUI.stop();
                    // to do - verificar se é interessante colocar algo parecido no backoffice
                    // this.networkErrorComponent.openModal();
                    break;
            }
        });
    }
    AppComponent.prototype.ngOnInit = function () {
        this.toastrService.overlayContainer = this.toastContainer;
    };
    tslib_1.__decorate([
        BlockUI(),
        tslib_1.__metadata("design:type", Object)
    ], AppComponent.prototype, "blockUI", void 0);
    return AppComponent;
}());
export { AppComponent };
