import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map } from 'rxjs/operators';
import { S3Policy } from '@models';
import { EndpointsService } from './endpoints.service';
import { SurEndpoints } from '@utils/app-endpoints';
import { BlockUiService } from './block-ui.service';
import { environment } from '@env/environment';

interface PolicySettings {
  bucket: string;
  region: string;
}

@Injectable()
export class UploadService {

  policySettings: PolicySettings = { region: environment.s3Region, bucket: null};

  constructor(
    private http: HttpClient,
    private endpointsService: EndpointsService,
    private blockUiService: BlockUiService
  ) { }

  getS3BucketUrl() {
    const { region, bucket } = this.policySettings;
    return `https://s3.dualstack.${region}.amazonaws.com/${bucket}`;
  }

  // tslint:disable-next-line:max-line-length
  public uploadFile(files: FileList | File[], bucketPath: string, blockUi = true, bucketName: string, gymSlug: string): Observable<string[]> {
    return this.generateS3Policy(files.length, bucketName).pipe(
      mergeMap(async (policies: S3Policy[]) => {
        if (policies) {
          const filename = this.normalizeName(gymSlug);
          this.policySettings.bucket = policies[0].bucket_name;
          const filePaths = await Promise.all(policies.map(async (_, i) => {
            await this.uploadFileToS3(bucketPath, policies[i], files[i], filename, blockUi);
            return this.getS3FilePath(bucketPath, filename, files[i].name);
          }));
          return filePaths;
        }
        return null;
      }),
    );
  }

  private generateS3Policy(numberOfFiles: number, bucketName: string): Observable<S3Policy[]> {
    return this.http.get<{ policies: S3Policy[] }>(
      this.endpointsService.endpoints(SurEndpoints.BucketPolicy, [`${bucketName}.${environment.s3Environment}`, numberOfFiles])
    ).pipe(
      map(res => res.policies),
    );
  }

  private uploadFileToS3(s3BucketPath: string, policy: S3Policy, file: File, fileName: string, blockUi: boolean) {

    const s3Url = this.getS3BucketUrl();
    const s3FilePath = this.getS3FilePath(s3BucketPath, fileName, file.name);

    const formData = this.createFormData([
      ['key', s3FilePath],
      ['AWSAccessKeyId', policy.key],

      ['acl', 'public-read'],
      ['policy', policy.policy],
      ['signature', policy.signature],

      ['Content-Type', file.type],
      ['Content-Disposition', `filename="${fileName}"`],
      ['success_action_status', '201'],
      ['file', file],
    ]);

    return this.httpRequest('POST', s3Url, formData, blockUi);
  }

  private getS3FilePath(s3BucketPath: string, identifier: string, filename: string) {
    const extension = this.fileExtension(filename);
    return `${s3BucketPath}/${identifier}${extension}`;
  }

  private fileExtension(filename: string) {
    const lastDot = filename.lastIndexOf('.');

    if (lastDot < 0) {
      return '';
    }

    return filename.substr(lastDot);
  }

  private normalizeName(gymSlug: string) {
    return `${ gymSlug }-` + Date.now();
  }

  private createFormData(entries: Array<[string, any]>) {
    const formData = new FormData();
    for (const [key, value] of entries) {
      formData.append(key, value);
    }
    return formData;
  }

  private httpRequest(method: string, url: string, formData: FormData, blockUi: boolean) {
    return new Promise((resolve, reject) => {
      if (blockUi) {
        this.blockUiService.increment();
      }

      const xhr = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState !== 4) {
          return;
        } else if (xhr.status >= 200 && xhr.status < 400) {
          resolve(xhr.response);
        } else {
          reject(xhr.response);
        }

        if (blockUi) {
          this.blockUiService.decrement();
        }
      };

      xhr.open(method, url, true);
      xhr.send(formData);
    });
  }
}
