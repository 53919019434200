import gql from 'graphql-tag';

export interface Convenience {
    id: number;
    icon: string;
    name: string;
    url?: string;
    description: string;
    displayOrder?: number;

    isSelected?: boolean;
}

export interface ConvenienceIcon {
    display: boolean;
    displayOrder: number;
    icon: string;
    id: number;
}

export class ConvenienceGraphql {
    public static readonly queryListConveniencesByGymUnit = gql`
        query($id: Long!) {
            listConveniencesByGymUnit(id: $id) {
                id
                name
                icon
                displayOrder
                url
                description
            }
        }
    `;
    public static readonly mutationAssociateConveniencesToGymUnit = gql`
        mutation associateConveniencesToGymUnit($gymUnitId: Long!, $conveniences: [ConvenienceUpdate!]) {
            associateConveniencesToGymUnit(gymUnitId: $gymUnitId, conveniences: $conveniences)
            {
                id
                insertUser
                insertDate
                updateUser
                updateDate
                errors {
                    field
                    message
                }
            }
        }
    `;

    public static readonly queryListConvenienceIcons = gql`
        query {
            listConvenienceIcons {
            id
            icon
            display
            displayOrder
            }
        }
    `;
}
