import { Subject, BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var stackActions;
(function (stackActions) {
    stackActions[stackActions["push"] = 0] = "push";
    stackActions[stackActions["pop"] = 1] = "pop";
})(stackActions || (stackActions = {}));
var ScrollService = /** @class */ (function () {
    function ScrollService() {
        this.stack = [];
        this.needScroll = new Subject();
        this.needScroll$ = this.needScroll.asObservable();
        this.sticky = new BehaviorSubject(false);
        this.sticky$ = this.sticky.asObservable();
        this.hostListenerSticker = false;
    }
    ScrollService.prototype.scroll = function () {
        this.needScroll.next();
    };
    ScrollService.prototype.setSticky = function (value) {
        if (this.stack.length === 0 || this.stack[0] === value) {
            this.stack.push(value);
            this.sticky.next(value);
        }
        else {
            this.stack.pop();
        }
        if (this.stack.length === 0) {
            this.sticky.next(value);
        }
    };
    ScrollService.prototype.setHostListenerSticker = function (value) {
        this.hostListenerSticker = value;
    };
    ScrollService.prototype.getHostListenerSticker = function () {
        return this.hostListenerSticker;
    };
    ScrollService.prototype.getStackLength = function () {
        return this.stack.length;
    };
    ScrollService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ScrollService_Factory() { return new ScrollService(); }, token: ScrollService, providedIn: "root" });
    return ScrollService;
}());
export { ScrollService };
