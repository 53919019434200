<div class="main-container" fxLayout="row"  fxLayout.lt-md="column" fxLayoutAlign="space-between">
  <div fxLayout="column" class="mb-16 container-image" ngClass.gt-xs="mr-26">
    <app-file-drop
      filePurpose="banner"
      bucketPath="banners"
      gymSlug="SLUG_BANNER"
      [invalid]="bannersForm.controls.image.invalid && bannersForm.controls.image.touched"
      (fileUrlEmitter)="getFileUrl($event)"
      (isLoadingEmitter)="setLoading($event)"
      [file]="file"
    ></app-file-drop>
  </div>
  <div class="w-100" [formGroup]="bannersForm">
    <div class="H5-LatoBold-C6 mb-10">TÍTULO DO BANNER</div>
    <div class="form-group" id="group-banner-title">
      <input id="banner-title" name="banner-title" type="text" class="input-text"
      formControlName="title">
    </div>
    <app-error-message class="error" [control]="bannersForm.controls.title"></app-error-message>

    <div fxLayout="row" fxLayout.lt-md="column">
      <div fxFlex="40">
        <div class="H5-LatoBold-C6 mb-10">TEXTO DO BOTÃO</div>
        <div class="form-group mr-16-lg" id="group-button-text">
          <input id="button-text" name="button-text" type="text" class="input-text"
          formControlName="buttonText" (change)="clearFieldIfEmpty($event.target.value)">
        </div>
        <app-error-message class="error" [control]="bannersForm.controls.buttonText"></app-error-message>
      </div>
      <div fxFlex>
        <div class="H5-LatoBold-C6 mb-10">INÍCIO DA VEICULAÇÃO</div>
        <div fxLayout="row" class="form-group mr-16-lg" id="group-initial-date" (click)="picker1.open()">
          <input matInput [matDatepicker]="picker1" id="initial-date" name="initial-date" class="input-text date-input"
          formControlName="initialDate" [min]="now()" [max]="bannersForm.value.finalDate" (focus)="picker1.open()" readonly>
          <mat-datepicker-toggle [class.date-picker-input-error]="bannersForm.controls.initialDate.invalid" matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1 disabled="false"></mat-datepicker>
        </div>
        <app-error-message class="error" [control]="bannersForm.controls.initialDate"></app-error-message>
      </div>
      <div fxFlex>
        <div class="H5-LatoBold-C6 mb-10">FIM DA VEICULAÇÃO</div>
        <div fxLayout="row" class="form-group" id="group-final-date" (click)="picker2.open()">
          <input matInput [matDatepicker]="picker2" id="final-date" name="final-date" class="input-text date-input"
          formControlName="finalDate" [min]="bannersForm.value.initialDate || now()" (focus)="picker2.open()" readonly>
          <mat-datepicker-toggle [class.date-picker-input-error]="bannersForm.controls.finalDate.invalid" matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2 disabled="false"></mat-datepicker>
        </div>
        <app-error-message class="error" [control]="bannersForm.controls.finalDate"></app-error-message>
      </div>
    </div>

    <div class="H5-LatoBold-C6 mb-10">LINK DO BOTÃO</div>
    <div class="form-group" id="group-button-link">
      <input [attr.disabled]="!bannersForm.controls.buttonText.value ? '' : null" id="button-link"
        name="button-link" type="text" class="input-text" formControlName="linkUrl"
        [required]="!!bannersForm.value.buttonText" (input)="clearErroUrl()">
    </div>
    <app-error-message class="error" [control]="bannersForm.controls.linkUrl"></app-error-message>

    <div class="H5-LatoBold-C6 mb-10">TEXTO DO BANNER</div>
    <textarea id="banner-text" name="banner-text" type="text" class="text-area input-text"
      formControlName="subtitle">
    </textarea>
    <app-error-message class="error" [control]="bannersForm.controls.subtitle"></app-error-message>

    <div class="H5-LatoBold-C6 mb-10">TEXTO LEGAL</div>
    <textarea id="legal-text" name="legal-text" type="text" class="text-area input-text"
      formControlName="legalText">
    </textarea>
    <app-error-message class="error" [control]="bannersForm.controls.legalText"></app-error-message>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-xs="space-between" fxLayoutAlign.lt-sm="center center">
      <div fxLayout="row" ngClass.lt-sm="mb-16 w-100" ngClass.gt-xs="mr-16" fxFlex.lt-sm="grow" fxFlex.gt-xs="auto">
        <button
          [disabled]="!bannersForm.controls.image.value"
          type="button"
          id="login-button"
          class="bo-btn bo-btn-c7 mr-16"
          (click)="preview('web')">
          PREVIEW WEB
        </button>
        <button
          [disabled]="!bannersForm.controls.image.value"
          type="button"
          id="login-button"
          class="bo-btn bo-btn-c7"
          (click)="preview('mobile')">
          PREVIEW MOBILE
        </button>
      </div>
      <button
        type="button"
        id="login-button"
        class="bo-btn bo-primary-btn"
        (click)="save()">
        {{ bannerToEdit ? 'SALVAR' : 'ADICIONAR BANNER'}}
      </button>
    </div>
  </div>
</div>
